export function obterListaMdfeTipoCarroceria() {
  return (
    [
      { label: '00 - Não aplicavel', value: 'NAOAPLICAVEL' },
      { label: '01 - Aberta', value: 'ABERTA' },
      { label: '02 - Fechada Baú', value: 'FECHADABAU' },
      { label: '03 - Granelera', value: 'GRANELERA' },
      { label: '04 - Porta Container', value: 'PORTACONTAINER' },
      { label: '05 - Sider', value: 'SIDER' },
    ]
  );
}
