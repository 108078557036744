/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unneeded-ternary */
import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get, orderBy, isNumber } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { ToggleButton } from 'primereact/togglebutton';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import moment from 'moment';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors, converteData, replaceAll, formataDataMoment, formataMoeda, arredondar } from '../../../util/diversos';
import { Input100px, Input155px, Input200px, Input250px, Input400px } from '../../../styles/inputGroup';
import { fillColumns } from '../../../components/table';

import { obterListaMdfeModalidade } from '../../../services/mdfeModalidade';
import { obterListaMdfeTipoEmitente } from '../../../services/mdfeTipoEmitente';
import { obterListaMdfeTipoEmissao } from '../../../services/mdfeTipoEmissao';
import { obterListaMdfeTipoTransportador } from '../../../services/mdfeTipoTransportador';
import { obterListaMdfeRespSeguro } from '../../../services/mdfeRespSeguro';
import { obterListaMdfeModeloDocFiscal } from '../../../services/mdfeModeloDocFiscal';
import { obterListaMdfeTipoUnidTransporte } from '../../../services/mdfeTipoUnidTransporte';
import { obterListaMdfeTipoUnidCarga } from '../../../services/mdfeTipoUnidCarga';

import Card from '../../../components/card';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

import ConsultaVeiculo from '../veiculo/consultaVeiculo';
import ConsultaParticipante from '../participante/consultaParticipante';

export default function CadastroMdfePropria({ payloadCadastro, childToParentConsulta }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [disableEdicaoEnviada, setDisableEdicaoEnviada] = useState(false);

  const id = get(payloadCadastro, 'id', 0);
  const empresaId = get(payloadCadastro, 'idEmpresa', 0);
  const empresaCnpj = get(payloadCadastro, 'cnpjEmpresa', 0);
  const tipoMovimentacao = get(payloadCadastro, 'tipoMovimentacao', '');
  const propsTipoMovimentacao = get(payloadCadastro, 'propsTipoMovimentacao', '');

  let seriePadrao = get(payloadCadastro, 'seriePadrao', '1');
  if (!seriePadrao) {
    seriePadrao = '1';
  }

  const [ufEmpresa, setUfEmpresa] = useState(get(payloadCadastro, 'uf', ''));

  const [flag, setFlag] = useState('D'); // D = digitada , T = transmitida
  const [chaveAcesso, setChaveAcesso] = useState('');
  const [protocolo, setProtocolo] = useState('');
  const [numeroRecibo, setNumeroRecibo] = useState('');
  const [cnpjEmpresa, setCnpjEmpresa] = useState('');

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [activeTabRodoviarioIndex, setActiveTabRodoviarioIndex] = useState(0);
  const [activeTabDocFiscalIndex, setActiveTabDocFiscalIndex] = useState(0);

  const mod = '58';
  const [serie, setSerie] = useState(seriePadrao);
  const [numeroMdf, setNumeroMdf] = useState('0');
  const [dhEmi, setDhEmi] = useState(new Date());
  const [dhIniViagem, setDhIniViagem] = useState(new Date());
  const [tipoEmissao, setTipoEmissao] = useState('NORMAL');
  const [modalidade, setModalidade] = useState('RODOVIARIO');
  const [tipoEmitente, setTipoEmitente] = useState('PRESTADORSERVICOTRANSPORTE');
  const [tipoTransportador, setTipoTransportador] = useState('ETC');
  const [idVeiculo, setIdVeiculo] = useState('');
  const [placaVeiculo, setPlacaVeiculo] = useState('');
  const [ufInicio, setUfInicio] = useState('AC');
  const [ufFim, setUfFim] = useState('AC');
  const [listaUf, setListaUf] = useState([]);
  const [rntrc, setRntrc] = useState('');

  const [listaMunicipios, setListaMunicipios] = useState([]);
  const [filteredMunicipios, setFilteredMunicipios] = useState(null);
  const [listaMunicipioCarregamento, setListaMunicipioCarregamento] = useState([]);
  const [editandoMunicipioCarregamento, setEditandoMunicipioCarregamento] = useState(0);
  const [selectedMunicipioCarregamento, setSelectedMunicipioCarregamento] = useState(null);
  const [cMunCarregamento, setCMunCarregamento] = useState('');
  const [xMunCarregamento, setXMuncarregamento] = useState('');

  const [listaUfPercurso, setListaUfPercurso] = useState([]);
  const [editandoUfPercurso, setEditandoUfPercurso] = useState(0);
  const [selectedUfPercurso, setSelectedUfPercurso] = useState(null);
  const [ufPercurso, setUfPercurso] = useState('');

  const [listaSeguroCarga, setListaSeguroCarga] = useState([]);
  const [editandoSeguroCarga, setEditandoSeguroCarga] = useState(0);
  const [selectedSeguroCarga, setSelectedSeguroCarga] = useState(null);
  const [seguroResponsavel, setSeguroResponsavel] = useState('EMITENTE');
  const [seguroCnpjResponsavel, setSeguroCnpjResponsavel] = useState('');
  const [seguroNomeSeguradora, setSeguroNomeSeguradora] = useState('');
  const [seguroCnpjSeguradora, setSeguroCnpjSeguradora] = useState('');
  const [seguroNumeroApolice, setSeguroNumeroApolice] = useState('');

  const [listaAverbacao, setListaAverbacao] = useState([]);
  const [editandoAverbacao, setEditandoAverbacao] = useState(0);
  const [selectedAverbacao, setSelectedAverbacao] = useState(null);
  const [numeroAverbacao, setNumeroAverbacao] = useState('');

  const [listaLacre, setListaLacre] = useState([]);
  const [editandoLacre, setEditandoLacre] = useState(0);
  const [selectedLacre, setSelectedLacre] = useState(null);
  const [numeroLacre, setNumeroLacre] = useState('');

  const [showConsultaVeiculo, setShowConsultaVeiculo] = useState(false);
  const [showConsultaReboque, setShowConsultaReboque] = useState(false);
  const [showConsultaMunicipio, setShowConsultaMunicipio] = useState(false);
  const [showCadastroMunicipioCarregamento, setShowCadastroMunicipioCarregamento] = useState(false);
  const [showCadastroUfPercurso, setShowCadastroUfPercurso] = useState(false);
  const [showCadastroSeguroCarga, setShowCadastroSeguroCarga] = useState(false);
  const [showCadastroAverbacao, setShowCadastroAverbacao] = useState(false);
  const [showCadastroLacre, setShowCadastroLacre] = useState(false);

  const [showConfirmDeleteMunicipioCarregamento, setShowConfirmDeleteMunicipioCarregamento] = useState(false);
  const [showConfirmDeleteUfPercurso, setShowConfirmDeleteUfPercurso] = useState(false);
  const [showConfirmDeleteSeguroCarga, setShowConfirmDeleteSeguroCarga] = useState(false);
  const [showConfirmDeleteAverbacao, setShowConfirmDeleteAverbacao] = useState(false);
  const [showConfirmDeleteLacre, setShowConfirmDeleteLacre] = useState(false);

  const [idDest, setIdDest] = useState(''); // localdestino nota, 1 interna, 2 interestadual, 3 exterior
  const [vOutro, setVOutro] = useState(0);

  const [idParticipante, setIdParticipante] = useState(empresaId);
  const [UfParticipante, setUfParticipante] = useState('');

  const [infCpl, setInfCpl] = useState('');
  const [infAdFisco, setInfAdFisco] = useState('');

  const [listaAutXml, setListaAutXml] = useState([]);
  const [selectedAutXml, setSelectedAutXml] = useState(null);
  const [editandoAutXml, setEditandoAutXml] = useState(0);
  const [autXmlCnpj, setAutXmlCnpj] = useState('');
  const [autXmlCpf, setAutXmlCpf] = useState('');

  const [listaCiot, setListaCiot] = useState([]);
  const [selectedCiot, setSelectedCiot] = useState(null);
  const [editandoCiot, setEditandoCiot] = useState(0);
  const [ciotCodigo, setCiotCodigo] = useState('');
  const [ciotCnpj, setCiotCnpj] = useState('');
  const [ciotCpf, setCiotCpf] = useState('');

  const [listaDocFiscal, setListaDocFiscal] = useState([]);
  const [selectedDocFiscal, setSelectedDocFiscal] = useState(null);
  const [editandoDocFiscal, setEditandoDocFiscal] = useState(0);
  const [docFiscalModelo, setDocFiscalModelo] = useState('55');
  const [docFiscalChaveAcesso, setDocFiscalChaveAcesso] = useState('');
  const [docFiscalSegundoCodigoBarras, setDocFiscalSegundoCodigoBarras] = useState('');
  const [docFiscalReentrega, setDocFiscalReentrega] = useState(false);
  const [docFiscalMunDescarregamento, setDocFiscalMunDescarregamento] = useState('');
  const [docFiscalXMunDescarregamento, setDocFiscalXMunDescarregamento] = useState('');
  const [docFiscalUfDescarregamento, setDocFiscalUfDescarregamento] = useState('AL');

  const [listaDocFiscalTransporte, setListaDocFiscalTransporte] = useState([]);
  const [selectedDocFiscalTransporte, setSelectedDocFiscalTransporte] = useState(null);
  const [editandoDocFiscalTransporte, setEditandoDocFiscalTransporte] = useState(0);
  const [docFiscalTranspTipo, setDocFiscalTranspTipo] = useState('RODOVIARIOTRACAO'); // ENUM UNID TRANSPORTE NO BACKEND
  const [docFiscalTranspIdentificacao, setDocFiscalTranspIdentificacao] = useState('');
  const [docFiscalTranspNLacres, setDocFiscalTranspNLacres] = useState('');
  const [docFiscalTranspQtdeRateada, setDocFiscalTranspQtdeRateada] = useState('');

  const [listaDocFiscalCarga, setListaDocFiscalCarga] = useState([]);
  const [selectedDocFiscalCarga, setSelectedDocFiscalCarga] = useState(null);
  const [editandoDocFiscalCarga, setEditandoDocFiscalCarga] = useState(0);
  const [docFiscalUnidadeCargaTipo, setDocFiscalUnidadeCargaTipo] = useState('CONTAINER'); // ENUM UNID CARGA NO BACKEND
  const [docFiscalUnidadeCargaIdentificacao, setDocFiscalUnidadeCargaIdentificacao] = useState('');
  const [docFiscalUnidadeCargaNLacres, setDocFiscalUnidadeCargaNLacres] = useState('');
  const [docFiscalUnidadeCargaQtdeRateada, setDocFiscalUnidadeCargaQtdeRateada] = useState('');

  const [docFiscalProdutoPerigosoOnu, setDocFiscalProdutoPerigosoOnu] = useState('');
  const [docFiscalProdutoPedigosoClasse, setDocFiscalProdutoPerigosoClasse] = useState('');
  const [docFiscalProdutoPedigosoNomeEmbarque, setDocFiscalProdutoPerigosoNomeEmbarque] = useState('');
  const [docFiscalProdutoPedigosoGrupoEmbalagem, setDocFiscalProdutoPerigosoGrupoEmbalagem] = useState('');
  const [docFiscalProdutoPedigosoQtdePorProduto, setDocFiscalProdutoPerigosoQtdePorProduto] = useState('');
  const [docFiscalProdutoPedigosoQtdeETipoVolume, setDocFiscalProdutoPerigosoQtdeETipoVolume] = useState('');

  const [listaValePedagio, setListaValePedagio] = useState([]);
  const [selectedValePedagio, setSelectedValePedagio] = useState(null);
  const [editandoValePedagio, setEditandoValePedagio] = useState(0);
  const [valePedagioCnpjForn, setValePedagioCnpjForn] = useState('');
  const [valePedagioCnpjPg, setValePedagioCnpjPg] = useState('');
  const [valePedagioNCompra, setValePedagioNCompra] = useState('');
  const [valePedagioValor, setValePedagioValor] = useState('');

  const [listaContratante, setListaContratante] = useState([]);
  const [selectedContratante, setSelectedContratante] = useState(null);
  const [editandoContratante, setEditandoContratante] = useState(0);
  const [contratanteCnpj, setContratanteCnpj] = useState('');
  const [contratanteCpf, setContratanteCpf] = useState('');

  const [listaReboque, setListaReboque] = useState([]);
  const [selectedReboque, setSelectedReboque] = useState(null);
  const [editandoReboque, setEditandoReboque] = useState(0);
  const [idReboque, setIdReboque] = useState('');
  const [placaReboque, setPlacaReboque] = useState('');

  const [showCadastroAutXml, setShowCadastroAutXml] = useState(false);
  const [showCadastroCiot, setShowCadastroCiot] = useState(false);
  const [showCadastroValePedagio, setShowCadastroValePedagio] = useState(false);
  const [showCadastroContratante, setShowCadastroContratante] = useState(false);
  const [showCadastroReboque, setShowCadastroReboque] = useState(false);
  const [showCadastroDocFiscal, setShowCadastroDocFiscal] = useState(false);
  const [showCadastroDocFiscalTransporte, setShowCadastroDocFiscalTransporte] = useState(false);
  const [showCadastroDocFiscalCarga, setShowCadastroDocFiscalCarga] = useState(false);

  const [showConsultaParticipante, setShowConsultaParticipante] = useState(false);

  const [showConfirmDeleteAutXml, setShowConfirmDeleteAutXml] = useState(false);
  const [showConfirmDeleteCiot, setShowConfirmDeleteCiot] = useState(false);
  const [showConfirmDeleteValePedagio, setShowConfirmDeleteValePedagio] = useState(false);
  const [showConfirmDeleteContratante, setShowConfirmDeleteContratante] = useState(false);
  const [showConfirmDeleteReboque, setShowConfirmDeleteReboque] = useState(false);
  const [showConfirmDeleteDocFiscal, setShowConfirmDeleteDocFiscal] = useState(false);
  const [showConfirmDeleteDocFiscalTransporte, setShowConfirmDeleteDocFiscalTransporte] = useState(false);
  const [showConfirmDeleteDocFiscalCarga, setShowConfirmDeleteDocFiscalCarga] = useState(false);

  const [showConfirmEdicaoEnviada, setShowConfirmEdicaoEnviada] = useState(false);
  const [showConfirmCancelarDigitacaoMdfe, setShowConfirmCancelarDigitacaoMdfe] = useState(false);

  const [setListaEmpresas] = useState([]);

  const [columnsSetupMunicipioCarregamento] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'cMunCarrega', header: 'Código Município', sort: true },
    { field: 'xMunCarrega', header: 'Nome Município', sort: true },
  ]);

  const [columnsSetupUfPercurso] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'ufPercurso', header: 'UF', sort: true },
  ]);

  const [columnsSetupAverbacao] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'numeroAverbacao', header: 'Número Averbação', sort: true },
  ]);

  const [columnsSetupLacre] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'numeroLacre', header: 'Número Lacre', sort: true },
  ]);

  const [columnsSetupSeguroCarga] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'responsavel', header: 'Responsável', sort: true },
    { field: 'cnpjResponsavel', header: 'CNPJ Responsável', sort: true },
    { field: 'nomeSeguradora', header: 'Nome Seguradora', sort: true },
    { field: 'cnpjSeguradora', header: 'CNPJ Seguradora', sort: true },
    { field: 'numeroApolice', header: 'Número Apólice', sort: true },
  ]);

  const [columnsSetupAutXml] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'cnpj', header: 'CNPJ', sort: true },
    { field: 'cpf', header: 'CPF', sort: true },
  ]);

  const [columnsSetupCiot] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'codigo', header: 'Código', sort: true },
    { field: 'cnpj', header: 'CNPJ', sort: true },
    { field: 'cpf', header: 'CPF', sort: true },
  ]);

  const [columnsSetupDocFiscalTransporte] = useState([
    { field: 'item', header: 'Item', sort: true },
  ]);

  const [columnsSetupDocFiscalCarga] = useState([
    { field: 'item', header: 'Item', sort: true },
  ]);

  const [columnsSetupDocFiscal] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'mod', header: 'Modelo', sort: true },
    { field: 'chaveAcesso', header: 'Chave Acesso', sort: true },
    { field: 'segCodBarra', header: 'Segundo Cód Barra', sort: true },
    { field: 'reentrega', header: 'Reentrega', sort: true, booleano: true },
  ]);

  const [columnsSetupValePedagio] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'valePedagioCnpjForn', header: 'CNPJ Fornecedor', sort: true },
    { field: 'valePedagioCnpjPg', header: 'CNPJ Pagamento', sort: true },
    { field: 'valePedagioNCompra', header: 'Número Compra', sort: true },
    { field: 'valePedagioValor', header: 'Valor', sort: true },
  ]);

  const [columnsSetupContratante] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'cnpj', header: 'CNPJ', sort: true },
    { field: 'cpf', header: 'CPF', sort: true },
  ]);

  const [columnsSetupReboque] = useState([
    { field: 'id', header: 'id', hidden: true },
    { field: 'item', header: 'Item', sort: true },
    { field: 'placaReboque', header: 'Placa Reboque', sort: true },
  ]);

  const listaMdfeModalidade = obterListaMdfeModalidade();
  const listaMdfeTipoEmitente = obterListaMdfeTipoEmitente();
  const listaMdfeTipoEmissao = obterListaMdfeTipoEmissao();
  const listaMdfeTipoTransportador = obterListaMdfeTipoTransportador();
  const listaRespSeguro = obterListaMdfeRespSeguro();
  const listaMdfeModeloDocFiscal = obterListaMdfeModeloDocFiscal();
  const listaMdfeTipoUnidTrasnporteDocFiscal = obterListaMdfeTipoUnidTransporte();
  const listaMdfeTipoUnidCargaDocFiscal = obterListaMdfeTipoUnidCarga();

  async function setUltimoNumero(serie) {
    if (id === 0) {
      try {
        setIsLoading(true);

        (serie === '') ? serie = '1' : '';

        await axiosAdmin.get(`/mdfe/ultimonumero?cnpjEmpresa=${empresaCnpj}&mod=${mod}&serie=${serie}`).then(res => {
          const ultimoNumero = res.data;
          if (res.data !== null) {
            const proximoNumero = parseInt(ultimoNumero, 10) + 1;
            setNumeroMdf(proximoNumero.toString());
          }
        }).catch(() => {
          setNumeroMdf(numeroMdf);
        });
      } catch (err) {
        toast.warn('Não foi possível achar o número da nota');
      } finally {
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    let mostrarMensagemErro = true;

    async function getUnidadesFederacao() {
      try {
        setIsLoading(true);

        const listDropdown = [];

        const { data: ufs } = await axiosAdmin.get('/unidadefederacao');

        ufs.forEach(element => {
          listDropdown.push({ label: element.sigla, value: element.sigla });
        });

        listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

        setListaUf(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }
    getUnidadesFederacao();

    async function getMunicipios() {
      try {
        setIsLoading(true);

        const listDropdown = [];

        const { data: _municipios } = await axiosAdmin.get('/municipio');

        _municipios.forEach(element => {
          listDropdown.push({ label: element.descricao + ' - ' + element.uf, value: element.descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, ''), codigo: element.codigo, uf: element.uf });
        });

        setListaMunicipios(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }
    getMunicipios();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUltimoNumero(serie.toString());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serie]);

  useEffect(() => {
    let newTitle = 'MDF-e - Cadastro';
    if (id) {
      newTitle = 'MDF-e - Edição';
    }

    dispatch(actions.titleHeaderChange({ newTitle }));

    const chaveAcesso = get(payloadCadastro, 'chaveAcesso', '');

    if (!id) return; // tipoMovimentacao vem do handleregister do consultamdfe

    const getDataById = async () => {
      const { data } = await axiosAdmin.get(`/mdfe/${id}`);

      // console.log('mdfe: ', data);

      if (data.flag === 'T') {
        setShowConfirmEdicaoEnviada(true);
        setDisableEdicaoEnviada(true);
      }
      return data;
    };

    const setarListaMunicipioCarrega = (data) => {
      let itemMunicipioCarregamento = 0;
      const _dataMunicipioCarregamento = [];

      data.listaMDFeMunicipioCarregamento.forEach(el => {
        itemMunicipioCarregamento = itemMunicipioCarregamento + 1;
        _dataMunicipioCarregamento.push({
          item: itemMunicipioCarregamento,
          xMunCarrega: el.xmunCarrega,
          cMunCarrega: el.cmunCarrega,
        });
      });

      setListaMunicipioCarregamento(_dataMunicipioCarregamento);
    };

    const setarListaUfPercurso = (data) => {
      let itemUfPercurso = 0;
      const _dataUfPercurso = [];

      data.listaMDFeUFPercurso.forEach(el => {
        itemUfPercurso = itemUfPercurso + 1;
        _dataUfPercurso.push({
          item: itemUfPercurso,
          ufPercurso: el.uf,
        });
      });

      setListaUfPercurso(_dataUfPercurso);
    };

    const setarListaLacre = (data) => {
      let itemLacre = 0;
      const _dataLacre = [];

      data.listaMDFeLacre.forEach(el => {
        itemLacre = itemLacre + 1;
        _dataLacre.push({
          item: itemLacre,
          numeroLacre: el.numero,
        });
      });

      setListaLacre(_dataLacre);
    };

    const setarListaSeguroCarga = (data) => {
      let itemSeguroCarga = 0;
      const _dataSeguroCarga = [];

      data.listaMDFeSeguro.forEach(el => {
        let itemAverbacao = 0;
        const _dataAverbacao = [];

        el.listaMDFeSeguroAverbacao.forEach(el => {
          itemAverbacao = itemAverbacao + 1;
          _dataAverbacao.push({
            item: itemAverbacao,
            numeroAverbacao: el.numero,
          });
        });

        itemSeguroCarga = itemSeguroCarga + 1;
        _dataSeguroCarga.push({
          item: itemSeguroCarga,
          responsavel: el.responsavel,
          cnpjResponsavel: el.cnpjResponsavel,
          nomeSeguradora: el.nomeSeguradora,
          cnpjSeguradora: el.cnpjSeguradora,
          numeroApolice: el.napol,
          listaMDFeSeguroAverbacao: _dataAverbacao,
        });
      });

      setListaSeguroCarga(_dataSeguroCarga);
    };

    const setarRodoviario = (data) => {
      setRntrc(data.rodoviario.rntrc);
      setIdVeiculo(data.rodoviario.veiculo ? data.rodoviario.veiculo.id : '');
      setPlacaVeiculo(data.rodoviario.veiculo ? data.rodoviario.veiculo.placa : '');

      let itemCiot = 0;
      const _dataCiot = [];
      data.rodoviario.listaMDFeCiot.forEach(el => {
        itemCiot = itemCiot + 1;
        _dataCiot.push({
          item: itemCiot,
          codigo: el.codigo,
          cnpj: el.cnpj,
          cpf: el.cpf,
        });
      });

      setListaCiot(_dataCiot);

      let itemValePedagio = 0;
      const _dataValePedagio = [];
      data.rodoviario.listaMDFeValePedagio.forEach(el => {
        itemValePedagio = itemValePedagio + 1;
        _dataValePedagio.push({
          item: itemValePedagio,
          valePedagioCnpjForn: el.valePedagioCnpjForn,
          valePedagioCnpjPg: el.valePedagioCnpjPg,
          valePedagioNCompra: el.valePedagioNCompra,
          valePedagioValor: el.valePedagioValor,
        });
      });

      setListaValePedagio(_dataValePedagio);

      let itemContratante = 0;
      const _dataContratante = [];
      data.rodoviario.listaMDFeContratante.forEach(el => {
        itemContratante = itemContratante + 1;
        _dataContratante.push({
          item: itemContratante,
          cnpj: el.cnpj,
          cpf: el.cpf,
        });
      });

      setListaContratante(_dataContratante);

      let itemReboque = 0;
      const _dataReboque = [];
      data.rodoviario.listaMDFeReboque.forEach(el => {
        itemReboque = itemReboque + 1;
        _dataReboque.push({
          item: itemReboque,
          id: el.veiculo.id,
          placaReboque: el.veiculo.placa,
        });
      });

      setListaReboque(_dataReboque);
    };

    const setarListaAutXml = (data) => {
      let itemAutXml = 0;
      const _dataAutXml = [];

      data.listaMDFeAutXML.forEach(el => {
        itemAutXml = itemAutXml + 1;
        _dataAutXml.push({
          item: itemAutXml,
          cnpj: el.cnpj,
          cpf: el.cpf,
        });
      });

      setListaAutXml(_dataAutXml);
    };

    const setarListaDocFiscal = (data) => {
      let itemDoc = 0;
      const _dataDoc = [];

      data.listaMDFeDoc.forEach(el => {
        itemDoc = itemDoc + 1;
        _dataDoc.push({
          item: itemDoc,
          mod: el.mod,
          chaveAcesso: el.chaveAcesso,
          segCodBarra: el.segCodBarra,
          codigoMunicipioDescarrega: el.codigoMunicipioDescarrega,
          reentrega: el.reentrega ? el.reentrega : false,
        });
      });

      setListaDocFiscal(_dataDoc);
    };

    async function getData() {
      try {
        let data = null;

        if (id > 0) {
          data = await getDataById();
        }

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        if (data.idUsuario !== idUsuario) {
          navigate('/consultamdfe');
          toast.error('Acesso negado');
        }

        const _dhEmi = converteData(data.dhEmi, true);
        const _dhIniViagem = converteData(data.dhIniViagem, true);

        console.log('get data: ', data);

        setFlag(data.flag);
        setChaveAcesso(data.chaveAcesso);
        setProtocolo(data.protocolo);
        setNumeroRecibo(data.numeroRecibo);
        setNumeroMdf(data.numeroMdf);
        setDhEmi(_dhEmi);
        setTipoEmissao(data.tpEmis);
        setSerie(data.serie);
        setModalidade(data.modal);
        setTipoEmitente(data.tpEmit);
        setTipoTransportador(data.tpTransp);
        setUfInicio(data.ufIni);
        setUfFim(data.ufFim);

        setPlacaVeiculo(data.veiculo ? data.veiculo.placa : '');
        setIdReboque(data.reboque ? data.reboque.id : '');
        setPlacaReboque(data.reboque ? data.reboque.placa : '');

        setDhIniViagem(_dhIniViagem);

        setRntrc(data.rntrc);
        setValePedagioCnpjForn(data.valePedagioCnpjForn);
        setValePedagioCnpjPg(data.valePedagioCnpjPg);
        setValePedagioNCompra(data.valePedagioNCompra);
        setValePedagioValor(data.valePedagioValor);

        setarListaDocFiscal(data);
        setarListaMunicipioCarrega(data);
        setarListaUfPercurso(data);
        setarListaSeguroCarga(data);
        setarListaLacre(data);
        setarListaAutXml(data);
        setarRodoviario(data);

        setIdParticipante(data.participante.id);
        setUfParticipante(data.participante.uf);
        setCnpjEmpresa(data.cnpjEmpresa.trim());

        setInfCpl(data.infCpl ? data.infCpl.trim() : '');
        setInfAdFisco(data.infAdFisco);

        setVOutro(data.totalVOutro);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      }
    }

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, navigate]);

  async function verificarSeMultiEmpresa(idUsuario) {
    try {
      setIsLoading(true);

      const params = `/usuario/${idUsuario}`;

      const response = await axiosAdmin.get(params);

      const _listaEmpresas = get(response.data, 'listaEmpresas', []);

      const listDropdown = [];

      let cnpjPreenchido = false;
      let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

      if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
        setCnpjEmpresa(_cnpjEmpresaStore);
        cnpjPreenchido = true;
      }

      _listaEmpresas.forEach(element => {
        if (!cnpjPreenchido) {
          setCnpjEmpresa(element.cnpj);
          _cnpjEmpresaStore = element.cnpj;
          cnpjPreenchido = true;
        }

        if (element.cnpj === _cnpjEmpresaStore) {
          setUfEmpresa(element.uf); // para calculo tributos
        }

        const nomeEmpresa = element.nomeFantasia ? element.nomeFantasia : element.razaoSocial;

        listDropdown.push({ label: nomeEmpresa, value: element.cnpj });
      });

      listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

      setListaEmpresas(listDropdown);
    } catch (err) {
      // if (mostrarMensagemErro) {
      //   displayErrors(err, dispatch, navigate, '/', '');
      // }
      // mostrarMensagemErro = false;
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    async function getUnidadeFederacao() {
      const listDropdown = [];
      try {
        setIsLoading(true);

        const { data: _ufs } = await axiosAdmin.get('/unidadefederacao/');

        _ufs.forEach(element => {
          listDropdown.push({ label: element.sigla, value: element.sigla });
        });
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }
    getUnidadeFederacao();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function limparCamposAutXml() {
    setAutXmlCnpj('');
    setAutXmlCpf('');
  }

  function limparCamposCiot() {
    setCiotCodigo('');
    setCiotCnpj('');
    setCiotCpf('');
  }

  function limparCamposDocFiscalTransporte() {
    setDocFiscalTranspTipo('RODOVIARIOTRACAO');
    setDocFiscalTranspIdentificacao('');
    setDocFiscalTranspNLacres('');
    setDocFiscalTranspQtdeRateada('');
  }

  function limparCamposDocFiscalCarga() {
    setDocFiscalUnidadeCargaTipo('CONTAINER');
    setDocFiscalUnidadeCargaIdentificacao('');
    setDocFiscalUnidadeCargaNLacres('');
    setDocFiscalUnidadeCargaQtdeRateada('');
  }

  function limparCamposDocFiscal() {
    setDocFiscalModelo('55');
    setDocFiscalChaveAcesso('');
    setDocFiscalUfDescarregamento('AL');
    setDocFiscalTranspTipo('RODOVIARIOTRACAO');
    setDocFiscalTranspIdentificacao('');
    setDocFiscalTranspNLacres('');
    setDocFiscalTranspQtdeRateada('');
    setDocFiscalUnidadeCargaTipo('CONTAINER');
    setDocFiscalUnidadeCargaIdentificacao('');
    setDocFiscalUnidadeCargaNLacres('');
    setDocFiscalUnidadeCargaQtdeRateada('');
    setDocFiscalProdutoPerigosoOnu('');
    setDocFiscalProdutoPerigosoClasse('');
    setDocFiscalProdutoPerigosoNomeEmbarque('');
    setDocFiscalProdutoPerigosoGrupoEmbalagem('');
    setDocFiscalProdutoPerigosoQtdePorProduto('');
    setDocFiscalProdutoPerigosoQtdeETipoVolume('');
  }

  function limparCamposValePedagio() {
    setValePedagioCnpjForn('');
    setValePedagioCnpjPg('');
    setValePedagioNCompra('');
    setValePedagioValor('');
  }

  function limparCamposContratante() {
    setContratanteCnpj('');
    setContratanteCpf('');
  }

  function limparCamposReboque() {
    setIdReboque('');
    setPlacaReboque('');
  }

  const onHideCadastroAutXml = () => {
    limparCamposAutXml();
    setShowCadastroAutXml(false);
  };

  const onHideCadastroCiot = () => {
    limparCamposCiot();
    setShowCadastroCiot(false);
  };

  const onHideCadastroDocFiscalTransporte = () => {
    limparCamposDocFiscalTransporte();
    setShowCadastroDocFiscalTransporte(false);
  };

  const onHideCadastroDocFiscalCarga = () => {
    limparCamposDocFiscalCarga();
    setShowCadastroDocFiscalCarga(false);
  };

  const onHideCadastroDocFiscal = () => {
    limparCamposDocFiscal();
    setShowCadastroDocFiscal(false);
  };

  const onHideCadastroValePedagio = () => {
    limparCamposValePedagio();
    setShowCadastroValePedagio(false);
  };

  const onHideCadastroContratante = () => {
    limparCamposContratante();
    setShowCadastroContratante(false);
  };

  const onHideCadastroReboque = () => {
    limparCamposReboque();
    setShowCadastroReboque(false);
  };

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);
      const cnpjEmpresa = get(payloadCadastro, 'cnpjEmpresa', '');

      let _dhEmi = null;
      if (dhEmi) {
        _dhEmi = formataDataMoment(dhEmi, '-', true);
      }

      let _dhIniViagem = null;
      if (dhIniViagem) {
        _dhIniViagem = formataDataMoment(dhIniViagem, '-', true);
      }

      let itemMunicipioCarregamento = 0;
      const _dataMunicipioCarregamento = [];
      if (listaMunicipioCarregamento !== undefined) {
        listaMunicipioCarregamento.forEach(elementMunicipioCarregamento => {
          itemMunicipioCarregamento = itemMunicipioCarregamento + 1;
          const municipioCarregamento = {
            item: itemMunicipioCarregamento,
            cMunCarrega: elementMunicipioCarregamento.cMunCarrega,
            xMunCarrega: elementMunicipioCarregamento.xMunCarrega,
          };

          _dataMunicipioCarregamento.push(municipioCarregamento);
        });
      }

      let itemUfPercurso = 0;
      const _dataUfPercurso = [];
      if (listaUfPercurso !== undefined) {
        listaUfPercurso.forEach(el => {
          itemUfPercurso = itemUfPercurso + 1;
          const ufPercurso = {
            item: itemUfPercurso,
            uf: el.ufPercurso,
          };

          _dataUfPercurso.push(ufPercurso);
        });
      }

      let itemLacre = 0;
      const _dataLacre = [];
      if (listaLacre !== undefined) {
        listaLacre.forEach(el => {
          itemLacre = itemLacre + 1;
          const numeroLacre = {
            item: itemLacre,
            numero: el.numeroLacre,
          };

          _dataLacre.push(numeroLacre);
        });
      }

      let itemSeguroCarga = 0;
      const _dataSeguroCarga = [];
      if (listaSeguroCarga !== undefined) {
        listaSeguroCarga.forEach(el => {
          let itemAverbacao = 0;
          const _dataAverbacao = [];
          if (listaAverbacao !== undefined) {
            listaAverbacao.forEach(el => {
              itemAverbacao = itemAverbacao + 1;
              const averbacao = {
                item: itemAverbacao,
                numero: el.numeroAverbacao,
              };
              _dataAverbacao.push(averbacao);
            });
          }

          itemSeguroCarga = itemSeguroCarga + 1;
          const seguroCarga = {
            item: itemSeguroCarga,
            responsavel: el.responsavel,
            cnpjResponsavel: el.cnpjResponsavel,
            nomeSeguradora: el.nomeSeguradora,
            cnpjSeguradora: el.cnpjSeguradora,
            nApol: el.numeroApolice,
            listaMDFeAverbacaoDTO: _dataAverbacao,
          };

          _dataSeguroCarga.push(seguroCarga);
        });
      }

      let itemAutXml = 0;
      const _dataAutXml = [];
      if (listaAutXml !== undefined) {
        listaAutXml.forEach(el => {
          itemAutXml = itemAutXml + 1;
          const autXml = {
            item: itemAutXml,
            cnpj: el.cnpj,
            cpf: el.cpf,
          };

          _dataAutXml.push(autXml);
        });
      }

      let itemCiot = 0;
      const _dataCiot = [];
      if (listaCiot !== undefined) {
        listaCiot.forEach(el => {
          itemCiot = itemCiot + 1;
          const ciot = {
            item: itemCiot,
            codigo: el.codigo,
            cnpj: el.cnpj,
            cpf: el.cpf,
          };

          _dataCiot.push(ciot);
        });
      }

      let itemDocFiscal = 0;
      const _dataDocFiscal = [];
      if (listaDocFiscal !== undefined) {
        listaDocFiscal.forEach(el => {
          itemDocFiscal = itemDocFiscal + 1;
          const docFiscal = {
            item: itemDocFiscal,
            mod: el.mod,
            chaveAcesso: el.chaveAcesso,
            segCodBarra: el.segCodBarra,
            codigoMunicipioDescarrega: el.codigoMunicipioDescarrega,
            reentrega: el.reentrega,
          };

          _dataDocFiscal.push(docFiscal);
        });
      }

      let itemDocFiscalTransporte = 0;
      const _dataDocFiscalTransporte = [];
      if (listaDocFiscalTransporte !== undefined) {
        listaDocFiscalTransporte.forEach(el => {
          itemDocFiscalTransporte = itemDocFiscalTransporte + 1;
          const docFiscalTransporte = {
            item: itemDocFiscalTransporte,
          };

          _dataDocFiscalTransporte.push(docFiscalTransporte);
        });
      }

      let itemContratante = 0;
      const _dataContratante = [];
      if (listaContratante !== undefined) {
        listaContratante.forEach(el => {
          itemContratante = itemContratante + 1;
          const contratante = {
            item: itemContratante,
            cnpj: el.cnpj,
            cpf: el.cpf,
          };

          _dataContratante.push(contratante);
        });
      }

      let itemReboque = 0;
      const _dataReboque = [];
      if (listaReboque !== undefined) {
        listaReboque.forEach(el => {
          itemReboque = itemReboque + 1;
          const reboque = {
            item: itemReboque,
            idReboque: el.id,
          };

          _dataReboque.push(reboque);
        });
      }

      let itemValePedagio = 0;
      const _dataValePedagio = [];
      if (listaValePedagio !== undefined) {
        listaValePedagio.forEach(el => {
          itemValePedagio = itemValePedagio + 1;
          const valePedagio = {
            item: itemValePedagio,
            valePedagioCnpjForn: el.valePedagioCnpjForn,
            valePedagioCnpjPg: el.valePedagioCnpjPg,
            valePedagioNCompra: el.valePedagioNCompra,
            valePedagioValor: el.valePedagioValor,
          };

          _dataValePedagio.push(valePedagio);
        });
      }

      const _dataRodoviario = {
        rntrc,
        idVeiculo,
        listaMDFeCiotDTO: _dataCiot,
        listaMDFeValePedagioDTO: _dataValePedagio,
        listaMDFeContratanteDTO: _dataContratante,
        listaMDFeReboqueDTO: _dataReboque,
      };

      let informacaoComplementar = '';

      informacaoComplementar = informacaoComplementar + ' ' + infCpl;

      const shippingData = {
        flag,
        chaveAcesso,
        protocolo,
        numeroRecibo,
        idDest,
        idUsuario,
        cnpjEmpresa,
        idParticipante,
        cMdf: Math.random().toString().slice(2, 10),
        numeroMdf,
        dhEmi: _dhEmi,
        tpEmit: tipoEmitente,
        tpTransp: tipoTransportador,
        tpEmis: tipoEmissao,
        mod,
        serie,
        modal: modalidade,
        ufIni: ufInicio,
        ufFim,
        dhIniViagem: _dhIniViagem,
        valePedagioCnpjForn,
        valePedagioCnpjPg,
        infCpl: informacaoComplementar ? informacaoComplementar.trim() : '',
        infAdFisco,
        rodoviario: _dataRodoviario,

        listaMDFeDocDTO: _dataDocFiscal,
        listaMDFeMunicipioCarregamentoDTO: _dataMunicipioCarregamento,
        listaMDFeUfPercursoDTO: _dataUfPercurso,
        listaMDFeSeguroDTO: _dataSeguroCarga,
        listaMDFeLacreDTO: _dataLacre,
        listaMDFeAutXMLDTO: _dataAutXml,
      };

      console.log('Shipping data: ', shippingData);

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/mdfe/${id}`, shippingData), 'data', {});
        payloadDataTableList.id = id;
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/mdfe', shippingData), 'data', {});
      }

      toast.success('MDF-e salva com sucesso');
      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      // console.log('err:', err);
      displayErrors(err, dispatch, navigate, '', 'Erro ao salvar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    setShowConfirmCancelarDigitacaoMdfe(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (serie === '' || serie === null) {
      toast.info('Série deve ser informada');
      return false;
    }

    if (numeroMdf === '' || numeroMdf <= 0 || numeroMdf === null) {
      toast.info('Número do MDF-e inválido');
      return false;
    }

    if (dhEmi === null || dhEmi === '') {
      toast.info('Data Emissão inválida');
      return false;
    }

    salvarRegistro();
  };

  const onHideConsultaParticipante = () => {
    setShowConsultaParticipante(false);
  };

  const childToParent = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (empresaCnpj === e.cnpjEmpresa.trim()) {
        setIdParticipante(idElement);

        if (e.razaoSocial) {
          //
        }
        if (e.uf) {
          setUfParticipante(e.uf);
          ufEmpresa === e.uf ? setIdDest('INTERNA') : setIdDest('INTERESTADUAL');
        }

        setShowConsultaParticipante(false);
      } else {
        toast.warning('Participante selecionado não pertence a empresa emitente da NF-e');
      }
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const cancelDeleteAutXml = () => {
    setShowConfirmDeleteAutXml(false);
  };

  const cancelDeleteCiot = () => {
    setShowConfirmDeleteCiot(false);
  };

  const cancelDeleteDocFiscalTransporte = () => {
    setShowConfirmDeleteDocFiscalTransporte(false);
  };

  const cancelDeleteDocFiscalCarga = () => {
    setShowConfirmDeleteDocFiscalCarga(false);
  };

  const cancelDeleteDocFiscal = () => {
    setShowConfirmDeleteDocFiscal(false);
  };

  const cancelDeleteValePedagio = () => {
    setShowConfirmDeleteValePedagio(false);
  };

  const cancelDeleteContratante = () => {
    setShowConfirmDeleteContratante(false);
  };

  const cancelDeleteReboque = () => {
    setShowConfirmDeleteReboque(false);
  };

  const cancelEdicaoEnviada = () => {
    childToParentConsulta();
  };

  const cancelCancelarDigitacaoMdfe = () => {
    setShowConfirmCancelarDigitacaoMdfe(false);
  };

  const confirmEdicaoEnviada = () => {
    setShowConfirmEdicaoEnviada(false);
  };

  const confirmDeleteAutXml = () => {
    const item = get(selectedAutXml, 'item', 0);

    if (item > 0) {
      const _listaAutXml = listaAutXml;

      const index = _listaAutXml.indexOf(_listaAutXml.find(element => element.item === item));

      if (index >= 0) {
        _listaAutXml.splice(index, 1);

        _listaAutXml.forEach(element => {
          if (element.item > item) {
            const _index = _listaAutXml.indexOf(element);

            _listaAutXml[_index].item = _listaAutXml[_index].item - 1;
          }
        });

        setListaAutXml(_listaAutXml);

        setShowConfirmDeleteAutXml(false);
      }
    }
  };

  const confirmDeleteCiot = () => {
    const item = get(selectedCiot, 'item', 0);

    if (item > 0) {
      const _listaCiot = listaCiot;

      const index = _listaCiot.indexOf(_listaCiot.find(element => element.item === item));

      if (index >= 0) {
        _listaCiot.splice(index, 1);

        _listaCiot.forEach(element => {
          if (element.item > item) {
            const _index = _listaCiot.indexOf(element);

            _listaCiot[_index].item = _listaCiot[_index].item - 1;
          }
        });

        setListaCiot(_listaCiot);

        setShowConfirmDeleteCiot(false);
      }
    }
  };

  const confirmDeleteDocFiscalTransporte = () => {
    const item = get(selectedDocFiscalTransporte, 'item', 0);

    if (item > 0) {
      const _listaDocFiscalTransporte = listaDocFiscalTransporte;

      const index = _listaDocFiscalTransporte.indexOf(_listaDocFiscalTransporte.find(element => element.item === item));

      if (index >= 0) {
        _listaDocFiscalTransporte.splice(index, 1);

        _listaDocFiscalTransporte.forEach(element => {
          if (element.item > item) {
            const _index = _listaDocFiscalTransporte.indexOf(element);

            _listaDocFiscalTransporte[_index].item = _listaDocFiscalTransporte[_index].item - 1;
          }
        });

        setListaDocFiscalTransporte(_listaDocFiscalTransporte);

        setShowConfirmDeleteDocFiscalTransporte(false);
      }
    }
  };

  const confirmDeleteDocFiscalCarga = () => {
    const item = get(selectedDocFiscalCarga, 'item', 0);

    if (item > 0) {
      const _listaDocFiscalCarga = listaDocFiscalCarga;

      const index = _listaDocFiscalCarga.indexOf(_listaDocFiscalCarga.find(element => element.item === item));

      if (index >= 0) {
        _listaDocFiscalCarga.splice(index, 1);

        _listaDocFiscalCarga.forEach(element => {
          if (element.item > item) {
            const _index = _listaDocFiscalCarga.indexOf(element);

            _listaDocFiscalCarga[_index].item = _listaDocFiscalCarga[_index].item - 1;
          }
        });

        setListaDocFiscalCarga(_listaDocFiscalCarga);

        setShowConfirmDeleteDocFiscalCarga(false);
      }
    }
  };

  const confirmDeleteDocFiscal = () => {
    const item = get(selectedDocFiscal, 'item', 0);

    if (item > 0) {
      const _listaDocFiscal = listaDocFiscal;

      const index = _listaDocFiscal.indexOf(_listaDocFiscal.find(element => element.item === item));

      if (index >= 0) {
        _listaDocFiscal.splice(index, 1);

        _listaDocFiscal.forEach(element => {
          if (element.item > item) {
            const _index = _listaDocFiscal.indexOf(element);

            _listaDocFiscal[_index].item = _listaDocFiscal[_index].item - 1;
          }
        });

        setListaDocFiscal(_listaDocFiscal);

        setShowConfirmDeleteDocFiscal(false);
      }
    }
  };

  const confirmDeleteValePedagio = () => {
    const item = get(selectedValePedagio, 'item', 0);

    if (item > 0) {
      const _listaValePedagio = listaValePedagio;

      const index = _listaValePedagio.indexOf(_listaValePedagio.find(element => element.item === item));

      if (index >= 0) {
        _listaValePedagio.splice(index, 1);

        _listaValePedagio.forEach(element => {
          if (element.item > item) {
            const _index = _listaValePedagio.indexOf(element);

            _listaValePedagio[_index].item = _listaValePedagio[_index].item - 1;
          }
        });

        setListaValePedagio(_listaValePedagio);

        setShowConfirmDeleteValePedagio(false);
      }
    }
  };

  const confirmDeleteContratante = () => {
    const item = get(selectedContratante, 'item', 0);

    if (item > 0) {
      const _listaContratante = listaContratante;

      const index = _listaContratante.indexOf(_listaContratante.find(element => element.item === item));

      if (index >= 0) {
        _listaContratante.splice(index, 1);

        _listaContratante.forEach(element => {
          if (element.item > item) {
            const _index = _listaContratante.indexOf(element);

            _listaContratante[_index].item = _listaContratante[_index].item - 1;
          }
        });

        setListaContratante(_listaContratante);

        setShowConfirmDeleteContratante(false);
      }
    }
  };

  const confirmDeleteReboque = () => {
    const item = get(selectedReboque, 'item', 0);

    if (item > 0) {
      const _listaReboque = listaReboque;

      const index = _listaReboque.indexOf(_listaReboque.find(element => element.item === item));

      if (index >= 0) {
        _listaReboque.splice(index, 1);

        _listaReboque.forEach(element => {
          if (element.item > item) {
            const _index = _listaReboque.indexOf(element);

            _listaReboque[_index].item = _listaReboque[_index].item - 1;
          }
        });

        setListaReboque(_listaReboque);

        setShowConfirmDeleteReboque(false);
      }
    }
  };

  const confirmCancelarDigitacaoMdfe = () => {
    childToParentConsulta();
  };

  const handleDeleteAutXml = (e) => {
    e.preventDefault();

    const item = get(selectedAutXml, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaAutXml.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para exclusão');
        return;
      }

      setShowConfirmDeleteAutXml(true);
    } else {
      toast.warning('Por favor, selecione o CNPJ/CPF autorizado para exclusão');
    }
  };

  const handleDeleteCiot = (e) => {
    e.preventDefault();

    const item = get(selectedCiot, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaCiot.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para exclusão');
        return;
      }

      setShowConfirmDeleteCiot(true);
    } else {
      toast.warning('Por favor, selecione o CIOT para exclusão');
    }
  };

  const handleDeleteDocFiscalTransporte = (e) => {
    e.preventDefault();

    const item = get(selectedDocFiscalTransporte, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaDocFiscalTransporte.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para exclusão');
        return;
      }

      setShowConfirmDeleteDocFiscalTransporte(true);
    } else {
      toast.warning('Por favor, selecione o Transporte para exclusão');
    }
  };

  const handleDeleteDocFiscalCarga = (e) => {
    e.preventDefault();

    const item = get(selectedDocFiscalCarga, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaDocFiscalCarga.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para exclusão');
        return;
      }

      setShowConfirmDeleteDocFiscalCarga(true);
    } else {
      toast.warning('Por favor, selecione a Carga para exclusão');
    }
  };

  const handleDeleteDocFiscal = (e) => {
    e.preventDefault();

    const item = get(selectedDocFiscal, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaDocFiscal.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para exclusão');
        return;
      }

      setShowConfirmDeleteDocFiscal(true);
    } else {
      toast.warning('Por favor, selecione o Doc Fiscal para exclusão');
    }
  };

  const handleDeleteValePedagio = (e) => {
    e.preventDefault();

    const item = get(selectedValePedagio, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaValePedagio.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para exclusão');
        return;
      }

      setShowConfirmDeleteValePedagio(true);
    } else {
      toast.warning('Por favor, selecione o Vale Pedágio autorizado para exclusão');
    }
  };

  const handleDeleteContratante = (e) => {
    e.preventDefault();

    const item = get(selectedContratante, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaContratante.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para exclusão');
        return;
      }

      setShowConfirmDeleteContratante(true);
    } else {
      toast.warning('Por favor, selecione o Contratante para exclusão');
    }
  };

  const handleDeleteReboque = (e) => {
    e.preventDefault();

    const item = get(selectedReboque, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaReboque.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para exclusão');
        return;
      }

      setShowConfirmDeleteReboque(true);
    } else {
      toast.warning('Por favor, selecione o Reboque para exclusão');
    }
  };

  const handleEditAutXml = (e) => {
    e.preventDefault();

    const item = get(selectedAutXml, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaAutXml.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o CNPJ/CPF autorizado para edição');
        return;
      }

      setEditandoAutXml(item);

      limparCamposAutXml();

      setAutXmlCnpj(_itemSelecionado.cnpj);
      setAutXmlCpf(_itemSelecionado.cpf);

      setShowCadastroAutXml(true);
    }
  };

  const handleEditCiot = (e) => {
    e.preventDefault();

    const item = get(selectedCiot, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaCiot.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o CNPJ/CPF autorizado para edição');
        return;
      }

      setEditandoCiot(item);

      limparCamposCiot();

      setCiotCodigo(_itemSelecionado.codigo);
      setCiotCnpj(_itemSelecionado.cnpj);
      setCiotCpf(_itemSelecionado.cpf);

      setShowCadastroCiot(true);
    }
  };

  const handleEditDocFiscalTransporte = (e) => {
    e.preventDefault();

    const item = get(selectedDocFiscalTransporte, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaDocFiscalTransporte.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o Transporte para edição');
        return;
      }

      setEditandoDocFiscalTransporte(item);

      limparCamposDocFiscalTransporte();

      setDocFiscalTranspTipo(_itemSelecionado.tipo);
      setDocFiscalTranspIdentificacao(_itemSelecionado.identificacao);
      setDocFiscalTranspNLacres(_itemSelecionado.nLacres);
      setDocFiscalTranspQtdeRateada(_itemSelecionado.qtdeRateada);

      setShowCadastroDocFiscalTransporte(true);
    }
  };

  const handleEditDocFiscalCarga = (e) => {
    e.preventDefault();

    const item = get(selectedDocFiscalCarga, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaDocFiscalCarga.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione a Carga para edição');
        return;
      }

      setEditandoDocFiscalCarga(item);

      limparCamposDocFiscalCarga();

      setDocFiscalUnidadeCargaTipo(_itemSelecionado.tipo);
      setDocFiscalUnidadeCargaIdentificacao(_itemSelecionado.identificacao);
      setDocFiscalUnidadeCargaNLacres(_itemSelecionado.nLacres);
      setDocFiscalUnidadeCargaQtdeRateada(_itemSelecionado.qtdeRateada);

      setShowCadastroDocFiscalCarga(true);
    }
  };

  const handleEditDocFiscal = (e) => {
    e.preventDefault();

    const item = get(selectedDocFiscal, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaDocFiscal.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o Doc Fiscal para edição');
        return;
      }

      setEditandoDocFiscal(item);

      limparCamposDocFiscal();

      // console.log('_itemSelecionado: ', _itemSelecionado);

      setDocFiscalModelo(_itemSelecionado.mod);
      setDocFiscalChaveAcesso(_itemSelecionado.chaveAcesso);
      setDocFiscalReentrega(_itemSelecionado.reentrega);
      setDocFiscalSegundoCodigoBarras(_itemSelecionado.segCodBarra);
      setDocFiscalMunDescarregamento(_itemSelecionado.docFiscalXMunDescarregamento);
      setDocFiscalReentrega(_itemSelecionado.reentrega ? _itemSelecionado.reentrega : false);

      setShowCadastroDocFiscal(true);
    }
  };

  const handleEditValePedagio = (e) => {
    e.preventDefault();

    const item = get(selectedValePedagio, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaValePedagio.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o vale pedágio para edição');
        return;
      }

      setEditandoValePedagio(item);

      limparCamposValePedagio();

      setValePedagioCnpjForn(_itemSelecionado.valePedagioCnpjForn);
      setValePedagioCnpjPg(_itemSelecionado.valePedagioCnpjPg);
      setValePedagioNCompra(_itemSelecionado.valePedagioNCompra);
      setValePedagioValor(_itemSelecionado.valePedagioValor);

      setShowCadastroValePedagio(true);
    }
  };

  const handleEditContratante = (e) => {
    e.preventDefault();

    const item = get(selectedContratante, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaContratante.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o Contratante para edição');
        return;
      }

      setEditandoContratante(item);

      limparCamposContratante();

      setContratanteCnpj(_itemSelecionado.cnpj);
      setContratanteCpf(_itemSelecionado.cpf);

      setShowCadastroContratante(true);
    }
  };

  const handleEditReboque = (e) => {
    e.preventDefault();

    const item = get(selectedReboque, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaReboque.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o Reboque para edição');
        return;
      }

      setEditandoReboque(item);

      limparCamposReboque();

      setIdReboque(_itemSelecionado.id);
      setPlacaReboque(_itemSelecionado.placaReboque);

      setShowCadastroReboque(true);
    }
  };

  const handleOkAutXml = () => {
    if (autXmlCnpj === '' || autXmlCpf === '') {
      toast.warning('CNPJ ou CPF deve ser informado');
      return;
    }

    const _listaAutXml = listaAutXml;

    let item = 0;
    listaAutXml.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoAutXml && editandoAutXml > 0) {
      item = editandoAutXml;

      const index = _listaAutXml.indexOf(_listaAutXml.find(element => element.item === editandoAutXml));

      if (index >= 0) {
        _listaAutXml.splice(index, 1);
      }
    }

    _listaAutXml.push({
      item,
      cnpj: autXmlCnpj,
      cpf: autXmlCpf,
    });

    limparCamposAutXml();
    setShowCadastroAutXml(false);
  };

  const handleOkCiot = () => {
    if (ciotCodigo === '' && ciotCnpj === '' && ciotCpf === '') {
      toast.warning('Código, CNPJ e CPF devem ser informado');
      return;
    }
    if (ciotCodigo === '') {
      toast.warning('Código deve ser informado');
      return;
    }
    if (ciotCnpj === '') {
      toast.warning('CNPJ deve ser informado');
      return;
    }
    if (ciotCpf === '') {
      toast.warning('CPF deve ser informado');
      return;
    }

    const _listaCiot = listaCiot;

    let item = 0;
    listaCiot.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoCiot && editandoCiot > 0) {
      item = editandoCiot;

      const index = _listaCiot.indexOf(_listaCiot.find(element => element.item === editandoCiot));

      if (index >= 0) {
        _listaCiot.splice(index, 1);
      }
    }

    _listaCiot.push({
      item,
      codigo: ciotCodigo,
      cnpj: ciotCnpj,
      cpf: ciotCpf,
    });

    limparCamposCiot();
    setShowCadastroCiot(false);
  };

  const handleOkDocFiscalTransporte = () => {
    /* if (ciotCodigo === '' && ciotCnpj === '' && ciotCpf === '') {
      toast.warning('Código, CNPJ e CPF devem ser informado');
      return;
    }
    if (ciotCodigo === '') {
      toast.warning('Código deve ser informado');
      return;
    }
    if (ciotCnpj === '') {
      toast.warning('CNPJ deve ser informado');
      return;
    }
    if (ciotCpf === '') {
      toast.warning('CPF deve ser informado');
      return;
    } */

    const _listaDocFiscalTransporte = listaDocFiscalTransporte;

    let item = 0;
    listaDocFiscalTransporte.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoDocFiscalTransporte && editandoDocFiscalTransporte > 0) {
      item = editandoDocFiscalTransporte;

      const index = _listaDocFiscalTransporte.indexOf(_listaDocFiscalTransporte.find(element => element.item === editandoDocFiscalTransporte));

      if (index >= 0) {
        _listaDocFiscalTransporte.splice(index, 1);
      }
    }

    _listaDocFiscalTransporte.push({
      item,
    });

    limparCamposDocFiscalTransporte();
    setShowCadastroDocFiscalTransporte(false);
  };

  const handleOkDocFiscalCarga = () => {
    /* if (ciotCodigo === '' && ciotCnpj === '' && ciotCpf === '') {
      toast.warning('Código, CNPJ e CPF devem ser informado');
      return;
    }
    if (ciotCodigo === '') {
      toast.warning('Código deve ser informado');
      return;
    }
    if (ciotCnpj === '') {
      toast.warning('CNPJ deve ser informado');
      return;
    }
    if (ciotCpf === '') {
      toast.warning('CPF deve ser informado');
      return;
    } */

    const _listaDocFiscalCarga = listaDocFiscalCarga;

    let item = 0;
    listaDocFiscalCarga.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoDocFiscalCarga && editandoDocFiscalCarga > 0) {
      item = editandoDocFiscalCarga;

      const index = _listaDocFiscalCarga.indexOf(_listaDocFiscalCarga.find(element => element.item === editandoDocFiscalCarga));

      if (index >= 0) {
        _listaDocFiscalCarga.splice(index, 1);
      }
    }

    _listaDocFiscalCarga.push({
      item,
    });

    limparCamposDocFiscalCarga();
    setShowCadastroDocFiscalCarga(false);
  };

  const handleOkDocFiscal = () => {
    if (docFiscalChaveAcesso.length < 44) {
      toast.warning('Chave de Acesso deve ser informada');
      return;
    }

    const _listaDocFiscal = listaDocFiscal;

    let item = 0;
    listaDocFiscal.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoDocFiscal && editandoDocFiscal > 0) {
      item = editandoDocFiscal;

      const index = _listaDocFiscal.indexOf(_listaDocFiscal.find(element => element.item === editandoDocFiscal));

      if (index >= 0) {
        _listaDocFiscal.splice(index, 1);
      }
    }

    _listaDocFiscal.push({
      item,
      mod: docFiscalModelo,
      chaveAcesso: docFiscalChaveAcesso,
      segCodBarra: docFiscalSegundoCodigoBarras,
      codigoMunicipioDescarrega: docFiscalMunDescarregamento,
      reentrega: docFiscalReentrega,
    });

    limparCamposDocFiscal();
    setShowCadastroDocFiscal(false);
  };

  const handleOkValePedagio = () => {
    if (valePedagioCnpjForn === '' && valePedagioCnpjPg === '' && valePedagioNCompra === '' && valePedagioValor <= 0) {
      toast.warning('Campos devem ser informados');
      return;
    }
    if (valePedagioCnpjForn === '') {
      toast.warning('CNPJ fornecedor deve ser informado');
      return;
    }
    if (valePedagioCnpjPg === '') {
      toast.warning('CNPJ pagador deve ser informado');
      return;
    }
    if (valePedagioNCompra === '') {
      toast.warning('Número da compra deve ser informado');
      return;
    }
    if (valePedagioValor === '') {
      toast.warning('Valor deve ser informado');
      return;
    }

    const _listaValePedagio = listaValePedagio;

    let item = 0;
    listaValePedagio.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoValePedagio && editandoValePedagio > 0) {
      item = editandoValePedagio;

      const index = _listaValePedagio.indexOf(_listaValePedagio.find(element => element.item === editandoValePedagio));

      if (index >= 0) {
        _listaValePedagio.splice(index, 1);
      }
    }

    _listaValePedagio.push({
      item,
      valePedagioCnpjForn,
      valePedagioCnpjPg,
      valePedagioNCompra,
      valePedagioValor,
    });

    limparCamposValePedagio();
    setShowCadastroValePedagio(false);
  };

  const handleOkContratante = () => {
    if (contratanteCnpj === '' && contratanteCpf === '') {
      toast.warning('CNPJ e CPF devem ser informado');
      return;
    }
    if (contratanteCnpj === '') {
      toast.warning('CNPJ deve ser informado');
      return;
    }
    if (contratanteCpf === '') {
      toast.warning('CPF deve ser informado');
      return;
    }

    const _listaContratante = listaContratante;

    let item = 0;
    listaContratante.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoContratante && editandoContratante > 0) {
      item = editandoContratante;

      const index = _listaContratante.indexOf(_listaContratante.find(element => element.item === editandoContratante));

      if (index >= 0) {
        _listaContratante.splice(index, 1);
      }
    }

    _listaContratante.push({
      item,
      cnpj: contratanteCnpj,
      cpf: contratanteCpf,
    });

    limparCamposContratante();
    setShowCadastroContratante(false);
  };

  const handleOkReboque = () => {
    if (idReboque <= 0 && placaReboque === '') {
      toast.warning('Placa Reboque deve ser informado');
      return;
    }

    const _listaReboque = listaReboque;

    let item = 0;
    listaReboque.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoReboque && editandoReboque > 0) {
      item = editandoReboque;

      const index = _listaReboque.indexOf(_listaReboque.find(element => element.item === editandoReboque));

      if (index >= 0) {
        _listaReboque.splice(index, 1);
      }
    }

    _listaReboque.push({
      item,
      id: idReboque,
      placaReboque,
    });

    limparCamposReboque();
    setShowCadastroReboque(false);
  };

  const confirmDialogFooterTotaisNota = (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <button
            onClick={handleSubmit}
            type="button"
            className="btn btn-success"
          >
            <i className="pi pi-check"></i>
            Salvar NF-e
          </button>
          <button
            // onClick={cancelTotaisNota}
            type="button"
            className="btn btn-danger"
          >
            <i className="pi pi-times"></i>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );

  const confirmDialogFooterAutXml = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteAutXml} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteAutXml} />
    </div>
  );

  const confirmDialogFooterCiot = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteCiot} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteCiot} />
    </div>
  );

  const confirmDialogFooterDocFiscalTransporte = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteDocFiscalTransporte} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteDocFiscalTransporte} />
    </div>
  );

  const confirmDialogFooterDocFiscalCarga = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteDocFiscalCarga} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteDocFiscalCarga} />
    </div>
  );

  const confirmDialogFooterDocFiscal = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteDocFiscal} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteDocFiscal} />
    </div>
  );

  const confirmDialogFooterValePedagio = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteValePedagio} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteValePedagio} />
    </div>
  );

  const confirmDialogFooterContratante = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteContratante} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteContratante} />
    </div>
  );

  const confirmDialogFooterReboque = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteReboque} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteReboque} />
    </div>
  );

  const confirmDialogFooterEdicaoEnviada = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmEdicaoEnviada} />
      <Button label="Não" icon="pi pi-times" onClick={cancelEdicaoEnviada} />
    </div>
  );

  const confirmDialogFooterCancelarDigitacaoMdfe = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmCancelarDigitacaoMdfe} />
      <Button label="Não" icon="pi pi-times" onClick={cancelCancelarDigitacaoMdfe} />
    </div>
  );

  const childToParentVeiculo = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setIdVeiculo(e.id);
      }
      if (e.placa) {
        setPlacaVeiculo(e.placa);
      }
      setShowConsultaVeiculo(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const onHideConsultaVeiculo = () => {
    setShowConsultaVeiculo(false);
  };

  const limparVeiculo = () => {
    setIdVeiculo('');
    setPlacaVeiculo('');
  };

  const onHideConsultaMunicipio = () => {
    setShowConsultaMunicipio(false);
  };

  const childToParentReboque = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setIdReboque(e.id);
      }
      if (e.placa) {
        setPlacaReboque(e.placa);
      }
      setShowConsultaReboque(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const onHideConsultaReboque = () => {
    setShowConsultaReboque(false);
  };

  const limparReboque = () => {
    setIdReboque('');
    setPlacaReboque('');
  };

  const childToParentMunicipio = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.codigo) {
        setCMunCarregamento(e.codigo);
      }
      if (e.descricao) {
        setXMuncarregamento(e.descricao);
      }
      setShowConsultaVeiculo(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  function limparCamposUfPercurso() {
    setUfPercurso('');
  }

  function limparCamposAverbacao() {
    setNumeroAverbacao('');
  }

  function limparCamposLacre() {
    setNumeroLacre('');
  }

  function limparCamposSeguroCarga() {
    setSeguroResponsavel('EMITENTE');
    setSeguroCnpjResponsavel('');
    setSeguroNomeSeguradora('');
    setSeguroCnpjSeguradora('');
    setSeguroNumeroApolice('');
  }

  const cancelDeleteUfPercurso = () => {
    setShowConfirmDeleteUfPercurso(false);
  };

  const cancelDeleteAverbacao = () => {
    setShowConfirmDeleteAverbacao(false);
  };

  const cancelDeleteLacre = () => {
    setShowConfirmDeleteLacre(false);
  };

  const cancelDeleteSeguroCarga = () => {
    setShowConfirmDeleteSeguroCarga(false);
  };

  const confirmDeleteUfPercurso = () => {
    const item = get(selectedUfPercurso, 'item', 0);

    if (item > 0) {
      const _listaUfPercurso = listaUfPercurso;

      const index = _listaUfPercurso.indexOf(_listaUfPercurso.find(element => element.item === item));

      if (index >= 0) {
        _listaUfPercurso.splice(index, 1);

        _listaUfPercurso.forEach(element => {
          if (element.item > item) {
            const _index = _listaUfPercurso.indexOf(element);

            _listaUfPercurso[_index].item = _listaUfPercurso[_index].item - 1;
          }
        });

        setListaUfPercurso(_listaUfPercurso);

        setShowConfirmDeleteUfPercurso(false);
      }
    }
  };

  const confirmDeleteAverbacao = () => {
    const item = get(selectedAverbacao, 'item', 0);

    if (item > 0) {
      const _listaAverbacao = listaAverbacao;

      const index = _listaAverbacao.indexOf(_listaAverbacao.find(element => element.item === item));

      if (index >= 0) {
        _listaAverbacao.splice(index, 1);

        _listaAverbacao.forEach(element => {
          if (element.item > item) {
            const _index = _listaAverbacao.indexOf(element);

            _listaAverbacao[_index].item = _listaAverbacao[_index].item - 1;
          }
        });

        setListaAverbacao(_listaAverbacao);

        setShowConfirmDeleteAverbacao(false);
      }
    }
  };

  const confirmDeleteLacre = () => {
    const item = get(selectedLacre, 'item', 0);

    if (item > 0) {
      const _listaLacre = listaLacre;

      const index = _listaLacre.indexOf(_listaLacre.find(element => element.item === item));

      if (index >= 0) {
        _listaLacre.splice(index, 1);

        _listaLacre.forEach(element => {
          if (element.item > item) {
            const _index = _listaLacre.indexOf(element);

            _listaLacre[_index].item = _listaLacre[_index].item - 1;
          }
        });

        setListaLacre(_listaLacre);

        setShowConfirmDeleteLacre(false);
      }
    }
  };

  const confirmDeleteSeguroCarga = () => {
    const item = get(selectedSeguroCarga, 'item', 0);

    if (item > 0) {
      const _listaSeguroCarga = listaSeguroCarga;

      const index = _listaSeguroCarga.indexOf(_listaSeguroCarga.find(element => element.item === item));

      if (index >= 0) {
        _listaSeguroCarga.splice(index, 1);

        _listaSeguroCarga.forEach(element => {
          if (element.item > item) {
            const _index = _listaSeguroCarga.indexOf(element);

            _listaSeguroCarga[_index].item = _listaSeguroCarga[_index].item - 1;
          }
        });

        setListaSeguroCarga(_listaSeguroCarga);

        setShowConfirmDeleteSeguroCarga(false);
      }
    }
  };

  const handleDeleteUfPercurso = (e) => {
    e.preventDefault();

    const item = get(selectedUfPercurso, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaUfPercurso.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para exclusão');
        return;
      }

      setShowConfirmDeleteUfPercurso(true);
    } else {
      toast.warning('Por favor, selecione o item para exclusão');
    }
  };

  const handleDeleteAverbacao = (e) => {
    e.preventDefault();

    const item = get(selectedAverbacao, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaAverbacao.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para exclusão');
        return;
      }

      setShowConfirmDeleteAverbacao(true);
    } else {
      toast.warning('Por favor, selecione o item para exclusão');
    }
  };

  const handleDeleteLacre = (e) => {
    e.preventDefault();

    const item = get(selectedLacre, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaLacre.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para exclusão');
        return;
      }

      setShowConfirmDeleteLacre(true);
    } else {
      toast.warning('Por favor, selecione o item para exclusão');
    }
  };

  const handleDeleteSeguroCarga = (e) => {
    e.preventDefault();

    const item = get(selectedSeguroCarga, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaSeguroCarga.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para exclusão');
        return;
      }

      setShowConfirmDeleteSeguroCarga(true);
    } else {
      toast.warning('Por favor, selecione o item para exclusão');
    }
  };

  const handleEditUfPercurso = (e) => {
    e.preventDefault();

    const item = get(selectedUfPercurso, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaUfPercurso.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para edição');
        return;
      }

      setEditandoUfPercurso(item);

      setUfPercurso(_itemSelecionado.uf);

      setShowCadastroUfPercurso(true);
    }
  };

  const handleEditAverbacao = (e) => {
    e.preventDefault();

    const item = get(selectedAverbacao, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaAverbacao.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para edição');
        return;
      }

      setEditandoAverbacao(item);

      setNumeroAverbacao(_itemSelecionado.numeroAverbacao);

      setShowCadastroAverbacao(true);
    }
  };

  const handleEditLacre = (e) => {
    e.preventDefault();

    const item = get(selectedLacre, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaLacre.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para edição');
        return;
      }

      setEditandoLacre(item);

      setNumeroLacre(_itemSelecionado.numeroLacre);

      setShowCadastroLacre(true);
    }
  };

  const handleEditSeguroCarga = (e) => {
    e.preventDefault();

    const item = get(selectedSeguroCarga, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaSeguroCarga.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para edição');
        return;
      }

      setEditandoSeguroCarga(item);

      setSeguroResponsavel(_itemSelecionado.responsavel);
      setSeguroCnpjResponsavel(_itemSelecionado.cnpjResponsavel);
      setSeguroNomeSeguradora(_itemSelecionado.nomeSeguradora);
      setSeguroCnpjSeguradora(_itemSelecionado.cnpjSeguradora);
      setSeguroNumeroApolice(_itemSelecionado.numeroApolice);
      setListaAverbacao(_itemSelecionado.listaMDFeSeguroAverbacao);

      setShowCadastroSeguroCarga(true);
    }
  };

  const handleOkUfPercurso = () => {
    if (ufPercurso === '') {
      toast.warning('UF deve ser informada.');
      return;
    }

    if (ufPercurso.toUpperCase() === ufInicio || ufPercurso === ufFim) {
      toast.warning('UF percurso não deve ser igual a UF inicio ou UF fim informada.');
      return;
    }

    let existeUf = false;
    listaUfPercurso.forEach(el => {
      if (el.ufPercurso === ufPercurso) {
        existeUf = true;
      }
    });

    if (existeUf) {
      toast.warning('UF já informada para percurso.');
      return;
    }

    const _listaUfPercurso = listaUfPercurso;

    let item = 0;
    listaUfPercurso.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoUfPercurso && editandoUfPercurso > 0) {
      item = editandoUfPercurso;

      const index = _listaUfPercurso.indexOf(_listaUfPercurso.find(element => element.item === editandoUfPercurso));

      if (index >= 0) {
        _listaUfPercurso.splice(index, 1);
      }
    }

    _listaUfPercurso.push({
      item,
      ufPercurso: ufPercurso.toUpperCase(),
    });

    setListaUfPercurso(_listaUfPercurso);
    limparCamposUfPercurso();
    setShowCadastroUfPercurso(false);
  };

  const handleOkAverbacao = () => {
    if (numeroAverbacao === '') {
      toast.warning('Número averbação deve ser informada.');
      return;
    }

    let existeAverbacao = false;
    listaAverbacao.forEach(el => {
      if (el.numeroAverbacao === numeroAverbacao) {
        existeAverbacao = true;
      }
    });

    if (existeAverbacao) {
      toast.warning('Número Averbação já informada para o seguro.');
      return;
    }

    const _listaAverbacao = listaAverbacao;

    let item = 0;
    listaAverbacao.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoAverbacao && editandoAverbacao > 0) {
      item = editandoAverbacao;

      const index = _listaAverbacao.indexOf(_listaAverbacao.find(element => element.item === editandoAverbacao));

      if (index >= 0) {
        _listaAverbacao.splice(index, 1);
      }
    }

    _listaAverbacao.push({
      item,
      numeroAverbacao,
    });

    setListaAverbacao(_listaAverbacao);
    limparCamposAverbacao();
    setShowCadastroAverbacao(false);
  };

  const handleOkLacre = () => {
    if (numeroLacre === '') {
      toast.warning('Número lacre deve ser informada.');
      return;
    }

    let existeLacre = false;
    listaLacre.forEach(el => {
      if (el.numeroLacre === numeroLacre) {
        existeLacre = true;
      }
    });

    if (existeLacre) {
      toast.warning('Número Lacre já informado.');
      return;
    }

    const _listaLacre = listaLacre;

    let item = 0;
    listaLacre.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoLacre && editandoLacre > 0) {
      item = editandoLacre;

      const index = _listaLacre.indexOf(_listaLacre.find(element => element.item === editandoLacre));

      if (index >= 0) {
        _listaLacre.splice(index, 1);
      }
    }

    _listaLacre.push({
      item,
      numeroLacre,
    });

    setListaLacre(_listaLacre);
    limparCamposLacre();
    setShowCadastroLacre(false);
  };

  // eslint-disable-next-line consistent-return
  const handleOkSeguroCarga = () => {
    if (seguroCnpjResponsavel === '') {
      toast.info('Cnpj Responsável deve ser informado.');
      return false;
    }

    // validacoes de campos

    const _listaSeguroCarga = listaSeguroCarga;

    let item = 0;
    listaSeguroCarga.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoSeguroCarga && editandoSeguroCarga > 0) {
      item = editandoSeguroCarga;

      const index = _listaSeguroCarga.indexOf(_listaSeguroCarga.find(element => element.item === editandoSeguroCarga));

      if (index >= 0) {
        _listaSeguroCarga.splice(index, 1);
      }
    }

    _listaSeguroCarga.push({
      item,
      responsavel: seguroResponsavel,
      cnpjResponsavel: seguroCnpjResponsavel,
      nomeSeguradora: seguroNomeSeguradora,
      cnpjSeguradora: seguroCnpjSeguradora,
      numeroApolice: seguroNumeroApolice,
    });

    setListaSeguroCarga(_listaSeguroCarga);
    limparCamposSeguroCarga();
    setShowCadastroSeguroCarga(false);
  };

  const onHideCadastroUfPercurso = () => {
    limparCamposUfPercurso();
    setShowCadastroUfPercurso(false);
  };

  const onHideCadastroAverbacao = () => {
    limparCamposAverbacao();
    setShowCadastroAverbacao(false);
  };

  const onHideCadastroLacre = () => {
    limparCamposLacre();
    setShowCadastroLacre(false);
  };

  const onHideCadastroSeguroCarga = () => {
    limparCamposSeguroCarga();
    setShowCadastroSeguroCarga(false);
  };

  const confirmDialogFooterUfPercurso = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteUfPercurso} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteUfPercurso} />
    </div>
  );

  const confirmDialogFooterAverbacao = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteAverbacao} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteAverbacao} />
    </div>
  );

  const confirmDialogFooterLacre = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteLacre} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteLacre} />
    </div>
  );

  const confirmDialogFooterSeguroCarga = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteSeguroCarga} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteSeguroCarga} />
    </div>
  );

  function limparCamposMunicipioCarregamento() {
    setCMunCarregamento('');
    setXMuncarregamento('');
  }

  const cancelDeleteMunicipioCarregamento = () => {
    setShowConfirmDeleteMunicipioCarregamento(false);
  };

  const confirmDeleteMunicipioCarregamento = () => {
    const item = get(selectedMunicipioCarregamento, 'item', 0);

    if (item > 0) {
      const _listaMunicipioCarregamento = listaMunicipioCarregamento;

      const index = _listaMunicipioCarregamento.indexOf(_listaMunicipioCarregamento.find(element => element.item === item));

      if (index >= 0) {
        _listaMunicipioCarregamento.splice(index, 1);

        _listaMunicipioCarregamento.forEach(element => {
          if (element.item > item) {
            const _index = _listaMunicipioCarregamento.indexOf(element);

            _listaMunicipioCarregamento[_index].item = _listaMunicipioCarregamento[_index].item - 1;
          }
        });

        setListaMunicipioCarregamento(_listaMunicipioCarregamento);

        setShowConfirmDeleteMunicipioCarregamento(false);
      }
    }
  };

  const handleDeleteMunicipioCarregamento = (e) => {
    e.preventDefault();

    const item = get(selectedMunicipioCarregamento, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaMunicipioCarregamento.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para exclusão');
        return;
      }

      setShowConfirmDeleteMunicipioCarregamento(true);
    } else {
      toast.warning('Por favor, selecione o item para exclusão');
    }
  };

  const handleEditMunicipioCarregamento = (e) => {
    e.preventDefault();

    const item = get(selectedMunicipioCarregamento, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaMunicipioCarregamento.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para edição');
        return;
      }

      setEditandoMunicipioCarregamento(item);

      setCMunCarregamento(_itemSelecionado.cMunCarregamento);
      setXMuncarregamento(_itemSelecionado.xMunCarregamento);

      setShowCadastroMunicipioCarregamento(true);
    }
  };

  const handleOkMunicipioCarregamento = () => {
    if (cMunCarregamento === '') {
      toast.warning('Código do Município deve ser informado.');
      return;
    }

    let existeMunicipio = false;
    listaMunicipioCarregamento.forEach(el => {
      if (el.xMunCarrega === xMunCarregamento) {
        existeMunicipio = true;
      }
    });

    if (existeMunicipio) {
      toast.warning('Município já informado para carregamento.');
      return;
    }

    const _listaMunicipioCarregamento = listaMunicipioCarregamento;

    let item = 0;
    listaMunicipioCarregamento.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoMunicipioCarregamento && editandoMunicipioCarregamento > 0) {
      item = editandoMunicipioCarregamento;

      const index = _listaMunicipioCarregamento.indexOf(_listaMunicipioCarregamento.find(element => element.item === editandoMunicipioCarregamento));

      if (index >= 0) {
        _listaMunicipioCarregamento.splice(index, 1);
      }
    }

    _listaMunicipioCarregamento.push({
      item,
      cMunCarrega: cMunCarregamento,
      xMunCarrega: xMunCarregamento,
    });

    setListaMunicipioCarregamento(_listaMunicipioCarregamento);
    limparCamposMunicipioCarregamento();
    setShowCadastroMunicipioCarregamento(false);
  };

  const onHideCadastroMunicipioCarregamento = () => {
    limparCamposMunicipioCarregamento();
    setShowCadastroMunicipioCarregamento(false);
  };

  const confirmDialogFooterMunicipioCarregamento = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteMunicipioCarregamento} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteMunicipioCarregamento} />
    </div>
  );

  const searchMunicipios = (event) => {
    const listDropdown = [];

    listaMunicipios.forEach(element => {
      if (element.value.toLowerCase().startsWith(event.query.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))) {
        listDropdown.push({ label: element.label, value: element.value, codigo: element.codigo, uf: element.uf });
      }
    });

    setFilteredMunicipios(listDropdown);
  };

  const handleChangeMunicipio = (event) => {
    const codigo = get(event.value, 'codigo', '');

    const listDropdown = [];

    listaMunicipios.forEach(element => {
      if (element.codigo !== undefined && element.codigo === codigo) {
        listDropdown.push({ label: element.label, value: element.value, codigo: element.codigo, uf: element.uf });
      }
    });

    setCMunCarregamento('');
    setXMuncarregamento('');

    if (listDropdown.length > 0) {
      setCMunCarregamento(get(listDropdown[0], 'codigo', ''));
      setXMuncarregamento(get(listDropdown[0], 'label', ''));
    }
  };

  const handleChangeDocFiscalMunicipioDescarregamento = (event) => {
    const codigo = get(event.value, 'codigo', '');

    const listDropdown = [];

    listaMunicipios.forEach(element => {
      if (element.codigo !== undefined && element.codigo === codigo) {
        listDropdown.push({ label: element.label, value: element.value, codigo: element.codigo, uf: element.uf });
      }
    });

    setDocFiscalMunDescarregamento('');
    setDocFiscalXMunDescarregamento('');

    if (listDropdown.length > 0) {
      setDocFiscalMunDescarregamento(get(listDropdown[0], 'codigo', ''));
      setDocFiscalXMunDescarregamento(get(listDropdown[0], 'label', ''));
    }
  };

  const titulo = () => (id ? 'MDF-e - Alteração' : 'MDF-e - Inclusão');

  return (

    <div>
      <Loading isLoading={isLoading} />

      <Dialog
        header="Consulta de Veículo"
        visible={showConsultaVeiculo}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaVeiculo()}
      >
        <ConsultaVeiculo menuMode={false} childToParent={childToParentVeiculo} />
      </Dialog>

      <Dialog
        header="Consulta de Reboque"
        visible={showConsultaReboque}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaReboque()}
      >
        <ConsultaVeiculo menuMode={false} childToParent={childToParentReboque} />
      </Dialog>

      <Dialog
        header="Consulta de Participantes"
        visible={showConsultaParticipante}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaParticipante()}
      >
        <ConsultaParticipante menuMode={false} childToParent={childToParent} empresaCnpj={empresaCnpj} />
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmEdicaoEnviada}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterEdicaoEnviada}
        onHide={() => setShowConfirmEdicaoEnviada(false)}
      >
        <p>
          Nota Fiscal já foi enviada. Alteração limitada a alguns campos!
        </p>
        <p>
          Deseja Continuar?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteMunicipioCarregamento}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterMunicipioCarregamento}
        onHide={() => setShowConfirmDeleteMunicipioCarregamento(false)}
      >
        <p>
          Excluir o Município Carregamento?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteUfPercurso}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterUfPercurso}
        onHide={() => setShowConfirmDeleteUfPercurso(false)}
      >
        <p>
          Excluir a UF Percurso?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteAverbacao}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterAverbacao}
        onHide={() => setShowConfirmDeleteAverbacao(false)}
      >
        <p>
          Excluir a averbação?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteLacre}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterLacre}
        onHide={() => setShowConfirmDeleteLacre(false)}
      >
        <p>
          Excluir o lacre?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteSeguroCarga}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterSeguroCarga}
        onHide={() => setShowConfirmDeleteSeguroCarga(false)}
      >
        <p>
          Excluir o Seguro Carga?
        </p>
      </Dialog>

      <Dialog
        header="MDF-e - Municípios Carregamento"
        visible={showCadastroMunicipioCarregamento}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroMunicipioCarregamento()}
      >
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Município
              </Input100px>
              <AutoComplete
                value={xMunCarregamento}
                maxLength={40}
                suggestions={filteredMunicipios}
                completeMethod={searchMunicipios}
                field="label"
                dropdown
                onChange={e => handleChangeMunicipio(e)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkMunicipioCarregamento}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroMunicipioCarregamento(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="MDF-e - Ufs Percurso"
        visible={showCadastroUfPercurso}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroUfPercurso()}
      >
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                UF
              </Input100px>
              <Dropdown
                options={listaUf}
                value={ufPercurso}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setUfPercurso(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkUfPercurso}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroUfPercurso(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="MDF-e - Seguro - Averbação"
        visible={showCadastroAverbacao}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroAverbacao()}
      >
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Número Averbação
              </Input155px>
              <InputText
                maxLength={20}
                value={numeroAverbacao}
                onChange={e => setNumeroAverbacao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkAverbacao}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroAverbacao(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="MDF-e - Lacre"
        visible={showCadastroLacre}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroLacre()}
      >
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Número Lacre
              </Input155px>
              <InputText
                maxLength={20}
                value={numeroLacre}
                onChange={e => setNumeroLacre(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkLacre}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroLacre(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="MDF-e - Autorização XML"
        visible={showCadastroAutXml}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroAutXml()}
      >
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                CNPJ
              </Input155px>
              <InputText
                maxLength={14}
                value={autXmlCnpj}
                onChange={e => setAutXmlCnpj(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                CPF
              </Input155px>
              <InputText
                maxLength={14}
                value={autXmlCpf}
                onChange={e => setAutXmlCpf(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkAutXml}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroAutXml(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="MDF-e - CIOT"
        visible={showCadastroCiot}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroCiot()}
      >
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Código
              </Input155px>
              <InputText
                maxLength={12}
                value={ciotCodigo}
                onChange={e => setCiotCodigo(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                CNPJ
              </Input155px>
              <InputText
                maxLength={14}
                value={ciotCnpj}
                onChange={e => setCiotCnpj(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                CPF
              </Input155px>
              <InputText
                maxLength={14}
                value={ciotCpf}
                onChange={e => setCiotCpf(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkCiot}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroCiot(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="MDF-e - Doc Fiscal Transporte"
        visible={showCadastroDocFiscalTransporte}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroDocFiscalTransporte()}
      >
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Tipo Unidade Transporte
              </Input200px>
              <Dropdown
                options={listaMdfeTipoUnidTrasnporteDocFiscal}
                value={docFiscalTranspTipo}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setDocFiscalTranspTipo(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Identificação
              </Input155px>
              <InputText
                maxLength={20}
                value={docFiscalTranspIdentificacao}
                onChange={e => setDocFiscalTranspIdentificacao(e.target.value)}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Nº Lacres
              </Input100px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                maxFractionDigits={0}
                value={docFiscalTranspNLacres}
                onValueChange={e => setDocFiscalTranspNLacres(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Qtde Rateada
              </Input155px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                maxFractionDigits={0}
                value={docFiscalTranspQtdeRateada}
                onValueChange={e => setDocFiscalTranspQtdeRateada(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkDocFiscalTransporte}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroDocFiscalTransporte(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="MDF-e - Doc Fiscal Carga"
        visible={showCadastroDocFiscalCarga}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroDocFiscalCarga()}
      >
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Tipo Unidade Carga
              </Input200px>
              <Dropdown
                options={listaMdfeTipoUnidCargaDocFiscal}
                value={docFiscalUnidadeCargaTipo}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setDocFiscalUnidadeCargaTipo(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Identificação
              </Input155px>
              <InputText
                maxLength={20}
                value={docFiscalTranspIdentificacao}
                onChange={e => setDocFiscalTranspIdentificacao(e.target.value)}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Nº Lacres
              </Input100px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                maxFractionDigits={0}
                value={docFiscalTranspNLacres}
                onValueChange={e => setDocFiscalTranspNLacres(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Qtde Rateada
              </Input155px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                maxFractionDigits={0}
                value={docFiscalTranspQtdeRateada}
                onValueChange={e => setDocFiscalTranspQtdeRateada(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkDocFiscalCarga}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroDocFiscalCarga(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="MDF-e - Doc Fiscal"
        visible={showCadastroDocFiscal}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroDocFiscal()}
      >
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo Doc Fiscal*
              </Input155px>
              <Dropdown
                options={listaMdfeModeloDocFiscal}
                value={docFiscalModelo}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setDocFiscalModelo(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Chave Acesso*
              </Input155px>
              <InputText
                maxLength={44}
                value={docFiscalChaveAcesso}
                onChange={e => setDocFiscalChaveAcesso(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Reentrega
              </Input200px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={docFiscalReentrega}
                onChange={(e) => setDocFiscalReentrega(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Segundo Cód. Barras
              </Input155px>
              <InputText
                maxLength={26}
                value={docFiscalSegundoCodigoBarras}
                onChange={e => setDocFiscalSegundoCodigoBarras(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input250px className="p-inputgroup-addon">
                Município Descarregamento
              </Input250px>
              <AutoComplete
                value={docFiscalXMunDescarregamento}
                maxLength={40}
                suggestions={filteredMunicipios}
                completeMethod={searchMunicipios}
                field="label"
                dropdown
                onChange={e => handleChangeDocFiscalMunicipioDescarregamento(e)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <TabView activeIndex={activeTabDocFiscalIndex} scrollable onTabChange={(e) => setActiveTabDocFiscalIndex(e.index)}>
            <TabPanel header="Transporte">
              <div className="row form-elo">
                <div className="transporte">
                  <DataTable
                    header="Transporte - Inclusão"
                    value={listaDocFiscalTransporte}
                    size="small"
                    stripedRows
                    responsiveLayout="scroll"
                    sortField="item"
                    selectionMode="single"
                    selection={selectedDocFiscalTransporte}
                    onSelectionChange={e => setSelectedDocFiscalTransporte(e.value)}
                    selectableRows
                    dataKey="item"
                    emptyMessage="Nenhum Item cadastrado"
                  >
                    {fillColumns(columnsSetupDocFiscalTransporte)}
                  </DataTable>
                </div>
                <br />
                <div className="row form-elo">
                  <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button
                      type="button"
                      title="Inclusão Doc Fiscal Transporte"
                      className="btn btn-success"
                      style={{ borderRadius: '15px' }}
                      onClick={() => {
                        setEditandoDocFiscalTransporte(0);
                        limparCamposDocFiscalTransporte(true);
                        setShowCadastroDocFiscalTransporte(true);
                      }}
                    >
                      <i className="pi pi-plus"></i>
                    </button>
                    <button
                      type="button"
                      title="Alterar Doc Fiscal Transporte"
                      className="btn btn-warning"
                      style={{ borderRadius: '15px' }}
                      onClick={(e) => handleEditDocFiscalTransporte(e)}
                    >
                      <i className="pi pi-pencil"></i>
                    </button>
                    <button
                      type="button"
                      title="Cancelar Doc Fiscal Transporte"
                      className="btn btn-danger"
                      style={{ borderRadius: '15px' }}
                      onClick={(e) => handleDeleteDocFiscalTransporte(e)}
                    >
                      <i className="pi pi-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Carga">
              <div className="row form-elo">
                <div className="carga">
                  <DataTable
                    header="Carga - Inclusão"
                    value={listaDocFiscalCarga}
                    size="small"
                    stripedRows
                    responsiveLayout="scroll"
                    sortField="item"
                    selectionMode="single"
                    selection={selectedDocFiscalCarga}
                    onSelectionChange={e => setSelectedDocFiscalCarga(e.value)}
                    selectableRows
                    dataKey="item"
                    emptyMessage="Nenhum Item cadastrado"
                  >
                    {fillColumns(columnsSetupDocFiscalCarga)}
                  </DataTable>
                </div>
                <br />
                <div className="row form-elo">
                  <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button
                      type="button"
                      title="Inclusão Doc Fiscal Carga"
                      className="btn btn-success"
                      style={{ borderRadius: '15px' }}
                      onClick={() => {
                        setEditandoDocFiscalCarga(0);
                        limparCamposDocFiscalCarga(true);
                        setShowCadastroDocFiscalCarga(true);
                      }}
                    >
                      <i className="pi pi-plus"></i>
                    </button>
                    <button
                      type="button"
                      title="Alterar Doc Fiscal Carga"
                      className="btn btn-warning"
                      style={{ borderRadius: '15px' }}
                      onClick={(e) => handleEditDocFiscalCarga(e)}
                    >
                      <i className="pi pi-pencil"></i>
                    </button>
                    <button
                      type="button"
                      title="Cancelar Doc Fiscal Carga"
                      className="btn btn-danger"
                      style={{ borderRadius: '15px' }}
                      onClick={(e) => handleDeleteDocFiscalCarga(e)}
                    >
                      <i className="pi pi-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Produtos Perigosos">
              <div className="row form-elo">
                <div className="col-4">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Nº ONU/UN
                    </Input155px>
                    <InputText
                      maxLength={4}
                      value={setDocFiscalProdutoPerigosoOnu}
                      onChange={e => setDocFiscalProdutoPerigosoOnu(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-8">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Classe e Risco
                    </Input155px>
                    <InputText
                      title="Classe ou Subclasse/Divisão e Risco Subsidiário/Secundário"
                      maxLength={40}
                      value={setDocFiscalProdutoPerigosoOnu}
                      onChange={e => setDocFiscalProdutoPerigosoOnu(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row form-elo">
                <div className="col-12">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Nome Produto
                    </Input155px>
                    <InputText
                      title="Nome Apropriado para Embarque do Produto"
                      maxLength={150}
                      value={setDocFiscalProdutoPerigosoNomeEmbarque}
                      onChange={e => setDocFiscalProdutoPerigosoNomeEmbarque(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row form-elo">
                <div className="col-4">
                  <div className="p-inputgroup">
                    <Input200px className="p-inputgroup-addon">
                      Grupo de Embalagem
                    </Input200px>
                    <InputText
                      maxLength={6}
                      value={setDocFiscalProdutoPerigosoGrupoEmbalagem}
                      onChange={e => setDocFiscalProdutoPerigosoGrupoEmbalagem(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="p-inputgroup">
                    <Input200px className="p-inputgroup-addon">
                      Qtde Total por Produto
                    </Input200px>
                    <InputText
                      maxLength={20}
                      value={setDocFiscalProdutoPerigosoQtdePorProduto}
                      onChange={e => setDocFiscalProdutoPerigosoQtdePorProduto(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="p-inputgroup">
                    <Input200px className="p-inputgroup-addon">
                      Qtde e Tipo de Volumes
                    </Input200px>
                    <InputText
                      maxLength={60}
                      value={setDocFiscalProdutoPerigosoQtdeETipoVolume}
                      onChange={e => setDocFiscalProdutoPerigosoQtdeETipoVolume(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
          </TabView>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkDocFiscal}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroDocFiscal(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="MDF-e - Rodoviario - Vale Pedágio"
        visible={showCadastroValePedagio}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroValePedagio()}
      >
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input250px className="p-inputgroup-addon">
                CNPJ Fornecedor Vale Pedágio
              </Input250px>
              <InputText
                maxLength={14}
                value={valePedagioCnpjForn}
                onChange={e => setValePedagioCnpjForn(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input250px className="p-inputgroup-addon">
                CNPJ Pagador Vale Pedágio
              </Input250px>
              <InputText
                maxLength={14}
                value={valePedagioCnpjPg}
                onChange={e => setValePedagioCnpjPg(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input250px className="p-inputgroup-addon">
                Número comprovante Vale Pedágio
              </Input250px>
              <InputText
                maxLength={14}
                value={valePedagioNCompra}
                onChange={e => setValePedagioNCompra(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input250px className="p-inputgroup-addon">
                Valor Vale Pedágio
              </Input250px>
              <InputText
                maxLength={14}
                value={valePedagioValor}
                onChange={e => setValePedagioValor(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkValePedagio}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroValePedagio(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="MDF-e - Contratante"
        visible={showCadastroContratante}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroContratante()}
      >
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                CNPJ
              </Input155px>
              <InputText
                maxLength={14}
                value={contratanteCnpj}
                onChange={e => setContratanteCnpj(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                CPF
              </Input155px>
              <InputText
                maxLength={14}
                value={contratanteCpf}
                onChange={e => setContratanteCpf(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkContratante}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroContratante(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="MDF-e - Reboque"
        visible={showCadastroReboque}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroReboque()}
      >
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Reboque
              </Input155px>
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaReboque(true)}
              />
              <Button
                icon="pi pi-trash"
                className="p-button-danger"
                onClick={() => limparReboque()}
              />
              <InputText
                disabled
                maxLength={255}
                value={placaReboque}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkReboque}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroReboque(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="MDF-e - Seguro Carga"
        visible={showCadastroSeguroCarga}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroSeguroCarga()}
      >
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Responsável
              </Input100px>
              <Dropdown
                options={listaRespSeguro}
                value={seguroResponsavel}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setSeguroResponsavel(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                CNPJ Responsável
              </Input155px>
              <InputText
                maxLength={14}
                value={seguroCnpjResponsavel}
                onChange={e => setSeguroCnpjResponsavel(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Nome Seguradora
              </Input155px>
              <InputText
                maxLength={30}
                value={seguroNomeSeguradora}
                onChange={e => setSeguroNomeSeguradora(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                CNPJ Seguradora
              </Input155px>
              <InputText
                maxLength={14}
                value={seguroCnpjSeguradora}
                onChange={e => setSeguroCnpjSeguradora(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Número Apólice
              </Input155px>
              <InputText
                maxLength={20}
                value={seguroNumeroApolice}
                onChange={e => setSeguroNumeroApolice(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="row form-elo">
            <div className="ufs-percurso">
              <DataTable
                header="Averbação - Inclusão"
                value={listaAverbacao}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                sortField="item"
                selectionMode="single"
                selection={selectedAverbacao}
                onSelectionChange={e => setSelectedAverbacao(e.value)}
                selectableRows
                dataKey="item"
                emptyMessage="Nenhum Item cadastrado"
              >
                {fillColumns(columnsSetupAverbacao)}
              </DataTable>
            </div>
            <br />
            <div className="row form-elo">
              <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <button
                  type="button"
                  title="Inclusão Averbação"
                  className="btn btn-success"
                  style={{ borderRadius: '15px' }}
                  onClick={() => {
                    setEditandoAverbacao(0);
                    limparCamposAverbacao(true);
                    setShowCadastroAverbacao(true);
                  }}
                >
                  <i className="pi pi-plus"></i>
                </button>
                <button
                  type="button"
                  title="Alterar Averbação"
                  className="btn btn-warning"
                  style={{ borderRadius: '15px' }}
                  onClick={(e) => handleEditAverbacao(e)}
                >
                  <i className="pi pi-pencil"></i>
                </button>
                <button
                  type="button"
                  title="Cancelar Averbação"
                  className="btn btn-danger"
                  style={{ borderRadius: '15px' }}
                  onClick={(e) => handleDeleteAverbacao(e)}
                >
                  <i className="pi pi-trash"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkSeguroCarga}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroSeguroCarga(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteAutXml}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterAutXml}
        onHide={() => setShowConfirmDeleteAutXml(false)}
      >
        <p>
          Excluir o CNPJ/CPF autorizado?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteCiot}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterCiot}
        onHide={() => setShowConfirmDeleteCiot(false)}
      >
        <p>
          Excluir o CIOT?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteDocFiscalTransporte}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterDocFiscalTransporte}
        onHide={() => setShowConfirmDeleteDocFiscalTransporte(false)}
      >
        <p>
          Excluir o Transporte?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteDocFiscalCarga}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterDocFiscalCarga}
        onHide={() => setShowConfirmDeleteDocFiscalCarga(false)}
      >
        <p>
          Excluir a Carga?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteDocFiscal}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterDocFiscal}
        onHide={() => setShowConfirmDeleteDocFiscal(false)}
      >
        <p>
          Excluir o Doc. Fiscal?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteContratante}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterContratante}
        onHide={() => setShowConfirmDeleteContratante(false)}
      >
        <p>
          Excluir o Contratante?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteValePedagio}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterValePedagio}
        onHide={() => setShowConfirmDeleteValePedagio(false)}
      >
        <p>
          Excluir o Vale Pedágio?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação Cancelamento da digitação da MDF-e"
        visible={showConfirmCancelarDigitacaoMdfe}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterCancelarDigitacaoMdfe}
        onHide={() => showConfirmCancelarDigitacaoMdfe(false)}
      >
        <p>
          Deseja realmente cancelar a digitação da MDF-e?
        </p>
      </Dialog>

      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}>{titulo()}</h6>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Série*
              </Input155px>
              <InputNumber
                disabled={true}
                onFocus={(e) => e.target.select()}
                maxFractionDigits={0}
                value={serie}
                onValueChange={e => {
                  setSerie(e.target.value);
                  propsTipoMovimentacao !== 'entrada' ? setUltimoNumero(e.target.value) : '';
                }}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Número*
              </Input155px>
              <InputNumber
                disabled={disableEdicaoEnviada}
                onFocus={(e) => e.target.select()}
                maxFractionDigits={0}
                value={numeroMdf}
                onValueChange={e => setNumeroMdf(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Emissão*
              </Input155px>
              <Calendar
                disabled={disableEdicaoEnviada}
                id="mask"
                showIcon
                showTime
                hourFormat="24"
                showSeconds
                mask="99/99/9999 99:99:99"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dhEmi}
                onChange={(e) => setDhEmi(e.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo Emissão*
              </Input155px>
              <Dropdown
                options={listaMdfeTipoEmissao}
                value={tipoEmissao}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTipoEmissao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Modalidade*
              </Input155px>
              <Dropdown
                disabled={disableEdicaoEnviada}
                options={listaMdfeModalidade}
                value={modalidade}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setModalidade(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo Emitente*
              </Input155px>
              <Dropdown
                options={listaMdfeTipoEmitente}
                value={tipoEmitente}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTipoEmitente(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo Transportador*
              </Input155px>
              <Dropdown
                options={listaMdfeTipoTransportador}
                value={tipoTransportador}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTipoTransportador(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                UF Início*
              </Input100px>
              <Dropdown
                options={listaUf}
                value={ufInicio}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setUfInicio(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                UF Fim*
              </Input100px>
              <Dropdown
                options={listaUf}
                value={ufFim}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setUfFim(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Viagem
              </Input155px>
              <Calendar
                id="mask"
                showIcon
                showTime
                hourFormat="24"
                showSeconds
                mask="99/99/9999 99:99:99"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dhIniViagem}
                onChange={(e) => setDhIniViagem(e.value)}
              />
            </div>
          </div>
        </div>
        <TabView activeIndex={activeTabIndex} scrollable onTabChange={(e) => setActiveTabIndex(e.index)}>
          <TabPanel header="Docs. Fiscais - CTE/NFE">
            <div className="row form-elo">
              <div className="docs-fiscais">
                <DataTable
                  header="CTE/NFE - Inclusão"
                  value={listaDocFiscal}
                  size="small"
                  stripedRows
                  responsiveLayout="scroll"
                  sortField="item"
                  selectionMode="single"
                  selection={selectedDocFiscal}
                  onSelectionChange={e => setSelectedDocFiscal(e.value)}
                  selectableRows
                  dataKey="item"
                  emptyMessage="Nenhum Item cadastrado"
                >
                  {fillColumns(columnsSetupDocFiscal)}
                </DataTable>
              </div>
              <br />
              <div className="row form-elo">
                <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <button
                    type="button"
                    title="Inclusão CTE/NFE"
                    className="btn btn-success"
                    style={{ borderRadius: '15px' }}
                    onClick={() => {
                      setEditandoDocFiscal(0);
                      limparCamposDocFiscal(true);
                      setShowCadastroDocFiscal(true);
                    }}
                  >
                    <i className="pi pi-plus"></i>
                  </button>
                  <button
                    type="button"
                    title="Alterar CTE/NFE"
                    className="btn btn-warning"
                    style={{ borderRadius: '15px' }}
                    onClick={(e) => handleEditDocFiscal(e)}
                  >
                    <i className="pi pi-pencil"></i>
                  </button>
                  <button
                    type="button"
                    title="Cancelar CTE/NFE"
                    className="btn btn-danger"
                    style={{ borderRadius: '15px' }}
                    onClick={(e) => handleDeleteDocFiscal(e)}
                  >
                    <i className="pi pi-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Rodoviário">
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    RNTRC
                  </Input155px>
                  <InputText
                    maxLength={8}
                    value={rntrc}
                    onChange={e => setRntrc(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Veículo
                  </Input155px>
                  <Button
                    icon="pi pi-search"
                    className="p-button-warning"
                    onClick={() => setShowConsultaVeiculo(true)}
                  />
                  <Button
                    icon="pi pi-trash"
                    className="p-button-danger"
                    onClick={() => limparVeiculo()}
                  />
                  <InputText
                    disabled
                    maxLength={255}
                    value={placaVeiculo}
                  />
                </div>
              </div>
            </div>
            <TabView activeIndex={activeTabRodoviarioIndex} scrollable onTabChange={(e) => setActiveTabRodoviarioIndex(e.index)}>
              <TabPanel header="CIOT - Código Identificador da Operação de Transporte">
                <div className="row form-elo">
                  <div className="ciot">
                    <DataTable
                      header="CIOT - Inclusão"
                      value={listaCiot}
                      size="small"
                      stripedRows
                      responsiveLayout="scroll"
                      sortField="item"
                      selectionMode="single"
                      selection={selectedCiot}
                      onSelectionChange={e => setSelectedCiot(e.value)}
                      selectableRows
                      dataKey="item"
                      emptyMessage="Nenhum Item cadastrado"
                    >
                      {fillColumns(columnsSetupCiot)}
                    </DataTable>
                  </div>
                  <br />
                  <div className="row form-elo">
                    <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <button
                        type="button"
                        title="Inclusão CIOT"
                        className="btn btn-success"
                        style={{ borderRadius: '15px' }}
                        onClick={() => {
                          setEditandoCiot(0);
                          limparCamposCiot(true);
                          setShowCadastroCiot(true);
                        }}
                      >
                        <i className="pi pi-plus"></i>
                      </button>
                      <button
                        type="button"
                        title="Alterar CIOT"
                        className="btn btn-warning"
                        style={{ borderRadius: '15px' }}
                        onClick={(e) => handleEditCiot(e)}
                      >
                        <i className="pi pi-pencil"></i>
                      </button>
                      <button
                        type="button"
                        title="Cancelar CIOT"
                        className="btn btn-danger"
                        style={{ borderRadius: '15px' }}
                        onClick={(e) => handleDeleteCiot(e)}
                      >
                        <i className="pi pi-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="Vale Pedágio">
                <div className="row form-elo">
                  <div className="vale-pedagio">
                    <DataTable
                      header="Vale Pedágio - Inclusão"
                      value={listaValePedagio}
                      size="small"
                      stripedRows
                      responsiveLayout="scroll"
                      sortField="item"
                      selectionMode="single"
                      selection={selectedValePedagio}
                      onSelectionChange={e => setSelectedValePedagio(e.value)}
                      selectableRows
                      dataKey="item"
                      emptyMessage="Nenhum Item cadastrado"
                    >
                      {fillColumns(columnsSetupValePedagio)}
                    </DataTable>
                  </div>
                  <br />
                  <div className="row form-elo">
                    <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <button
                        type="button"
                        title="Inclusão Vale Pedágio"
                        className="btn btn-success"
                        style={{ borderRadius: '15px' }}
                        onClick={() => {
                          setEditandoValePedagio(0);
                          limparCamposValePedagio(true);
                          setShowCadastroValePedagio(true);
                        }}
                      >
                        <i className="pi pi-plus"></i>
                      </button>
                      <button
                        type="button"
                        title="Alterar Vale Pedágio"
                        className="btn btn-warning"
                        style={{ borderRadius: '15px' }}
                        onClick={(e) => handleEditValePedagio(e)}
                      >
                        <i className="pi pi-pencil"></i>
                      </button>
                      <button
                        type="button"
                        title="Cancelar Vale Pedágio"
                        className="btn btn-danger"
                        style={{ borderRadius: '15px' }}
                        onClick={(e) => handleDeleteValePedagio(e)}
                      >
                        <i className="pi pi-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="Contratantes">
                <div className="row form-elo">
                  <div className="contratantes">
                    <DataTable
                      header="Contratantes - Inclusão"
                      value={listaContratante}
                      size="small"
                      stripedRows
                      responsiveLayout="scroll"
                      sortField="item"
                      selectionMode="single"
                      selection={selectedContratante}
                      onSelectionChange={e => setSelectedContratante(e.value)}
                      selectableRows
                      dataKey="item"
                      emptyMessage="Nenhum Item cadastrado"
                    >
                      {fillColumns(columnsSetupContratante)}
                    </DataTable>
                  </div>
                  <br />
                  <div className="row form-elo">
                    <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <button
                        type="button"
                        title="Inclusão Contratante"
                        className="btn btn-success"
                        style={{ borderRadius: '15px' }}
                        onClick={() => {
                          setEditandoContratante(0);
                          limparCamposContratante(true);
                          setShowCadastroContratante(true);
                        }}
                      >
                        <i className="pi pi-plus"></i>
                      </button>
                      <button
                        type="button"
                        title="Alterar Contratante"
                        className="btn btn-warning"
                        style={{ borderRadius: '15px' }}
                        onClick={(e) => handleEditContratante(e)}
                      >
                        <i className="pi pi-pencil"></i>
                      </button>
                      <button
                        type="button"
                        title="Cancelar Contratante"
                        className="btn btn-danger"
                        style={{ borderRadius: '15px' }}
                        onClick={(e) => handleDeleteContratante(e)}
                      >
                        <i className="pi pi-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="Reboques">
                <div className="row form-elo">
                  <div className="reboques">
                    <DataTable
                      header="Reboques - Inclusão"
                      value={listaReboque}
                      size="small"
                      stripedRows
                      responsiveLayout="scroll"
                      sortField="item"
                      selectionMode="single"
                      selection={selectedReboque}
                      onSelectionChange={e => setSelectedReboque(e.value)}
                      selectableRows
                      dataKey="item"
                      emptyMessage="Nenhum Item cadastrado"
                    >
                      {fillColumns(columnsSetupReboque)}
                    </DataTable>
                  </div>
                  <br />
                  <div className="row form-elo">
                    <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <button
                        type="button"
                        title="Inclusão Reboque"
                        className="btn btn-success"
                        style={{ borderRadius: '15px' }}
                        onClick={() => {
                          setEditandoReboque(0);
                          limparCamposReboque(true);
                          setShowCadastroReboque(true);
                        }}
                      >
                        <i className="pi pi-plus"></i>
                      </button>
                      <button
                        type="button"
                        title="Alterar Reboque"
                        className="btn btn-warning"
                        style={{ borderRadius: '15px' }}
                        onClick={(e) => handleEditReboque(e)}
                      >
                        <i className="pi pi-pencil"></i>
                      </button>
                      <button
                        type="button"
                        title="Cancelar Contratante"
                        className="btn btn-danger"
                        style={{ borderRadius: '15px' }}
                        onClick={(e) => handleDeleteReboque(e)}
                      >
                        <i className="pi pi-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabView>

            {/*  */}
          </TabPanel>
          <TabPanel header="Municípios de Carregamento">
            <div className="row form-elo">
              <div className="municipio-carregamento">
                <DataTable
                  header="Município Carregamento - Inclusão"
                  value={listaMunicipioCarregamento}
                  size="small"
                  stripedRows
                  responsiveLayout="scroll"
                  sortField="item"
                  selectionMode="single"
                  selection={selectedMunicipioCarregamento}
                  onSelectionChange={e => setSelectedMunicipioCarregamento(e.value)}
                  selectableRows
                  dataKey="item"
                  emptyMessage="Nenhum Item cadastrado"
                >
                  {fillColumns(columnsSetupMunicipioCarregamento)}
                </DataTable>
              </div>
              <br />
              <div className="row form-elo">
                <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <button
                    type="button"
                    title="Inclusão Município Carregamento"
                    className="btn btn-success"
                    style={{ borderRadius: '15px' }}
                    onClick={() => {
                      setEditandoMunicipioCarregamento(0);
                      limparCamposMunicipioCarregamento(true);
                      setShowCadastroMunicipioCarregamento(true);
                    }}
                  >
                    <i className="pi pi-plus"></i>
                  </button>
                  <button
                    type="button"
                    title="Alterar Município Carregamento"
                    className="btn btn-warning"
                    style={{ borderRadius: '15px' }}
                    onClick={(e) => handleEditMunicipioCarregamento(e)}
                  >
                    <i className="pi pi-pencil"></i>
                  </button>
                  <button
                    type="button"
                    title="Cancelar Município Carregamento"
                    className="btn btn-danger"
                    style={{ borderRadius: '15px' }}
                    onClick={(e) => handleDeleteMunicipioCarregamento(e)}
                  >
                    <i className="pi pi-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Ufs Percurso">
            <div className="row form-elo">
              <div className="ufs-percurso">
                <DataTable
                  header="UF Percurso - Inclusão"
                  value={listaUfPercurso}
                  size="small"
                  stripedRows
                  responsiveLayout="scroll"
                  sortField="item"
                  selectionMode="single"
                  selection={selectedUfPercurso}
                  onSelectionChange={e => setSelectedUfPercurso(e.value)}
                  selectableRows
                  dataKey="item"
                  emptyMessage="Nenhum Item cadastrado"
                >
                  {fillColumns(columnsSetupUfPercurso)}
                </DataTable>
              </div>
              <br />
              <div className="row form-elo">
                <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <button
                    type="button"
                    title="Inclusão UF Percurso"
                    className="btn btn-success"
                    style={{ borderRadius: '15px' }}
                    onClick={() => {
                      setEditandoUfPercurso(0);
                      limparCamposUfPercurso(true);
                      setShowCadastroUfPercurso(true);
                    }}
                  >
                    <i className="pi pi-plus"></i>
                  </button>
                  <button
                    type="button"
                    title="Alterar UF Percurso"
                    className="btn btn-warning"
                    style={{ borderRadius: '15px' }}
                    onClick={(e) => handleEditUfPercurso(e)}
                  >
                    <i className="pi pi-pencil"></i>
                  </button>
                  <button
                    type="button"
                    title="Cancelar UF Percurso"
                    className="btn btn-danger"
                    style={{ borderRadius: '15px' }}
                    onClick={(e) => handleDeleteUfPercurso(e)}
                  >
                    <i className="pi pi-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Seguro Carga">
            <div className="row form-elo">
              <div className="seguro-carga">
                <DataTable
                  header="Seguro Carga - Inclusão"
                  value={listaSeguroCarga}
                  size="small"
                  stripedRows
                  responsiveLayout="scroll"
                  sortField="item"
                  selectionMode="single"
                  selection={selectedSeguroCarga}
                  onSelectionChange={e => setSelectedSeguroCarga(e.value)}
                  selectableRows
                  dataKey="item"
                  emptyMessage="Nenhum Item cadastrado"
                >
                  {fillColumns(columnsSetupSeguroCarga)}
                </DataTable>
              </div>
              <br />
              <div className="row form-elo">
                <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <button
                    type="button"
                    title="Inclusão Seguro Carga"
                    className="btn btn-success"
                    style={{ borderRadius: '15px' }}
                    onClick={() => {
                      setEditandoSeguroCarga(0);
                      limparCamposSeguroCarga(true);
                      setShowCadastroSeguroCarga(true);
                    }}
                  >
                    <i className="pi pi-plus"></i>
                  </button>
                  <button
                    type="button"
                    title="Alterar Seguro Carga"
                    className="btn btn-warning"
                    style={{ borderRadius: '15px' }}
                    onClick={(e) => handleEditSeguroCarga(e)}
                  >
                    <i className="pi pi-pencil"></i>
                  </button>
                  <button
                    type="button"
                    title="Cancelar Seguro Carga"
                    className="btn btn-danger"
                    style={{ borderRadius: '15px' }}
                    onClick={(e) => handleDeleteSeguroCarga(e)}
                  >
                    <i className="pi pi-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Lacres">
            <div className="row form-elo">
              <div className="lacres">
                <DataTable
                  header="Lacres - Inclusão"
                  value={listaLacre}
                  size="small"
                  stripedRows
                  responsiveLayout="scroll"
                  sortField="item"
                  selectionMode="single"
                  selection={selectedLacre}
                  onSelectionChange={e => setSelectedLacre(e.value)}
                  selectableRows
                  dataKey="item"
                  emptyMessage="Nenhum Item cadastrado"
                >
                  {fillColumns(columnsSetupLacre)}
                </DataTable>
              </div>
              <br />
              <div className="row form-elo">
                <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <button
                    type="button"
                    title="Inclusão Lacre"
                    className="btn btn-success"
                    style={{ borderRadius: '15px' }}
                    onClick={() => {
                      setEditandoLacre(0);
                      limparCamposLacre(true);
                      setShowCadastroLacre(true);
                    }}
                  >
                    <i className="pi pi-plus"></i>
                  </button>
                  <button
                    type="button"
                    title="Alterar Lacre"
                    className="btn btn-warning"
                    style={{ borderRadius: '15px' }}
                    onClick={(e) => handleEditLacre(e)}
                  >
                    <i className="pi pi-pencil"></i>
                  </button>
                  <button
                    type="button"
                    title="Cancelar Lacre"
                    className="btn btn-danger"
                    style={{ borderRadius: '15px' }}
                    onClick={(e) => handleDeleteLacre(e)}
                  >
                    <i className="pi pi-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Outras informções">
            <div className="row form-elo">
              <div className="col-12">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Infor. Complementar da NF
                  </Input200px>
                  <InputText
                    disabled={disableEdicaoEnviada}
                    maxLength={5000}
                    value={infCpl}
                    onChange={e => setInfCpl(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-12">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Infor. de Interesse do Fisco
                  </Input200px>
                  <InputText
                    disabled={disableEdicaoEnviada}
                    maxLength={2000}
                    value={infAdFisco}
                    onChange={e => setInfAdFisco(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Autorização XML">
            <div className="row form-elo">
              <div className="aut-xml">
                <DataTable
                  header="Autorização XML - Inclusão"
                  value={listaAutXml}
                  size="small"
                  stripedRows
                  responsiveLayout="scroll"
                  sortField="item"
                  selectionMode="single"
                  selection={selectedAutXml}
                  onSelectionChange={e => setSelectedAutXml(e.value)}
                  selectableRows
                  dataKey="item"
                  emptyMessage="Nenhum Item cadastrado"
                >
                  {fillColumns(columnsSetupAutXml)}
                </DataTable>
              </div>
              <br />
              <div className="row form-elo">
                <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <button
                    type="button"
                    title="Inclusão Autorização XML"
                    className="btn btn-success"
                    style={{ borderRadius: '15px' }}
                    onClick={() => {
                      setEditandoAutXml(0);
                      limparCamposAutXml(true);
                      setShowCadastroAutXml(true);
                    }}
                  >
                    <i className="pi pi-plus"></i>
                  </button>
                  <button
                    type="button"
                    title="Alterar autorização XML"
                    className="btn btn-warning"
                    style={{ borderRadius: '15px' }}
                    onClick={(e) => handleEditAutXml(e)}
                  >
                    <i className="pi pi-pencil"></i>
                  </button>
                  <button
                    type="button"
                    title="Cancelar Autorização XML"
                    className="btn btn-danger"
                    style={{ borderRadius: '15px' }}
                    onClick={(e) => handleDeleteAutXml(e)}
                  >
                    <i className="pi pi-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
        <div className="row float-end">
          <div className="col-lg-12 ">
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-success"
            >
              <i className="pi pi-check"></i>
              Continuar
            </button>
            <button
              onClick={handleCancel}
              type="button"
              className="btn btn-danger"
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
}
