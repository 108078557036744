import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { get, orderBy, sortBy } from 'lodash';

import Modal from 'react-bootstrap/Modal';

import 'react-toastify/dist/ReactToastify.css';

import { displayErrors } from '../../../../util/diversos';
import { fillColumns } from '../../../../components/table';
import { Input100px, InputCampoBusca100px } from '../../../../styles/inputGroup';

import CadastroEstampariaGola from './cadastroEstampariaGola';
import store from '../../../../store/store';
import axiosAdmin from '../../../../services/axiosAdmin';
import Card from '../../../../components/card';
import Loading from '../../../loading';

export default function ConsultaEstampariaGola() {
  const [isLoading, setIsLoading] = useState(false);
  const [dataTableList, setDataTableList] = useState([]);

  const [modalLogShow, setModalLogShow] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [selectedItens, setSelectedItens] = useState(null);
  const [findField, setFindField] = useState('');
  const [sortField, setSortField] = useState('descricao');
  const [sortOrder, setSortOrder] = useState(null);
  const [showCadastro, setShowCadastro] = useState(false);
  const [payloadCadastro, setPayloadCadastro] = useState({});

  const [idGola, setIdGola] = useState('0');
  const [logList, setLogList] = useState([]);
  const [logTotalRecords, setLogTotalRecords] = useState(0);

  const [lazyParamsLog, setLazyParamsLog] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
    rowData: null,
  });

  const [columnsSetup] = useState([
    { field: 'descricao', header: 'Descrição', sort: true },
    { field: 'valorAcrescimo', header: 'Valor Acréscimo', sort: false },
  ]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function getData(searchFilter) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/estampariagola?idUsuario=${idUsuario}`;

      if (searchFilter && searchFilter.findField !== '') {
        params = `${params}&${sortField}=${searchFilter.findField}`;
      }

      const response = await axiosAdmin.get(params);

      setDataTableList(response.data);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getData('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleFind = () => {
    const searchFilter = {
      findField,
    };

    setFindField('');

    getData(searchFilter);
  };

  const handleRegister = () => {
    const id = 0;

    setPayloadCadastro({ id });

    setShowCadastro(true);
  };

  const handleEdit = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setPayloadCadastro({ id });
      setShowCadastro(true);
    } else {
      toast.warning('Por favor, selecione o registro para edição');
    }

    e.preventDefault();
  };

  const handleDelete = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setShowConfirmDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para exclusão');
    }
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  const confirmDelete = async () => {
    setShowConfirmDialog(false);

    try {
      setIsLoading(true);

      const idExclusao = get(selectedItens, 'id', 0);

      if (idExclusao > 0) {
        await axiosAdmin.delete(`/estampariagola/${idExclusao}`);

        const _itens = dataTableList.filter(val => val.id !== idExclusao);
        setDataTableList(_itens);

        toast.success('Registro excluído com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível excluir o registro');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDialogFooter = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDelete} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDelete} />
    </div>
  );

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const onHideCadastro = () => {
    setShowCadastro(false);
  };

  const childToParentConsulta = (payloadDataTableList, id) => {
    if (payloadDataTableList) {
      if (id > 0) {
        const index = dataTableList.map(e => e.id).indexOf(id);

        if (index >= 0) {
          dataTableList[index] = payloadDataTableList;
        }
      } else {
        const _dataTableList = [];
        _dataTableList.push(payloadDataTableList);

        setDataTableList(_dataTableList);
      }
    }

    setShowCadastro(false);
  };

  // eslint-disable-next-line no-shadow
  const getLog = async (idGola, page = 0, size = 10) => {
    try {
      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/estamparialog/search?idUsuario=${idUsuario}`;

      params = `${params}&tabela=tblestampariagola`;

      if (idGola !== '' && idGola > 0) {
        params = `${params}&idItem=${idGola}`;
      }

      params = `${params}&page=${page}&size=${size}`;

      await axiosAdmin.get(params).then(response => {
        const _logEstoque = sortBy(response.data.content, 'id');

        // fazer calculo do estoque mov dep e mov prat
        lazyParamsLog.page = page;

        setLogList(_logEstoque);
        setLogTotalRecords(response.data.totalElements);
      });
    } catch (error) {
      toast.info('Erro ao consultar log.');
    }
  };

  const handleHideModalLog = () => {
    setModalLogShow(false);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function ModalLog(props) {
    const [logColumnsSetup] = useState([
      { field: 'data', header: 'Data', sort: false, dataHora: true },
      { field: 'descricaoAntes', header: 'Descrição Antes', sort: false },
      { field: 'descricaoDepois', header: 'Descrição Depois', sort: false },
      { field: 'valorAcrescimoAntes', header: 'Valor Acréscimo Antes', sort: false },
      { field: 'valorAcrescimoDepois', header: 'Valor Acréscimo Depois', sort: false },
    ]);

    if (!props.show) {
      return null;
    }

    const headerTable = (title) => (
      <div className="table-header">
        {title}
      </div>
    );

    const onPageLog = (event) => {
      // const { rowData } = lazyParamsLogEstoqueIndividual;
      setLazyParamsLog(event);

      getLog(idGola, event.page, event.rows);
    };

    return (
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter" dialogClassName="main-modal">
        <Modal.Body className="show-grid">
          <div className="row form-elo">
            <div className="col-12">
              <DataTable
                id="log"
                header={headerTable('Log')}
                value={logList}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                selectionMode="single"
                selectableRows
                dataKey="id"
                paginator
                lazy
                first={lazyParamsLog.first}
                rows={lazyParamsLog.rows}
                totalRecords={logTotalRecords}
                onPage={onPageLog}
                emptyMessage="Nenhum registro a ser exibido"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="{currentPage} de {totalPages}"
              >
                {fillColumns(logColumnsSetup)}
              </DataTable>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleHideModalLog}
            type="button"
            className="btn btn-primary"
          >
            <i className="pi pi-times"></i>
            Fechar
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  const handleViewLog = (rowData) => {
    try {
      setIsLoading(true);

      const _lazyParamsLog = { ...lazyParamsLog, first: 0, page: 0, rowData };

      setLazyParamsLog(_lazyParamsLog);

      if (!rowData) {
        toast.warning('Por favor, selecione o registro para visualização do log de estoque');
      } else {
        // eslint-disable-next-line no-shadow
        const idGola = get(selectedItens, 'id', 0);

        if (idGola > 0) {
          setIdGola(idGola);

          getLog(idGola, lazyParamsLog.page);
          setModalLogShow(true);
        } else {
          toast.warning('Malha selecionada não possui histórico de compras');
        }
      }
    } catch (err) {
      //
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Loading isLoading={isLoading} />

      <ModalLog
        show={modalLogShow}
        onHide={handleHideModalLog}
        dialogClassName="modal-90w"
      />

      <Dialog
        header=""
        closeOnEscape={false}
        visible={showCadastro}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastro()}
        className="dialog-cadastro"
      >
        <CadastroEstampariaGola
          payloadCadastro={payloadCadastro}
          childToParentConsulta={childToParentConsulta}
        />
      </Dialog>

      <Card>
        <div>
          <div className="card mb-3">
            <h6 className="card-header" style={{ textAlign: 'center' }}>Estamparia - Gola</h6>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Buscar por:
                </Input100px>
                <InputCampoBusca100px className="p-inputgroup-addon">
                  {columnsSetup.find(element => element.field === sortField).header}
                </InputCampoBusca100px>
                <InputText
                  maxLength={250}
                  value={findField}
                  onChange={e => setFindField(e.target.value)}
                />
              </div>
            </div>
            <div className="col">
              <button
                type="button"
                title="Buscar"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={handleFind}
              >
                <i className="pi pi-search"></i>
              </button>
              <button
                type="button"
                title="Cadastrar"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
                onClick={handleRegister}
              >
                <i className="pi pi-plus"></i>
              </button>
              <button
                type="button"
                title="Editar"
                className="btn btn-warning"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleEdit(e)}
              >
                <i className="pi pi-pencil"></i>
              </button>
              <button
                type="button"
                title="Log"
                className="btn btn-dark"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleViewLog(e)}
              >
                <i className="pi pi-history"></i>
              </button>
              <button
                type="button"
                title="Excluir"
                className="btn btn-danger"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleDelete(e)}
              >
                <i className="pi pi-trash"></i>
              </button>
            </div>
          </div>
        </div>
        <br />

        <div className="card">
          <DataTable
            value={dataTableList}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            selectionMode="single"
            selection={selectedItens}
            onSelectionChange={e => setSelectedItens(e.value)}
            selectableRows
            dataKey="id"
            paginator
            rows={10}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            {fillColumns(columnsSetup)}
          </DataTable>
        </div>

        <Dialog
          header="Confirmação"
          visible={showConfirmDialog}
          style={{ width: '50vw' }}
          footer={confirmDialogFooter}
          onHide={() => setShowConfirmDialog(false)}
        >
          <p>
            Excluir o registro?
          </p>
        </Dialog>
      </Card>
    </>
  );
}
