export function obterListaMdfeTipoRodado() {
  return (
    [
      { label: '01 - Truck', value: 'TRUCK' },
      { label: '02 - Toco', value: 'TOCO' },
      { label: '03 - Cavalo Mecânico', value: 'CAVALOMECANICO' },
      { label: '04 - Van', value: 'VAN' },
      { label: '05 - Utilitário', value: 'UTILITARIO' },
      { label: '06 - Outros', value: 'OUTROS' },
    ]
  );
}
