import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors } from '../../../util/diversos';
import { Input100px, Input155px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

import { obterListaMdfeTipoRodado } from '../../../services/mdfeTipoRodado';
import { obterListaMdfeTipoCarroceria } from '../../../services/mdfeTipoCarroceria';
import { obterListaMdfeTipoProprietario } from '../../../services/mdfeTipoProprietario';

import ConsultaParticipante from '../participante/consultaParticipante';
import ConsultaMotorista from '../motorista/consultaMotorista';

export default function CadastroVeiculo({ payloadCadastro, childToParentConsulta }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadCadastro, 'id', 0);

  const [placa, setPlaca] = useState('');
  const [idParticipante, setIdParticipante] = useState('');
  const [nomeParticipante, setNomeParticipante] = useState('');
  const [idMotorista, setIdMotorista] = useState('');
  const [nomeMotorista, setNomeMotorista] = useState('');
  const [renavan, setRenavan] = useState('');
  const [capKg, setCapKg] = useState(0);
  const [capM3, setCapM3] = useState(0);
  const [uf, setUf] = useState('');
  const [listaUf, setListaUf] = useState([]);
  const [tipoRodado, setTipoRodado] = useState('TRUCK');
  const [tipoCarroceria, setTipoCarroceria] = useState('NAOAPLICAVEL');

  const [proprietarioNome, setProprietarioNome] = useState('');
  const [proprietarioCnpj, setProprietarioCnpj] = useState('');
  const [proprietarioIe, setProprietarioIe] = useState('');
  const [proprietarioRntrc, setProprietarioRntrc] = useState('');
  const [proprietarioUf, setProprietarioUf] = useState('');
  const [proprietarioTipo, setProprietarioTipo] = useState('');

  const listaMdfeTipoRodado = obterListaMdfeTipoRodado();
  const listaMdfeTipoCarroceria = obterListaMdfeTipoCarroceria();
  const listaMdfeTipoProprietario = obterListaMdfeTipoProprietario();

  const [showConsultaParticipante, setShowConsultaParticipante] = useState(false);
  const [showConsultaMotorista, setShowConsultaMotorista] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let newTitle = 'Veículo - Cadastro';
    if (id) {
      newTitle = 'Veículo - Edição';
    }

    dispatch(actions.titleHeaderChange({ newTitle }));

    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/veiculo/${id}`);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        if (data.idUsuario !== idUsuario) {
          navigate('/consultaveiculo');
          toast.error('Acesso negado');
        }

        setPlaca(data.placa);
        if (data.participante) {
          setIdParticipante(data.participante.id);
          setNomeParticipante(data.participante.razaoSocial);
        }

        if (data.motorista) {
          setIdMotorista(data.motorista.id);
          setNomeMotorista(data.motorista.nome);
        }
        setRenavan(data.renavan);
        setCapKg(data.capKg);
        setCapM3(data.capM3);
        setUf(data.uf);
        setTipoRodado(data.tpRod);
        setTipoCarroceria(data.tpCar);
        setProprietarioNome(data.propNome);
        setProprietarioCnpj(data.propCnpj);
        setProprietarioIe(data.propIe);
        setProprietarioRntrc(data.propRntrc);
        setProprietarioTipo(data.propTipo);
        setProprietarioUf(data.propUf);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, id, navigate]);

  useEffect(() => {
    let mostrarMensagemErro = true;

    async function getUnidadesFederacao() {
      try {
        setIsLoading(true);

        const listDropdown = [];

        const { data: ufs } = await axiosAdmin.get('/unidadefederacao');

        ufs.forEach(element => {
          listDropdown.push({ label: element.sigla, value: element.sigla });
        });

        listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

        setListaUf(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    getUnidadesFederacao();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const cnpjEmpresa = get(payloadCadastro, 'cnpjEmpresa', '');

      const shippingData = {
        idUsuario,
        cnpjEmpresa,
        placa: placa.toUpperCase(),
        idParticipante,
        idMotorista,
        renavan,
        capKg,
        capM3,
        uf,
        tpRod: tipoRodado,
        tpCar: tipoCarroceria,
        propNome: proprietarioNome,
        propRntrc: proprietarioRntrc,
        propCnpj: proprietarioCnpj,
        propIe: proprietarioIe,
        propUf: proprietarioUf,
        propTipo: proprietarioTipo,
      };

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/veiculo/${id}`, shippingData), 'data', {});
        toast.success('Veículo atualizado com sucesso');
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/veiculo', shippingData), 'data', {});
        toast.success('Veículo cadastrado com sucesso');
      }

      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentConsulta();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!placa) {
      formErros = true;
      toast.error('Placa deve ser informada');
    }

    if (!renavan || renavan.length < 9) {
      formErros = true;
      toast.error('Renavan deve ser informada');
    }

    if (uf.length < 2) {
      formErros = true;
      toast.error('UF deve ser informada');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const childToParentParticipante = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setIdParticipante(e.id);
      }
      if (e.razaoSocial) {
        setNomeParticipante(e.razaoSocial);
      }
      setShowConsultaParticipante(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const onHideConsultaParticipante = () => {
    setShowConsultaParticipante(false);
  };

  const limparParticipante = () => {
    setIdParticipante('');
    setNomeParticipante('');
  };

  const childToParentMotorista = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setIdMotorista(e.id);
      }
      if (e.nome) {
        setNomeMotorista(e.nome);
      }
      setShowConsultaMotorista(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const onHideConsultaMotorista = () => {
    setShowConsultaMotorista(false);
  };

  const limparMotorista = () => {
    setIdMotorista('');
    setNomeMotorista('');
  };

  const titulo = () => (id ? 'Veículo - Alteração' : 'Veículo - Inclusão');

  return (
    <div>
      <Loading isLoading={isLoading} />

      <Dialog
        header="Consulta de Participante"
        visible={showConsultaParticipante}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaParticipante()}
      >
        <ConsultaParticipante menuMode={false} childToParent={childToParentParticipante} />
      </Dialog>

      <Dialog
        header="Consulta de Motorista"
        visible={showConsultaMotorista}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaMotorista()}
      >
        <ConsultaMotorista menuMode={false} childToParent={childToParentMotorista} />
      </Dialog>

      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}>{titulo()}</h6>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Placa
              </Input100px>
              <InputText
                autoFocus
                maxLength={7}
                value={placa}
                onChange={e => setPlaca(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Renavan
              </Input155px>
              <InputText
                minLength={9}
                maxLength={11}
                value={renavan}
                onChange={e => setRenavan(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Capacidade em Kg
              </Input155px>
              <InputNumber
                maxFractionDigits={0}
                min={0}
                max={999999}
                value={capKg}
                onValueChange={e => setCapKg(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Capacidade M3
              </Input155px>
              <InputNumber
                maxFractionDigits={0}
                min={0}
                max={999}
                value={capM3}
                onValueChange={e => setCapM3(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Motorista
              </Input155px>
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaMotorista(true)}
              />
              <Button
                icon="pi pi-trash"
                className="p-button-danger"
                onClick={() => limparMotorista()}
              />
              <InputText
                disabled
                maxLength={255}
                value={nomeMotorista}
              />
            </div>
          </div>
          <div className="col-8">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Cliente
              </Input155px>
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaParticipante(true)}
              />
              <Button
                icon="pi pi-trash"
                className="p-button-danger"
                onClick={() => limparParticipante()}
              />
              <InputText
                disabled
                maxLength={255}
                value={nomeParticipante}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                UF
              </Input100px>
              <Dropdown
                options={listaUf}
                value={uf}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setUf(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo de Rodado
              </Input155px>
              <Dropdown
                options={listaMdfeTipoRodado}
                value={tipoRodado}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTipoRodado(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo de Carroceria
              </Input155px>
              <Dropdown
                options={listaMdfeTipoCarroceria}
                value={tipoCarroceria}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTipoCarroceria(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <hr />
            <center> Dados do Proprietário do Veículo (Só preenchido quando o veículo não pertencer à empresa emitente do MDF-e) </center>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Nome do Proprietário
              </Input155px>
              <InputText
                maxLength={60}
                value={proprietarioNome}
                onChange={e => setProprietarioNome(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                CNPJ/CPF
              </Input100px>
              <InputText
                maxLength={14}
                value={proprietarioCnpj}
                onChange={e => setProprietarioCnpj(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Inscrição Estadual
              </Input100px>
              <InputText
                maxLength={14}
                value={proprietarioIe}
                onChange={e => setProprietarioIe(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                RNTRC
              </Input100px>
              <InputText
                maxLength={7}
                value={proprietarioRntrc}
                onChange={e => setProprietarioRntrc(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                UF
              </Input100px>
              <Dropdown
                options={listaUf}
                value={proprietarioUf}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setProprietarioUf(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo Proprietário
              </Input155px>
              <Dropdown
                options={listaMdfeTipoProprietario}
                value={proprietarioTipo}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setProprietarioTipo(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-success"
            >
              <i className="pi pi-check"></i>
              Salvar
            </button>
            <button
              onClick={handleCancel}
              type="button"
              className="btn btn-danger"
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
}
