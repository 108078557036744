export function obterListaMdfeTipoUnidCarga() {
  return (
    [
      { label: '1 - Container', value: 'CONTAINER' },
      { label: '2 - ULD', value: 'ULD' },
      { label: '3 - Pallet', value: 'PALLET' },
      { label: '4 - Outros', value: 'OUTROS' },
    ]
  );
}
