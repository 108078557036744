import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { isEmail } from 'validator';
import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator';

import ReactFileReader from 'react-file-reader';

import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
import { ToggleButton } from 'primereact/togglebutton';
import { TabView, TabPanel } from 'primereact/tabview';
import { Calendar } from 'primereact/calendar';
import { Image } from 'primereact/image';
import { Password } from 'primereact/password';
import { addLocale } from 'primereact/api';

import { Input155px, Input100px, Input50px } from '../../../styles/inputGroup';

import { soNumeros, formataTelefone, formataCnpjCpf, displayErrors, converteData, formataDataMoment } from '../../../util/diversos';

import * as actions from '../../../store/modules/auth/actions';

import Card from '../../../components/card';
import Loading from '../../loading';
import axiosAdmin from '../../../services/axiosAdmin';
import axiosViaCep from '../../../services/axiosViaCep';

export default function CadastroMunicipio({ payloadCadastro, childToParentConsulta }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadCadastro, 'id', 0);

  const [cnpj, setCnpj] = useState('');
  const [cnpjMatriz, setCnpjMatriz] = useState('');
  const [inscricaoEstadual, setInscricaoEstadual] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [nomeAbreviado, setNomeAbreviado] = useState('');
  const [endereco, setEndereco] = useState('');
  const [enderecoNumero, setEnderecoNumero] = useState('');
  const [enderecoComplemento, setEnderecoComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [codigoMunicipio, setCodigoMunicipio] = useState('');
  const [cidade, setCidade] = useState('');
  const [uf, setUf] = useState('');
  const [cep, setCep] = useState('');
  const [telefone1, setTelefone1] = useState('');
  const [telefone2, setTelefone2] = useState('');
  const [pessoaContato, setPessoaContato] = useState('');
  const [email, setEmail] = useState('');
  const [csc, setCsc] = useState('');
  const [ativo, setAtivo] = useState(true);
  const [dataLicenca, setDataLicenca] = useState(null);
  const [valorMensal, setValorMensal] = useState(0);
  const [diaVencimento, setDiaVencimento] = useState(0);
  const [gerarBoleto, setGerarBoleto] = useState(false);
  const [balancaDigitosCodigo, setBalancaDigitosCodigo] = useState(5);
  const [balancaPorValor, setBalancaPorValor] = useState(false);
  const [pisCofinsCumulativo, setPisCofinsCumulativo] = useState(false);
  const [aliquotaPis, setAliquotaPis] = useState(0);
  const [aliquotaCofins, setAliquotaCofins] = useState(0);
  const [nfeAmbiente, setNfeAmbiente] = useState('PRODUCAO');
  const [nfceAmbiente, setNfceAmbiente] = useState('PRODUCAO');
  const [cteAmbiente, setCteAmbiente] = useState('PRODUCAO');
  const [mdfeAmbiente, setMdfeAmbiente] = useState('PRODUCAO');
  const [nfeSerie, setNfeSerie] = useState('');
  const [manifestacaoNFe, setManifestacaoNFe] = useState(false);
  const [regimeTributario, setRegimeTributario] = useState('SIMPLESNACIONAL');
  const [tipoAtividade, setTipoAtividade] = useState('OUTROS');
  const [mei, setMei] = useState(false);
  const [subLimiteSimplesNacional, setSubLimiteSimplesNacional] = useState(false);
  const [apuracaoDataSaida, setApuracaoDataSaida] = useState(true);
  const [certificado, setCertificado] = useState(null);
  const [senhaCertificado, setSenhaCertificado] = useState(null);
  const [senhaAdm, setSenhaAdm] = useState(null);
  const [logotipo, setLogotipo] = useState(null);
  const [logotipoFormato, setLogotipoFormato] = useState('png');
  const [ramoNegocio, setRamoNegocio] = useState('');
  const [gerarInventario, setGerarInventario] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [listaRamoNegocios, setListaRamoNegocios] = useState([]);
  const [listaMunicipios, setListaMunicipios] = useState([]);
  const [filteredMunicipios, setFilteredMunicipios] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [listaAmbiente] = useState([
    { label: 'Homologação', value: 'HOMOLOGACAO' },
    { label: 'Produção', value: 'PRODUCAO' },
  ]);
  const [listaRegimeTributario] = useState([
    { label: 'Simples Nacional', value: 'SIMPLESNACIONAL' },
    { label: 'Lucro Presumido', value: 'LUCROPRESUMIDO' },
    { label: 'Lucro Real', value: 'LUCROREAL' },
  ]);
  const [listaTipoAtividade] = useState([
    { label: '0 - Industrial ou equiparado', value: 'INDUSTRIA' },
    { label: '1 - Outros', value: 'OUTROS' },
  ]);

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  useEffect(() => {
    let newTitle = 'Empresa - Cadastro';
    if (id) {
      newTitle = 'Empresa - Edição';
    }

    let mostrarMensagemErro = true;

    dispatch(actions.titleHeaderChange({ newTitle }));

    async function getRamoNegocios() {
      try {
        setIsLoading(true);

        const listDropdown = [{ label: 'Selecione...', value: '' }];

        const { data: _ramoNegocios } = await axiosAdmin.get('/ramonegocio');

        _ramoNegocios.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id });
        });

        setListaRamoNegocios(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getMunicipios() {
      try {
        setIsLoading(true);

        const listDropdown = [];

        const { data: _municipios } = await axiosAdmin.get('/municipio');

        _municipios.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, ''), codigo: element.codigo, uf: element.uf });
        });

        setListaMunicipios(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    getRamoNegocios();

    getMunicipios();

    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/empresa/${id}`);

        const _dataLicenca = converteData(data.dataLicenca);

        setCnpj(formataCnpjCpf(data.cnpj));
        setCnpjMatriz(formataCnpjCpf(data.cnpjMatriz));
        setInscricaoEstadual(data.inscricaoEstadual);
        setRazaoSocial(data.razaoSocial);
        setNomeFantasia(data.nomeFantasia);
        setNomeAbreviado(data.nomeAbreviado);
        setEndereco(data.endereco);
        setEnderecoNumero(data.enderecoNumero);
        setEnderecoComplemento(data.enderecoComplemento);
        setBairro(data.bairro);
        setCodigoMunicipio(data.codigoMunicipio);
        setCidade(data.cidade);
        setUf(data.uf);
        setCep(data.cep);
        setTelefone1(data.telefone_1);
        setTelefone2(data.telefone_2);
        setPessoaContato(data.pessoaContato);
        setEmail(data.email);
        setCsc(data.csc);
        setAtivo(data.ativo);
        setDataLicenca(_dataLicenca);
        setValorMensal(data.valorMensal);
        setDiaVencimento(data.diaVencimento);
        setGerarBoleto(data.gerarBoleto);
        setBalancaDigitosCodigo(data.balancaDigitosCodigo);
        setBalancaPorValor(data.balancaPorValor);
        setPisCofinsCumulativo(data.pisCofinsCumulativo);
        setAliquotaPis(data.aliquotaPis);
        setAliquotaCofins(data.aliquotaCofins);
        setNfeAmbiente(data.nfeAmbiente);
        setNfceAmbiente(data.nfceAmbiente);
        setCteAmbiente(data.cteAmbiente);
        setMdfeAmbiente(data.mdfeAmbiente);
        setNfeSerie(data.nfeSerie);
        setManifestacaoNFe(data.manifestacaoNFe);
        setRegimeTributario(data.regimeTributario);
        setMei(data.mei);
        setSubLimiteSimplesNacional(data.subLimiteSimplesNacional);
        setApuracaoDataSaida(data.apuracaoDataSaida);
        setCertificado(data.certificado);
        setSenhaCertificado(data.senhaCertificado);
        setSenhaAdm(data.senhaAdm);
        setLogotipo(data.logotipo);
        setLogotipoFormato(data.logotipoFormato);
        setRamoNegocio(data.ramoNegocio.id);
        setGerarInventario(data.gerarInventario);
        setTipoAtividade(data.tipoAtividade ? data.tipoAtividade : tipoAtividade);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const _dataLicenca = formataDataMoment(dataLicenca, '-');

      let _inscricaoEstadual = inscricaoEstadual;

      if (!_inscricaoEstadual) {
        _inscricaoEstadual = 'ISENTO';
      }

      const shippingData = {
        cnpj: soNumeros(cnpj),
        cnpjMatriz: soNumeros(cnpjMatriz),
        inscricaoEstadual: _inscricaoEstadual,
        razaoSocial,
        nomeFantasia,
        nomeAbreviado,
        endereco,
        enderecoNumero,
        enderecoComplemento,
        bairro,
        codigoMunicipio,
        cidade,
        uf,
        cep,
        telefone_1: telefone1,
        telefone_2: telefone2,
        pessoaContato,
        dataLicenca: _dataLicenca,
        valorMensal,
        diaVencimento,
        gerarBoleto,
        balancaDigitosCodigo,
        balancaPorValor,
        pisCofinsCumulativo,
        aliquotaPis,
        aliquotaCofins,
        nfeAmbiente,
        nfceAmbiente,
        cteAmbiente,
        mdfeAmbiente,
        nfeSerie,
        manifestacaoNFe,
        regimeTributario,
        mei,
        subLimiteSimplesNacional,
        apuracaoDataSaida,
        certificado,
        senhaCertificado,
        senhaAdm,
        logotipo,
        logotipoFormato,
        ramoNegocio,
        email,
        csc,
        ativo,
        gerarInventario,
        tipoAtividade,
      };

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = await axiosAdmin.put(`/empresa/${id}`, shippingData);
        toast.success('Empresa atualizada com sucesso');
      } else {
        payloadDataTableList = await axiosAdmin.post('/empresa', shippingData);
        toast.success('Empresa cadastrada com sucesso');
      }

      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentConsulta();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!cnpj) {
      formErros = true;
      toast.error('CNPJ deve ser informado');
    } else if (!cpfValidator.isValid(soNumeros(cnpj)) && !cnpjValidator.isValid(soNumeros(cnpj))) {
      formErros = true;
      toast.error('CPF/CNPJ inválido');
    }
    if (razaoSocial.length < 3 || razaoSocial.length > 100) {
      formErros = true;
      toast.error('Razão Social deve ter entre 3 a 100 caracteres');
    }
    if (!endereco) {
      formErros = true;
      toast.error('Endereço deve ser informado.');
    }
    if (!enderecoNumero) {
      formErros = true;
      toast.error('Número do endereço deve ser informado.');
    }
    if (!bairro) {
      formErros = true;
      toast.error('Bairro deve ser informado.');
    }
    if (!ramoNegocio) {
      formErros = true;
      toast.error('Ramo de Negócio deve ser informado');
    }
    if (!codigoMunicipio) {
      formErros = true;
      toast.error('Código do Município deve ser informado');
    }
    if (codigoMunicipio && codigoMunicipio.length !== 7) {
      formErros = true;
      toast.error('Código do Município deve ter 7 caracteres');
    }
    if (email && !isEmail(email)) {
      formErros = true;
      toast.error('E-mail inválido.');
    }
    if (valorMensal < 0) {
      formErros = true;
      toast.error('Valor mensal não pode ser negativo');
    }
    if (valorMensal <= 0 && !cnpjMatriz) {
      formErros = true;
      toast.error('Valor mensal deve ser informado, exceto se informado Cnpj da Matriz');
    }
    if (diaVencimento < 0) {
      formErros = true;
      toast.error('Dia de vencimento inválido');
    }
    if (gerarBoleto && diaVencimento <= 0) {
      formErros = true;
      toast.error('Dia de vencimento deve ser informado para geração de boleto');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const onBlurCep = (e) => {
    e.preventDefault();

    async function getViaCep() {
      try {
        setIsLoading(true);

        const cepConsulta = cep.replace(/[^0-9]/g, '');

        const { data } = await axiosViaCep.get(`/${cepConsulta}/json/`);

        if (!data.erro && !endereco) {
          setEndereco(get(data, 'logradouro', ''));
          setEnderecoComplemento(get(data, 'complemento'));
          setBairro(get(data, 'bairro', ''));
          setCodigoMunicipio(get(data, 'ibge', ''));
          setCidade(get(data, 'localidade', ''));
          setUf(get(data, 'uf', ''));
        }
      } finally {
        setIsLoading(false);
      }
    }
    if (cep && cep.length === 10) {
      getViaCep();
    }
  };

  const searchMunicipios = (event) => {
    const listDropdown = [];

    listaMunicipios.forEach(element => {
      if (element.value.toLowerCase().startsWith(event.query.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))) {
        listDropdown.push({ label: element.label, value: element.value, codigo: element.codigo, uf: element.uf });
      }
    });

    setFilteredMunicipios(listDropdown);
  };

  const onBlurCodigoMunicipio = (e) => {
    const listDropdown = [];

    listaMunicipios.forEach(element => {
      if (element.codigo !== undefined && element.codigo === e) {
        listDropdown.push({ label: element.label, value: element.value, codigo: element.codigo, uf: element.uf });
      }
    });

    setCidade('');
    setUf('');

    if (listDropdown.length > 0) {
      setCidade(get(listDropdown[0], 'label', ''));
      setUf(get(listDropdown[0], 'uf', ''));
    }
  };

  const handleChangeCidade = (event) => {
    const codigo = get(event.value, 'codigo', '');

    if (codigo !== '' && codigo.length === 7) {
      setCodigoMunicipio(codigo);
      onBlurCodigoMunicipio(codigo);
    } else {
      setCidade(event.value);
    }
  };

  const handleImportarCertificado = files => {
    const reader = new FileReader();
    reader.onload = function () {
      setCertificado(reader.result.split(',')[1]);
    };
    reader.readAsDataURL(files[0]);
  };

  const handleImportarLogotipo = files => {
    const reader = new FileReader();

    reader.onload = function () {
      const textoSplit = files[0].type.split('/');
      if (textoSplit[0] !== 'image') {
        toast.error('Arquivo não é uma imagem válida!');
      } else {
        setLogotipo(reader.result.split(',')[1]);

        setLogotipoFormato(textoSplit[1]);
      }
    };

    reader.readAsDataURL(files[0]);
  };

  const titulo = () => (id ? 'Empresa - Alteração' : 'Empresa - Inclusão');

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}>{titulo()}</h6>
        </div>
        <TabView activeIndex={activeTabIndex} scrollable onTabChange={(e) => setActiveTabIndex(e.index)}>
          <TabPanel header="Principal">
            <div className="row form-elo">
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    CPF/CNPJ
                  </Input100px>
                  <InputText
                    maxLength={20}
                    value={cnpj}
                    onChange={e => setCnpj(e.target.value)}
                    onFocus={e => setCnpj(soNumeros(e.target.value))}
                    onBlur={e => setCnpj(formataCnpjCpf(e.target.value))}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Inscrição Estadual
                  </Input155px>
                  <InputText
                    maxLength={20}
                    value={inscricaoEstadual}
                    onChange={e => setInscricaoEstadual(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    CNPJ Matriz
                  </Input100px>
                  <InputText
                    maxLength={20}
                    value={cnpjMatriz}
                    onChange={e => setCnpjMatriz(e.target.value)}
                    onFocus={e => setCnpjMatriz(soNumeros(e.target.value))}
                    onBlur={e => setCnpjMatriz(formataCnpjCpf(e.target.value))}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Ramo Negócio
                  </Input155px>
                  <Dropdown
                    options={listaRamoNegocios}
                    value={ramoNegocio}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setRamoNegocio(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Razão Social
                  </Input155px>
                  <InputText
                    maxLength={100}
                    value={razaoSocial}
                    onChange={e => setRazaoSocial(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Nome Fantasia
                  </Input155px>
                  <InputText
                    maxLength={50}
                    value={nomeFantasia}
                    onChange={e => setNomeFantasia(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    CEP
                  </Input100px>
                  <InputMask
                    mask="99.999-999"
                    placeholder="  .   -   "
                    maxLength={10}
                    value={cep}
                    onChange={e => setCep(e.target.value)}
                    onBlur={e => onBlurCep(e)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Endereço
                  </Input100px>
                  <InputText
                    maxLength={100}
                    value={endereco}
                    onChange={e => setEndereco(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Número
                  </Input100px>
                  <InputText
                    maxLength={20}
                    value={enderecoNumero}
                    onChange={e => setEnderecoNumero(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Complemento
                  </Input155px>
                  <InputText
                    maxLength={50}
                    value={enderecoComplemento}
                    onChange={e => setEnderecoComplemento(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Bairro
                  </Input155px>
                  <InputText
                    maxLength={50}
                    value={bairro}
                    onChange={e => setBairro(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Código Município
                  </Input155px>
                  <InputText
                    maxLength={7}
                    value={codigoMunicipio}
                    onChange={e => setCodigoMunicipio(e.target.value)}
                    onBlur={e => onBlurCodigoMunicipio(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Cidade
                  </Input100px>
                  <AutoComplete
                    value={cidade}
                    maxLength={40}
                    suggestions={filteredMunicipios}
                    completeMethod={searchMunicipios}
                    field="label"
                    dropdown
                    onChange={e => handleChangeCidade(e)}
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="p-inputgroup">
                  <Input50px className="p-inputgroup-addon">
                    UF
                  </Input50px>
                  <InputText
                    disabled
                    value={uf}
                    onChange={e => setUf(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    E-mail
                  </Input155px>
                  <InputText
                    id="inputEmail"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Pessoa de Contato
                  </Input155px>
                  <InputText
                    maxLength={100}
                    value={pessoaContato}
                    onChange={e => setPessoaContato(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Telefone 1
                  </Input155px>
                  <InputText
                    maxLength={20}
                    value={telefone1}
                    onChange={e => setTelefone1(e.target.value)}
                    onFocus={e => setTelefone1(soNumeros(e.target.value))}
                    onBlur={e => setTelefone1(formataTelefone(e.target.value))}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Telefone 2
                  </Input100px>
                  <InputText
                    maxLength={20}
                    value={telefone2}
                    onChange={e => setTelefone2(e.target.value)}
                    onFocus={() => setTelefone2(soNumeros(telefone2))}
                    onBlur={() => setTelefone2(formataTelefone(telefone2))}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Data Licença
                  </Input155px>
                  <Calendar
                    id="mask"
                    showIcon
                    mask="99/99/9999"
                    dateFormat="dd/mm/yy"
                    showButtonBar
                    showOnFocus={false}
                    locale="pt-BR"
                    value={dataLicenca}
                    onChange={(e) => setDataLicenca(e.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Valor Mensal
                  </Input155px>
                  <InputNumber
                    mode="currency"
                    inputId="currency-br"
                    currency="BRL"
                    locale="pt-BR"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={valorMensal}
                    onValueChange={e => setValorMensal(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Dia Vencimento
                  </Input155px>
                  <InputNumber
                    showButtons
                    min={0}
                    max={31}
                    value={diaVencimento}
                    onValueChange={e => setDiaVencimento(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Gerar Boleto
                  </Input155px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={gerarBoleto}
                    onChange={(e) => setGerarBoleto(e.value)}
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Ativo
                  </Input100px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={ativo}
                    onChange={(e) => setAtivo(e.value)}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Dados Adicionais">
            <div className="row form-elo">
              <div className="col-lg-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    NF-e Ambiente
                  </Input155px>
                  <Dropdown
                    options={listaAmbiente}
                    value={nfeAmbiente}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setNfeAmbiente(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    NFC-e Ambiente
                  </Input155px>
                  <Dropdown
                    options={listaAmbiente}
                    value={nfceAmbiente}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setNfceAmbiente(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    CT-e Ambiente
                  </Input155px>
                  <Dropdown
                    options={listaAmbiente}
                    value={cteAmbiente}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setCteAmbiente(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    MDF-e Ambiente
                  </Input155px>
                  <Dropdown
                    options={listaAmbiente}
                    value={mdfeAmbiente}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setMdfeAmbiente(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-lg-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Regime Tributário
                  </Input155px>
                  <Dropdown
                    options={listaRegimeTributario}
                    value={regimeTributario}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setRegimeTributario(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    MEI
                  </Input100px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={mei}
                    onChange={(e) => setMei(e.value)}
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Sub-Limite
                  </Input100px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={subLimiteSimplesNacional}
                    onChange={(e) => setSubLimiteSimplesNacional(e.value)}
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Apuração Data Saída
                  </Input155px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={apuracaoDataSaida}
                    onChange={(e) => setApuracaoDataSaida(e.value)}
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Manifestação NF-e
                  </Input155px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={manifestacaoNFe}
                    onChange={(e) => setManifestacaoNFe(e.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-lg-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Série
                  </Input155px>
                  <InputText
                    minLength={1}
                    maxLength={3}
                    value={nfeSerie}
                    onChange={e => setNfeSerie(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Certificado Digital
                  </Input155px>
                  <InputText
                    disabled
                    rows={1}
                    value={certificado}
                    onChange={e => setCertificado(e.target.value)}
                  />
                  <ReactFileReader handleFiles={handleImportarCertificado} fileTypes=".pfx">
                    <button
                      type="button"
                      title="Importar Certificado Digital"
                      className="btn btn-warning"
                      icon="pi pi-search"
                      style={{ borderRadius: '15px', marginTop: '5px', marginLeft: '5px', display: 'flex' }}
                    >
                      <i className="pi pi-download"></i>
                    </button>
                  </ReactFileReader>
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Senha
                  </Input155px>
                  <Password
                    id="inputSenha"
                    toggleMask
                    weakLabel="Fraca"
                    mediumLabel="Média"
                    strongLabel="Forte"
                    value={senhaCertificado}
                    onChange={e => setSenhaCertificado(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    CSC
                  </Input155px>
                  <InputText
                    id="inputCsc"
                    value={csc}
                    onChange={e => setCsc(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Balança Digitos Código
                  </Input155px>
                  <InputNumber
                    showButtons
                    min={1}
                    max={9}
                    value={balancaDigitosCodigo}
                    onValueChange={e => setBalancaDigitosCodigo(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Balança por Valor
                  </Input155px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={balancaPorValor}
                    onChange={(e) => setBalancaPorValor(e.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Nome Abreviado
                  </Input155px>
                  <InputText
                    maxLength={20}
                    value={nomeAbreviado}
                    onChange={e => setNomeAbreviado(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Alíquota PIS
                  </Input155px>
                  <InputNumber
                    suffix=" %"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={aliquotaPis}
                    onValueChange={e => setAliquotaPis(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Alíquota COFINS
                  </Input155px>
                  <InputNumber
                    suffix=" %"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={aliquotaCofins}
                    onValueChange={e => setAliquotaCofins(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Pis/Cofins Cumulativo
                  </Input155px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={pisCofinsCumulativo}
                    onChange={(e) => setPisCofinsCumulativo(e.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Gerar Inventário
                  </Input155px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={gerarInventario}
                    onChange={(e) => setGerarInventario(e.value)}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Tipo Atividade
                  </Input155px>
                  <Dropdown
                    options={listaTipoAtividade}
                    value={tipoAtividade}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setTipoAtividade(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Logotipo">
            <div className="row form-elo">
              <Image src={'data:image/png;base64,' + logotipo} alt="image" height="300" width="300" preview style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
            </div>
            <div className="row form-elo">
              <div className="col-12" style={{ borderRadius: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ReactFileReader handleFiles={handleImportarLogotipo} fileTypes="image/*">
                  <button
                    type="button"
                    title="Importar Logotipo"
                    className="btn btn-warning"
                    icon="pi pi-search"
                    style={{ borderRadius: '15px' }}
                  >
                    <i className="pi pi-download"></i>
                  </button>
                </ReactFileReader>
              </div>
            </div>
          </TabPanel>
        </TabView>
        <div className="row">
          <div className="col-lg-12">
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-success"
            >
              <i className="pi pi-check"></i>
              Salvar
            </button>
            <button
              onClick={handleCancel}
              type="button"
              className="btn btn-danger"
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
}
