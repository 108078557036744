export function obterListaMdfeTipoUnidTransporte() {
  return (
    [
      { label: '1 - Rodoviário Tração', value: 'RODOVIARIOTRACAO' },
      { label: '2 - Rodoviário Reboque', value: 'RODOVIARIOREBOQUE' },
      { label: '3 - Navio', value: 'NAVIO' },
      { label: '4 - Balsa', value: 'BALSA' },
      { label: '5 - Aeronave', value: 'AERONAVE' },
      { label: '6 - Vagão', value: 'VAGAO' },
      { label: '7 - Outros', value: 'OUTROS' },
    ]
  );
}
