/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';
import { get, orderBy } from 'lodash';
import { Base64 } from 'js-base64';

import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import dateFormat from 'dateformat';

import * as actions from '../../../store/modules/auth/actions';

import 'react-toastify/dist/ReactToastify.css';

import { displayErrors } from '../../../util/diversos';
import { fillColumns } from '../../../components/table';
import { Input100px, Input200px, Input400px } from '../../../styles/inputGroup';

import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Card from '../../../components/card';
import Loading from '../../loading';
import CadastroMDFe from './cadastroMDFe';
import ConsultaProduto from '../produto/consultaProduto';
import ConsultaConversaoCfop from '../conversaoCfop/consultaConversaoCfop';

export default function ConsultaMDFe() {
  const [isLoading, setIsLoading] = useState(false);

  const [permitirCadastrar, setPermitirCadastrar] = useState(true);
  const [permitirEditar, setPermitirEditar] = useState(true);
  const [permitirExcluir, setPermitirExcluir] = useState(true);

  const [cnpjEmpresa, setCnpjEmpresa] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [serieEmpresa, setSerieEmpresa] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [ufEmpresa, setUfEmpresa] = useState('');

  const [idSelecionado, setIdSelecionado] = useState('');

  const [empresaTipoAmbiente, setEmpresaTipoAmbiente] = useState('');

  const [listaArquivosZipar, setListaArquivosZipar] = useState([]);

  const [showConsultaProduto, setShowConsultaProduto] = useState(false);
  const [showConsultaConversaoCfop, setShowConsultaConversaoCfop] = useState(false);
  const [dataTableListMdfeItem, setDataTableListMdfeItem] = useState([]);

  const [motivoCancelamento, setMotivoCancelamento] = useState('');
  const [motivoCartaCorrecao, setMotivoCartaCorrecao] = useState('');
  const [sequenciaEventoCartaCorrecao, setSequenciaEventoCartaCorrecao] = useState('');
  const [numeroLoteCartaCorrecao, setNumeroLoteCartaCorrecao] = useState('1');
  const [cnpjCartaCorrecao, setCnpjCartaCorrecao] = useState('');
  const [chaveAcesso, setChaveAcesso] = useState('');
  const [showCadastro, setShowCadastro] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [dataTableList, setDataTableList] = useState([]);
  const [dataTableListCartaCorrecao, setDataTableListCartaCorrecao] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showConfirmCancelamentoDialog, setShowConfirmCancelamentoDialog] = useState(false);
  const [showConfirmCartaCorrecaoDialog, setShowConfirmCartaCorrecaoDialog] = useState(false);
  const [selectedItens, setSelectedItens] = useState(null);
  const [findDataInicial, setFindDataInicial] = useState(null);
  const [findDataFinal, setFindDataFinal] = useState(null);
  const [sortField, setSortField] = useState('item');
  const [sortOrder, setSortOrder] = useState(null);
  const [payloadCadastro, setPayloadCadastro] = useState({});

  const [columnsSetup] = useState([
    { field: 'id', header: 'ID', sort: false },
    { field: 'dhEmi', header: 'Data', sort: true, formatarData: true },
    { field: 'serie', header: 'Série', sort: false },
    { field: 'numeroMdf', header: 'Número', sort: true },
    { field: 'chaveAcesso', header: 'Chave de Acesso', sort: true },
    { field: 'participante.razaoSocial', header: 'Participante', sort: false },
    { field: 'totalVNF', header: 'Total NDF-e', sort: false },
    { field: 'flag', header: 'Enviada', sort: true },
  ]);
  const [columnsSetupCartaCorrecao] = useState([
    { field: 'chaveAcesso', header: 'Chave Acesso', sort: false },
    { field: 'data', header: 'Data Evento', sort: false },
    { field: 'sequencia', header: 'Sequência Evento', sort: false },
    { field: 'motivo', header: 'Motivo', sort: false },
  ]);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function getData(searchFilter, filtrarCnpjEmpresa, page, size) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/mdfe/search?idUsuario=${idUsuario}`;

      if (filtrarCnpjEmpresa !== '') {
        params = `${params}&cnpjEmpresa=${filtrarCnpjEmpresa}`;
      }

      if (searchFilter && searchFilter.findDataInicial !== '') {
        const _dataInicial = `${dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy')} 00:00:00`;

        const _dataFinal = `${dateFormat(searchFilter.findDataFinal, 'dd-mm-yyyy')} 23:59:59`;
        params = `${params}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;
      }

      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);

      setDataTableList(response.data.content);
      setTotalRecords(response.data.totalElements);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function getDataCartaCorrecao(chave) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/evento?idUsuario=${idUsuario}`;

      if (cnpjEmpresa !== '') {
        params = `${params}&cnpjEmpresa=${cnpjEmpresa}`;
      }

      params = `${params}&chaveAcesso=${chave}&tipo=CARTACORRECAO`;

      const response = await axiosAdmin.get(params);

      setDataTableListCartaCorrecao(response.data);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleFind = () => {
    const searchFilter = { findDataInicial, findDataFinal };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);

    getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);
  };

  useEffect(() => {
    async function verificarSeMultiEmpresa(idUsuario, searchFilter) {
      try {
        setIsLoading(true);

        const params = `/usuario/${idUsuario}`;

        const response = await axiosAdmin.get(params);

        const _listaEmpresas = get(response.data, 'listaEmpresas', []);

        const listDropdown = [];

        const _cnpjConsultaPrevisaoFinanceiraCjpEmpresa = store.getState().auth.consultaPrevisaoFinanceiraCnpjEmpresa;

        let cnpjPreenchido = false;
        let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

        if (_cnpjConsultaPrevisaoFinanceiraCjpEmpresa === null || _cnpjConsultaPrevisaoFinanceiraCjpEmpresa === '') {
          if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
            setCnpjEmpresa(_cnpjEmpresaStore);
            const consultaPrevisaoFinanceiraCnpjEmpresa = _cnpjEmpresaStore;
            dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
            cnpjPreenchido = true;
          }
        }

        _listaEmpresas.forEach(element => {
          if (element.ativo) {
            if (!cnpjPreenchido && (_cnpjConsultaPrevisaoFinanceiraCjpEmpresa === null || _cnpjConsultaPrevisaoFinanceiraCjpEmpresa === '')) {
              setCnpjEmpresa(element.cnpj);
              setSerieEmpresa('58');
              setUfEmpresa(element.uf);
              const consultaPrevisaoFinanceiraCnpjEmpresa = element.cnpj;
              dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
              _cnpjEmpresaStore = element.cnpj;
              cnpjPreenchido = true;
            }

            listDropdown.push(
              {
                label: element.razaoSocial.trim(),
                value: element.cnpj,
                serie: '58',
                uf: element.uf,
                mdfeAmbiente: element.mdfeAmbiente,
                id: element.id,
                regimeTributario: element.regimeTributario,
              });
          }
        });

        listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

        setListaEmpresas(listDropdown);

        if (!cnpjPreenchido && _cnpjConsultaPrevisaoFinanceiraCjpEmpresa !== null && _cnpjConsultaPrevisaoFinanceiraCjpEmpresa !== '') {
          setCnpjEmpresa(_cnpjConsultaPrevisaoFinanceiraCjpEmpresa);
          _cnpjEmpresaStore = _cnpjConsultaPrevisaoFinanceiraCjpEmpresa;
        }

        getData(searchFilter, _cnpjEmpresaStore, 0, lazyParams.rows);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    if (findDataInicial === null) {
      setFindDataInicial(new Date(store.getState().auth.consultaPrevisaoFinanceiraDataInicial));
      setFindDataFinal(new Date(store.getState().auth.consultaPrevisaoFinanceiraDataFinal));

      const _dataInicial = new Date(store.getState().auth.consultaPrevisaoFinanceiraDataInicial);
      const _dataFinal = new Date(store.getState().auth.consultaPrevisaoFinanceiraDataFinal);

      const searchFilter = { findDataInicial: _dataInicial, findDataFinal: _dataFinal };

      verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0), searchFilter);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const searchFilter = { findDataInicial, findDataFinal };
    listaEmpresas.forEach(element => {
      // console.log('empresa: ', element);
      if (element.value === cnpjEmpresa) {
        // eslint-disable-next-line no-unused-expressions
        element.mdfeAmbiente === 'PRODUCAO' ? setEmpresaTipoAmbiente('Produção') : setEmpresaTipoAmbiente('AMBIENTE DE HOMOLOGAÇÃO - SEM VALOR FISCAL');
      }
    });
    getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cnpjEmpresa, listaEmpresas]);

  useEffect(() => {
    const operadorGrupoAcesso = get(store.getState().auth.user, 'operadorGrupoAcesso', null);

    if (operadorGrupoAcesso != null) {
      let listaGrupoAcessoBody = [];
      listaGrupoAcessoBody = operadorGrupoAcesso.listaGrupoAcessoBody;
      const permissaoCadastrar = listaGrupoAcessoBody.filter(el => el.modulo === 'CadastrarMDFe');
      const permissaoEditar = listaGrupoAcessoBody.filter(el => el.modulo === 'EditarMDFe');
      const permissaoExcluir = listaGrupoAcessoBody.filter(el => el.modulo === 'ExcluirMDFe');

      if (permissaoCadastrar.length <= 0) {
        setPermitirCadastrar(false);
      }

      if (permissaoEditar.length <= 0) {
        setPermitirEditar(false);
      }

      if (permissaoExcluir.length <= 0) {
        setPermitirExcluir(false);
      }
    }
  }, []);

  async function licencaValida(empresaId) {
    // eslint-disable-next-line consistent-return
    const retorno = await axiosAdmin.get(`/empresa/verificardatalicenca/${empresaId}`);
    return retorno.data;
  }

  // eslint-disable-next-line no-shadow, consistent-return
  const handleRegister = async (arquivoXml) => {
    if (!permitirCadastrar) {
      toast.info('Usuário não autorizado a cadastrar.');
      return false;
    }

    const id = 0;

    let idEmpresa = 0;
    let seriePadrao = '1';
    let uf = '';
    let aliquotaPis = 0;
    let aliquotaCofins = 0;
    let regimeTributario = '';

    listaEmpresas.forEach(element => {
      if (element.value === cnpjEmpresa) {
        // eslint-disable-next-line no-unused-expressions
        (element.serie !== '') ? seriePadrao = element.serie : '';
        idEmpresa = element.id;
        uf = element.uf;
        aliquotaCofins = element.aliquotaCofins;
        aliquotaPis = element.aliquotaPis;
        regimeTributario = element.regimeTributario;
      }
    });

    setPayloadCadastro(
      {
        id,
        idEmpresa,
        cnpjEmpresa,
        seriePadrao,
        uf,
        chaveAcesso,
        arquivoXml,
        aliquotaPis,
        aliquotaCofins,
        regimeTributario,
      },
    );

    setShowCadastro(true);
  };

  // eslint-disable-next-line consistent-return
  const handleEdit = async (e) => {
    e.preventDefault();

    if (!permitirEditar) {
      toast.info('Usuário não autorizado a editar.');
      return false;
    }

    let seriePadrao = '1';
    let uf = 'MG';
    let regimeTributario = '';

    listaEmpresas.forEach(element => {
      if (element.value === cnpjEmpresa) {
        // eslint-disable-next-line no-unused-expressions
        (element.serie !== '') ? seriePadrao = element.serie : '';
        uf = element.uf;
        regimeTributario = element.regimeTributario;
      }
    });

    const id = get(selectedItens, 'id', 0);
    const codigoSituacao = get(selectedItens, 'codSit', 0);
    const enviada = get(selectedItens, 'flag', 0);

    if (codigoSituacao === 'CANCELADO') {
      toast.warn('Nota cancelada não pode ser editada');
      return false;
    }

    if (codigoSituacao === 'DENEGADO') {
      toast.warn('Nota DENEGADA não pode ser editada');
      return false;
    }

    if (id > 0) {
      setPayloadCadastro({ id, cnpjEmpresa, enviada, seriePadrao, uf, regimeTributario });
      setShowCadastro(true);
    } else {
      toast.warning('Por favor, selecione o registro para edição');
    }
  };

  // eslint-disable-next-line consistent-return
  const handleDelete = (e) => {
    e.preventDefault();

    if (!permitirExcluir) {
      toast.info('Usuário não autorizado a excluir.');
      return false;
    }

    const enviada = get(selectedItens, 'flag', 0);

    if (enviada === 'T') {
      toast.warn('Nota enviada não pode ser excluída');
      return false;
    }

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setShowConfirmDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para exclusão');
    }
  };

  const handleCartaCorrecao = (e) => {
    const enviada = get(e, 'flag', 0);
    const codigoSituacao = get(e, 'codSit', 0);
    setChaveAcesso(get(e, 'chaveAcesso', 0));
    setCnpjCartaCorrecao(cnpjEmpresa);
    setIdSelecionado(get(e, 'id', 0));

    if (enviada !== 'T') {
      toast.warn('Somente Nota enviada pode ter carta de correção.');
      return;
    }
    if (codigoSituacao !== 'REGULAR') {
      toast.warn('Somente notas regulares podem ter carta de correção.');
      return;
    }

    const id = get(e, 'id', 0);

    if (id > 0) {
      getDataCartaCorrecao(get(e, 'chaveAcesso', 0));
      setShowConfirmCartaCorrecaoDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para criar a carta de correção');
    }
  };

  const handleCancelamento = (e) => {
    const enviada = get(e, 'flag', 0);
    const codigoSituacao = get(e, 'codSit', 0);
    setIdSelecionado(get(e, 'id', 0));

    if (enviada !== 'T') {
      toast.warn('Somente Nota enviada pode ser cancelada');
      return;
    }

    if (codigoSituacao === 'CANCELADO') {
      toast.warn('Nota já esta cancelada.');
      return;
    }

    const id = get(e, 'id', 0);

    if (id > 0) {
      setShowConfirmCancelamentoDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para cancelamento');
    }
  };

  const cancelCancelamento = () => {
    setShowConfirmCancelamentoDialog(false);
    setMotivoCancelamento('');
  };

  const cancelCartaCorrecao = () => {
    setShowConfirmCartaCorrecaoDialog(false);
    setMotivoCartaCorrecao('');
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  const confirmDelete = async () => {
    setShowConfirmDialog(false);

    try {
      setIsLoading(true);

      const idExclusao = get(selectedItens, 'id', 0);

      if (idExclusao > 0) {
        await axiosAdmin.delete(`/mdfe/${idExclusao}`);

        const _itens = dataTableList.filter(val => val.id !== idExclusao);
        setDataTableList(_itens);

        toast.success('Registro excluído com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível excluir o registro');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmCartaCorrecao = async () => {
    if (motivoCartaCorrecao.length < 15) {
      toast.info('Motivo da carta de correção inválido.');
      return;
    }

    setShowConfirmCartaCorrecaoDialog(false);

    try {
      setIsLoading(true);

      const id = idSelecionado;

      if (id > 0) {
        await axiosAdmin.get(`/mdfeutil/cartacorrecaomdfe?id=${id}&motivocorrecao=${motivoCartaCorrecao}&cnpj=${cnpjCartaCorrecao}&sequencia=${sequenciaEventoCartaCorrecao}&numerolote=${numeroLoteCartaCorrecao}`);

        toast.success('Registro Cartao Correção realizado com sucesso');
        handleFind();
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível registrar a carta de correção');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmCancelamento = async () => {
    if (motivoCancelamento.length < 15) {
      toast.warn('Motivo cancelamento deve conter no mínimo 15 caracteres.');
      return;
    }

    setShowConfirmCancelamentoDialog(false);

    try {
      setIsLoading(true);

      const id = idSelecionado;

      if (id > 0) {
        await axiosAdmin.get(`/mdfeutil/cancelarmdfe?id=${id}&motivocancelamento=${motivoCancelamento}`);

        toast.success('Cancelamento registrado com sucesso');
        handleFind();
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível cancelar nota fiscal');
    } finally {
      setIsLoading(false);
    }
  };

  const downloadPdf = (response, chave) => {
    try {
      const byteCharacters = Base64.atob(response.data);

      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      const blobFile = new Blob([byteArray], { type: 'application/pdf' });

      listaArquivosZipar.push({ file: blobFile, fileName: chave + '.pdf' });
    } catch (e) {
      toast.warn('Erro ao adicionar o pdf ao arquivo .zip');
    }
  };

  const visualizarPdf = (response) => {
    try {
      const byteCharacters = Base64.atob(response.data);

      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      const blobFile = new Blob([byteArray], { type: 'application/pdf' });

      const pdf = URL.createObjectURL(blobFile);
      window.open(pdf);
    } catch (e) {
      toast.warn('Erro ao adicionar o pdf ao arquivo .zip');
    }
  };

  const handleVisualizarPdf = async (e) => {
    if (e.codSit === 'DENEGADO') {
      toast.info('Nota Denegada não pode ser impressa.');
      return;
    }

    try {
      setIsLoading(true);

      const id = get(e, 'id', 0);
      const chave = get(e, 'chaveAcesso', 0);

      if (id > 0) {
        await axiosAdmin.get(`/mdfeutil/downloaddamdfe?chaveacesso=${chave}`).then(res => {
          visualizarPdf(res, chave);
        });
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível baixar os arquivos');
    } finally {
      setIsLoading(false);
    }
  };

  const downloadXml = (responseXml, chave) => {
    try {
      const byteCharactersXml = Base64.atob(responseXml.data);

      const byteNumbersXml = new Array(byteCharactersXml.length);
      for (let i = 0; i < byteCharactersXml.length; i++) {
        byteNumbersXml[i] = byteCharactersXml.charCodeAt(i);
      }

      const byteArrayXml = new Uint8Array(byteNumbersXml);

      const blobFileXml = new Blob([byteArrayXml], { type: 'application/xml' });

      listaArquivosZipar.push({ file: blobFileXml, fileName: chave + '.xml' });
    } catch (e) {
      toast.info('Erro ao adicionar o arquivo xml ao .zip');
    }
  };

  const handleDownloadPdf = async (e) => {
    try {
      const id = get(e, 'id', 0);
      const chave = get(e, 'chaveAcesso', 0);

      if (id > 0) {
        await axiosAdmin.get(`/mdfeutil/downloaddandfe?chaveacesso=${chave}`).then(res => {
          downloadPdf(res, chave);
        });
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível baixar os arquivos');
    }
  };

  const handleDownloadXml = async (e) => {
    try {
      const id = get(e, 'id', 0);
      const chave = get(e, 'chaveAcesso', 0);

      if (id > 0) {
        await axiosAdmin.get(`/xml/obterxml?chaveacesso=${chave}`).then(res => {
          downloadXml(res, chave);
        });
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível baixar os arquivos');
    }
  };

  const criarEBaixarArquivoZip = async (arquivos, chave) => {
    try {
      const zip = new JSZip();

      arquivos.forEach(element => {
        zip.file(element.fileName, element.file);
      });

      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, chave + '.zip');
      });

      setListaArquivosZipar([]);

      toast.success('Arquivo baixado com sucesso!');
    } catch (e) {
      toast.warn('Erro ao baixar arquivo zip.');
    }
  };

  const handleSalvarArquivos = async (e) => {
    if (e.codSit === 'DENEGADO') {
      toast.info('Nota Denegada não pode ser impressa.');
      return;
    }

    try {
      setIsLoading(true);
      const chave = get(e, 'chaveAcesso', 0);
      await handleDownloadXml(e);
      await handleDownloadPdf(e);
      await criarEBaixarArquivoZip(listaArquivosZipar, chave);
    } catch (err) {
      toast.warn('Não foi possível fazer download dos arquivos.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleImprimirCartaCorrecao = async (e) => {
    try {
      setIsLoading(true);

      // eslint-disable-next-line prefer-destructuring
      const modelo = e.modelo;
      // eslint-disable-next-line prefer-destructuring
      const serie = e.serie;
      const numero = e.chaveAcesso.substring(25, 34);
      const dataEvento = ('00' + e.data[2]).slice(-2) + '/' + ('00' + e.data[1]).slice(-2) + '/' + e.data[0];
      const xCorrecao = e.motivo;
      const dataEmissaoCarta = dataEvento;

      // eslint-disable-next-line new-cap
      const doc = new jsPDF('landscape');

      doc.autoTable({
        styles: { fontSize: 10 },
        margin: { bottom: 58, top: 38, left: 5, right: 5 },
        theme: 'grid',
        head: '',
        body: [],
        didDrawPage: (_dados) => {
          // Header
          doc.rect(5, 5, 287, 30);

          doc.setFontSize(20);
          doc.text('CARTA DE CORREÇÃO ELETRÔNICA', 85, 15);
          doc.setFontSize(15);
          doc.text('Não possui valor fiscal. Simples Representação do evento indicado abaixo', 57, 23);
          doc.setFontSize(15);
          doc.text('CONSULTE A AUTENTICIDADE NO SITE DA SEFAZ AUTORIZADORA', 58, 30);

          doc.setFontSize(15);
          doc.text('MANIFESTO DE TRANSPORTE ELETRÔNICO - MDFe', 5, 43);

          // quadro nota fiscal
          doc.rect(5, 46, 287, 30);

          // modelo
          doc.rect(5, 46, 50, 15);
          doc.setFontSize(10);
          doc.text('MODELO', 7, 51);
          doc.setFontSize(20);
          doc.text(modelo, 25, 58);

          // serie
          doc.rect(55, 46, 50, 15);
          doc.setFontSize(10);
          doc.text('SÉRIE', 57, 51);
          doc.setFontSize(20);
          doc.text(serie, 70, 58);

          // numero
          doc.rect(105, 46, 90, 15);
          doc.setFontSize(10);
          doc.text('NÚMERO', 107, 51);
          doc.setFontSize(20);
          doc.text(numero, 125, 58);

          // data emissao
          doc.rect(195, 46, 97, 15);
          doc.setFontSize(10);
          doc.text('MÊS/ANO DA EMISSÃO', 197, 51);
          doc.setFontSize(20);
          doc.text(dataEmissaoCarta, 225, 58);

          // chaveacesso
          doc.rect(5, 61, 287, 15);
          doc.setFontSize(10);
          doc.text('CHAVE DE ACESSO', 7, 66);
          doc.setFontSize(20);
          doc.text(e.chaveAcesso, 55, 72);

          doc.setFontSize(15);
          doc.text('CARTA DE CORREÇÃO ELETRÔNICA', 5, 83);

          // quadro carta correcao
          doc.rect(5, 86, 287, 15);

          // orgão
          doc.rect(5, 86, 50, 15);
          doc.setFontSize(10);
          doc.text('ÓRGÃO', 7, 91);
          doc.setFontSize(20);
          doc.text(e.codigoOrgao, 25, 98);

          // data do evento
          doc.rect(55, 86, 140, 15);
          doc.setFontSize(10);
          doc.text('DATA DO EVENTO', 108, 91);
          doc.setFontSize(20);
          doc.text(dataEvento, 115, 98);

          // sequencia
          doc.rect(195, 86, 97, 15);
          doc.setFontSize(10);
          doc.text('SEQUÊNCIA DO EVENTO', 198, 91);
          doc.setFontSize(20);
          doc.text(e.sequencia, 235, 98);

          doc.setFontSize(15);
          doc.text('MOTIVO DA CARTA DE CORREÇÃO', 5, 109);

          // quadro carta correcao
          doc.rect(5, 112, 287, 50);

          doc.setFontSize(14);
          doc.text(xCorrecao.substring(0, 120), 8, 118);
          doc.text(xCorrecao.substring(120, 240), 8, 124);
          doc.text(xCorrecao.substring(240, 360), 8, 130);
          doc.text(xCorrecao.substring(360, 480), 8, 136);
          doc.text(xCorrecao.substring(480, 600), 8, 142);

          // footer
          doc.setFontSize(8);
          const { pageSize } = doc.internal;
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          doc.text(`Pag ${doc.internal.getNumberOfPages()}`, _dados.settings.margin.right, pageHeight - 8);
          const periodo = new Date();
          doc.text('Impresso: ', 255, pageHeight - 8);
          // eslint-disable-next-line prefer-template
          doc.text((`${('00' + periodo.getDate()).slice(-2)}/${('00' + (+periodo.getMonth() + 1)).slice(-2)}/${periodo.getFullYear()}`).toString(), 270, pageHeight - 8);
        },
      });
      window.open(doc.output('bloburl'));
    } catch (err) {
      // console.log(err);
      displayErrors(err, dispatch, navigate, '', 'Não foi possível imprimir a carta de correção');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDialogFooter = (
    <div>
      <Button className="btn-success" label="Sim" icon="pi pi-check" onClick={confirmDelete} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDelete} />
    </div>
  );

  const confirmCancelamentoDialogFooter = (
    <div>
      <Button className="btn-success" label="Sim" icon="pi pi-check" onClick={confirmCancelamento} />
      <Button label="Não" icon="pi pi-times" onClick={cancelCancelamento} />
    </div>
  );

  const confirmCartaCorrecaoDialogFooter = (
    <div>
      <Button className="btn-success" label="Sim" icon="pi pi-check" onClick={confirmCartaCorrecao} />
      <Button label="Não" icon="pi pi-times" onClick={cancelCartaCorrecao} />
    </div>
  );

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const onPage = (event) => {
    setLazyParams(event);

    const searchFilter = { findDataInicial, findDataFinal };

    getData(searchFilter, cnpjEmpresa, event.page, event.rows);
  };

  const onHideCadastro = () => {
    setShowCadastro(false);
  };

  const childToParentConsulta = (payloadDataTableList, id) => {
    if (payloadDataTableList) {
      if (id > 0) {
        const index = dataTableList.map(e => e.id).indexOf(id);

        if (index >= 0) {
          dataTableList[index] = payloadDataTableList;
        }
      } else {
        const _dataTableList = [];
        _dataTableList.push(payloadDataTableList);

        setDataTableList(_dataTableList);

        const _lazyParams = { ...lazyParams, first: 0, page: 0 };
        setLazyParams(_lazyParams);
        setTotalRecords(1);
      }
    }

    setShowCadastro(false);
  };

  const actionBodyTemplate = (rowData) => {
    if (rowData.flag === 'T') {
      return (
        <div className="row col-12">
          <div className="col-lg-3">
            <button
              type="button"
              title="Visualizar PDF"
              className="btn-dark rounded outlined"
              style={{ borderRadius: '15px' }}
              onClick={() => handleVisualizarPdf(rowData)}
            >
              <i className="pi pi-eye"></i>
            </button>
          </div>
          <div className="col-lg-3">
            <button
              type="button"
              title="Download PDF/XML"
              className="btn-success rounded outlined"
              style={{ borderRadius: '15px' }}
              onClick={() => handleSalvarArquivos(rowData)}
            >
              <i className="pi pi-download"></i>
            </button>
          </div>
          <div className="col-lg-3">
            <button
              type="button"
              title="Criar Carta de Correção"
              className="btn-info rounded outlined"
              style={{ borderRadius: '15px' }}
              onClick={() => handleCartaCorrecao(rowData)}
            >
              <i className="pi pi-envelope"></i>
            </button>
          </div>
          <div className="col-lg-3">
            <button
              type="button"
              title="Efetuar Cancelamento"
              className="btn-danger rounded outlined"
              style={{ borderRadius: '15px' }}
              onClick={() => handleCancelamento(rowData)}
            >
              <i className="pi pi-ban"></i>
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row col-12">
          <div className="col-lg-3">
            <button
              type="button"
              title="Visualizar PDF"
              className="btn-dark rounded outlined"
              style={{ borderRadius: '15px' }}
              onClick={() => handleVisualizarPdf(rowData)}
            >
              <i className="pi pi-eye"></i>
            </button>
          </div>
        </div>
      );
    }
  };

  const actionBodyTemplateCartaCorrecao = (rowData) => (
    <div className="row col-12">
      <div className="col-lg-4">
        <button
          type="button"
          title="Imprimir Carta Correção"
          className="btn-success rounded outlined"
          style={{ borderRadius: '15px' }}
          onClick={() => handleImprimirCartaCorrecao(rowData)}
        >
          <i className="pi pi-print"></i>
        </button>
      </div>
    </div>
  );

  useEffect(() => {
    setDataTableList([]);

    const searchFilter = { findDataInicial, findDataFinal };

    listaEmpresas.forEach(element => {
      if (element.value === cnpjEmpresa) {
        // eslint-disable-next-line no-unused-expressions
        element.mdfeAmbiente === 'PRODUCAO' ? setEmpresaTipoAmbiente('Produção') : setEmpresaTipoAmbiente('AMBIENTE DE HOMOLOGAÇÃO - SEM VALOR FISCAL');
      }
    });

    getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTipoAmbiente = () => {
    if (empresaTipoAmbiente === 'AMBIENTE DE HOMOLOGAÇÃO - SEM VALOR FISCAL') {
      return (
        <h6 className="card-header" style={{ textAlign: 'center' }}>
          {empresaTipoAmbiente}
        </h6>
      );
    }
    return '';
  };

  const getConversaoCfop = async (cfop, cst) => {
    try {
      const idUsuario = get(store.getState().auth.user, 'id', 0);
      const response = await axiosAdmin.get(`/conversaocfop?idUsuario=${idUsuario}&cfopOrigem=${cfop}&cstOrigem=${cst}`);
      return response;
    } catch (e) {
      toast.info('Não foi possível pegar a conversão de cfop');
    }
    return null;
  };

  const onHideConsultaProduto = () => {
    setShowConsultaProduto(false);
  };

  const childToParentConsultaProduto = async (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      setShowConsultaProduto(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const verificaNovaConversaoCfop = async (lista) => {
    lista.forEach(async element => {
      await getConversaoCfop(element.cfop, element.cst).then(resp => {
        if (resp.data.length > 0) {
          element.cfopDestino = resp.data[0].cfopDestino;
          element.cstDestino = resp.data[0].cstDestino;
        }
      });
      setDataTableListMdfeItem([...lista]);
    });
  };

  const onHideConsultaConversaoCfop = async () => {
    await verificaNovaConversaoCfop(dataTableListMdfeItem);
    setShowConsultaConversaoCfop(false);
  };

  const childToParentConsultaConversaoCfop = async () => {

  };

  const larguraColunaOpcoes = '170px';

  // eslint-disable-next-line consistent-return
  async function handleCnpjEmpresa(e) {
    let empresaId = 0;

    listaEmpresas.forEach(element => {
      if (element.value === e.target.value) {
        // eslint-disable-next-line no-unused-expressions
        empresaId = element.id;
      }
    });

    const isLicencaValida = await licencaValida(empresaId);
    if (!isLicencaValida) {
      toast.info('Cliente com licença expirada. Efetuar pagamento mensalidade.');
      // navigate('/pagamento');
      return false;
    }

    setCnpjEmpresa(e.target.value);

    const consultaPrevisaoFinanceiraCnpjEmpresa = e.target.value;

    dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
  }

  return (
    <>
      <Loading isLoading={isLoading} />

      <Dialog
        header="Consulta de Produtos"
        visible={showConsultaProduto}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaProduto()}
      >
        <ConsultaProduto menuMode={false} childToParent={childToParentConsultaProduto} empresaCnpj={cnpjEmpresa} />
      </Dialog>

      <Dialog
        header="Cadastro Conversão CFOP"
        visible={showConsultaConversaoCfop}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaConversaoCfop()}
      >
        <ConsultaConversaoCfop menuMode={false} childToParent={childToParentConsultaConversaoCfop} empresaCnpj={cnpjEmpresa} />
      </Dialog>

      <Dialog
        header=""
        closeOnEscape={false}
        visible={showCadastro}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastro()}
        className="dialog-cadastro"
      >
        <CadastroMDFe payloadCadastro={payloadCadastro} childToParentConsulta={childToParentConsulta} />
      </Dialog>

      <Card>
        <div>
          <div className="card mb-3">
            <h6 className="card-header" style={{ textAlign: 'center' }}>
              Manifesto Eletrônico de Documentos Fiscais
            </h6>
            {renderTipoAmbiente()}

          </div>
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Data inicial
                </Input100px>
                <Calendar
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataInicial}
                  onChange={(e) => {
                    setFindDataInicial(e.value);
                    const consultaPrevisaoFinanceiraDataInicial = e.value;
                    const consultaPrevisaoFinanceiraDataFinal = findDataFinal;
                    dispatch(actions.consultaPrevisaoFinanceiraDataInicialChange({ consultaPrevisaoFinanceiraDataInicial, consultaPrevisaoFinanceiraDataFinal }));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Data final
                </Input100px>
                <Calendar
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataFinal}
                  onChange={(e) => {
                    setFindDataFinal(e.value);
                    const consultaPrevisaoFinanceiraDataInicial = findDataInicial;
                    const consultaPrevisaoFinanceiraDataFinal = e.value;
                    dispatch(actions.consultaPrevisaoFinanceiraDataInicialChange({ consultaPrevisaoFinanceiraDataInicial, consultaPrevisaoFinanceiraDataFinal }));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              {listaEmpresas.length > 1 ? (
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Empresa
                  </Input100px>
                  <Dropdown
                    options={listaEmpresas}
                    value={cnpjEmpresa}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={(e) => handleCnpjEmpresa(e)}
                  />
                </div>
              ) : ('')}
            </div>
            <div className="col-lg-3">
              <button
                type="button"
                title="Buscar"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={handleFind}
              >
                <i className="pi pi-search"></i>
              </button>
              <button
                type="button"
                title="Cadastrar"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
                onClick={handleRegister}
              >
                <i className="pi pi-plus"></i>
              </button>
              <button
                type="button"
                title="Editar"
                className="btn btn-warning"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleEdit(e)}
              >
                <i className="pi pi-pencil"></i>
              </button>
              <button
                type="button"
                title="Excluir"
                className="btn btn-danger"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleDelete(e)}
              >
                <i className="pi pi-trash"></i>
              </button>
            </div>
          </div>
        </div>
        <br />

        <div className="card">
          <DataTable
            value={dataTableList}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            selectionMode="single"
            selection={selectedItens}
            onSelectionChange={e => setSelectedItens(e.value)}
            selectableRows
            dataKey="id"
            paginator
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            {fillColumns(columnsSetup)}
            <Column body={actionBodyTemplate} exportable={false} style={{ width: `${larguraColunaOpcoes}` }}></Column>
          </DataTable>
        </div>

        <Dialog
          header="Confirmação"
          visible={showConfirmDialog}
          style={{ width: '50vw' }}
          footer={confirmDialogFooter}
          onHide={() => setShowConfirmDialog(false)}
        >
          <p>
            Excluir o registro?
          </p>
        </Dialog>

        <Dialog
          header={<h6 className="card-header" style={{ textAlign: 'center' }}>Carta de Correção</h6>}
          visible={showConfirmCartaCorrecaoDialog}
          style={{ width: '90vw' }}
          footer={confirmCartaCorrecaoDialogFooter}
          onHide={() => setShowConfirmCartaCorrecaoDialog(false)}
        >
          <div className="row form-elo">
            <div className="col-12">
              <p>
                Aviso: Não poderão ser sanados erros relacionados às variáveis consideradas no cálculo do valor do imposto.
                Tais como: valor da operação ou da prestação, base de cálculo e alíquota, dados cadastrais que impliquem
                alteração na identidade ou no enderecço de localização do remetente ou destinatário, data de emissão da
                NF-e ou data de saída da mercadoria.
              </p>
            </div>
          </div>
          <div className="row form-elo">
            <div className="col-6">
              <div className="p-inputgroup">
                <Input200px className="p-inputgroup-addon">
                  Chave de Acesso
                </Input200px>
                <InputText
                  disabled
                  maxLength={50}
                  value={chaveAcesso}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="p-inputgroup">
                <Input400px className="p-inputgroup-addon">
                  Código do orgão da recepção do evento
                </Input400px>
                <InputText
                  disabled
                  maxLength={2}
                  value={chaveAcesso.substring(0, 2)}
                />
              </div>
            </div>
          </div>
          <div className="row form-elo">
            <div className="col-6">
              <div className="p-inputgroup">
                <Input400px className="p-inputgroup-addon">
                  CNPJ ou CPF do autor do evento
                </Input400px>
                <InputText
                  disabled
                  maxLength={14}
                  value={cnpjCartaCorrecao}
                  onChange={e => setCnpjCartaCorrecao(e.target.value)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="p-inputgroup">
                <Input200px className="p-inputgroup-addon">
                  Sequência do evento
                </Input200px>
                <InputText
                  maxLength={5}
                  value={sequenciaEventoCartaCorrecao}
                  onChange={e => setSequenciaEventoCartaCorrecao(e.target.value)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="p-inputgroup">
                <Input200px className="p-inputgroup-addon">
                  Id do Lote de envio
                </Input200px>
                <InputText
                  maxLength={5}
                  value={numeroLoteCartaCorrecao}
                  onChange={e => setNumeroLoteCartaCorrecao(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row form-elo">
            <div className="col-12">
              <p>
                A correção mais recente substitui as anteriores. Motivo deve contar mais de 15 caracteres.
              </p>
            </div>
            <div className="col-12">
              <div className="p-inputgroup">
                <Input200px className="p-inputgroup-addon">
                  Motivo da Correção
                </Input200px>
                <InputText
                  maxLength={249}
                  value={motivoCartaCorrecao}
                  onChange={e => setMotivoCartaCorrecao(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row form-elo">
            <div className="col-12">
              <DataTable
                value={dataTableListCartaCorrecao}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                sortField={sortField}
                sortOrder={sortOrder}
                onSort={onSort}
                selectionMode="single"
                selection={selectedItens}
                onSelectionChange={e => setSelectedItens(e.value)}
                selectableRows
                dataKey="id"
                paginator
                lazy
                first={lazyParams.first}
                rows={lazyParams.rows}
                totalRecords={totalRecords}
                onPage={onPage}
                emptyMessage="Nenhum registro a ser exibido"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="{currentPage} de {totalPages}"
                rowsPerPageOptions={[10, 25, 50]}
              >
                {fillColumns(columnsSetupCartaCorrecao)}
                <Column body={actionBodyTemplateCartaCorrecao} exportable={false} style={{ width: '50px' }}></Column>
              </DataTable>
            </div>
          </div>
          <p>
            Criar carta de correção?
          </p>
        </Dialog>

        <Dialog
          header={<h6 className="card-header" style={{ textAlign: 'center' }}>Cancelamento da Nota Fiscal</h6>}
          visible={showConfirmCancelamentoDialog}
          style={{ width: '70vw' }}
          footer={confirmCancelamentoDialogFooter}
          onHide={() => setShowConfirmCancelamentoDialog(false)}
        >
          <div className="row form-elo">
            <div className="col-12">
              <div className="p-inputgroup">
                <Input200px className="p-inputgroup-addon">
                  Motivo do Cancelamento
                </Input200px>
                <InputText
                  maxLength={50}
                  value={motivoCancelamento}
                  onChange={e => setMotivoCancelamento(e.target.value)}
                />
              </div>
            </div>
          </div>
          <p>
            Cancelar a Nota Fiscal Selecionada?
          </p>
        </Dialog>
      </Card>
    </>
  );
}
