export function obterListaMdfeModalidade() {
  return (
    [
      { label: 'Rodoviário', value: 'RODOVIARIO' },
      { label: 'Aéreo', value: 'AEREO' },
      { label: 'Aquaviário', value: 'AQUAVIARIO' },
      { label: 'Ferroviário', value: 'FERROVIARIO' },
    ]
  );
}
