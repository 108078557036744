import React from 'react';

import '../styles/navbar.css';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Menubar } from 'primereact/menubar';
// import { get } from 'lodash';
import store from '../store/store';

import * as actions from '../store/modules/auth/actions';

export default function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(actions.loginFailure());

    navigate('/login');
  };

  const { tipoUsuario, tipoAcesso, modeloNegocio, moduloFinanceiro } = store.getState().auth.user;

  const itemsAdmin = [
    {
      label: 'Início',
      icon: 'pi pi-fw pi-home',
      command: () => { navigate('/home'); },
    },
    {
      label: 'Tabelas',
      icon: 'pi pi-fw pi-table',
      items: [
        {
          label: 'Usuários',
          command: () => {
            navigate('/consultausuario');
          },
        },
        {
          separator: true,
        },
        {
          label: 'Empresas',
          command: () => { navigate('/consultaempresa'); },
        },
        {
          separator: true,
        },
        {
          label: 'Ramo de Negócio',
          command: () => { navigate('/consultaramonegocio'); },
        },
        {
          separator: true,
        },
        {
          label: 'Municípios',
          command: () => { navigate('/consultamunicipio'); },
        },
        {
          separator: true,
        },
        {
          label: 'Unidade da Federação',
          command: () => { navigate('/consultaunidadefederacao'); },
        },
        {
          separator: true,
        },
        {
          label: 'Bancos',
          command: () => { navigate('/consultabanco'); },
        },
        {
          separator: true,
        },
        {
          label: 'Modulo',
          command: () => { navigate('/admconsultamodulo'); },
        },
        {
          separator: true,
        },
        {
          label: 'Tabelas Fiscais',
          items: [
            {
              label: 'Atualizar Tabelas Fiscais',
              command: () => { navigate('/atualizatabelafiscal'); },
            },
            {
              separator: true,
            },
            {
              label: 'CST ICMS',
              command: () => { navigate('/consultacst'); },
            },
            {
              label: 'CFOP',
              command: () => { navigate('/consultacfop'); },
            },
            {
              label: 'CSOSN',
              command: () => { navigate('/consultacsosn'); },
            },
            {
              label: 'Modalidade de Cálculo BC do ICMS',
              command: () => { navigate('/consultaicmsmodalidadebc'); },
            },
            {
              label: 'Modalidade de Cálculo BC do ICMS ST',
              command: () => { navigate('/consultaicmsmodalidadebcst'); },
            },
            {
              label: 'NCM',
              command: () => { navigate('/consultancm'); },
            },
            {
              label: 'CEST',
              command: () => { navigate('/consultacest'); },
            },
            {
              label: 'Gênero de Produto',
              command: () => { navigate('/consultageneroproduto'); },
            },
            {
              label: 'Tipo de Produto',
              command: () => { navigate('/consultatipoproduto'); },
            },
            {
              label: 'Serviço',
              command: () => { navigate('/consultaservico'); },
            },
            {
              label: 'Origem da Mercadoria',
              command: () => { navigate('/consultaorigemmercadoria'); },
            },
            {
              label: 'CST IPI',
              command: () => { navigate('/consultaipi'); },
            },
            {
              label: 'CST PIS/COFINS',
              command: () => { navigate('/consultapiscofins'); },
            },
          ],
        },
      ],
    },
    {
      label: 'Recebimentos',
      icon: 'pi pi-fw pi-table',
      items: [
        {
          label: 'Gerar Recebimentos',
          command: () => {
            navigate('/gerarrecebimento');
          },
        },
        {
          label: 'Consulta Recebimentos em Aberto',
          command: () => {
            navigate('/consultarecebimentoemaberto');
          },
        },
        {
          separator: true,
        },
        {
          label: 'Remessa Boleto',
          command: () => {
            navigate('/remessaboleto');
          },
        },
        /* {
          separator: true,
        },
         {
          label: 'Configuração Shipay',
          command: () => {
            navigate('/configuracaoshipay');
          },
        }, */
      ],
    },
    {
      label: 'Tickets',
      icon: 'pi pi-fw pi-table',
      command: () => { navigate('/admconsultaticket'); },
    },
    {
      label: 'Sair',
      icon: 'pi pi-fw pi-power-off',
      command: () => { logout(); },
    },
  ];

  const itemsTabelaFinanceiro = {
    label: 'Financeiro',
    icon: 'pi pi-fw pi-table',
    items: [
      {
        label: 'Centro de Custo',
        command: () => { navigate('/consultacentrocusto'); },
      },
      {
        label: 'DRE Financeiro',
        command: () => { navigate('/consultafinanceirodre'); },
      },
      {
        label: 'Meio de Pagamento',
        command: () => { navigate('/consultameiopagamento'); },
      },
      {
        label: 'Conta Bancária',
        command: () => { navigate('/consultacontabancaria'); },
      },
      {
        label: 'Layout Número OFX',
        command: () => { navigate('/consultaofxlayoutnumero'); },
      },
    ],
  };

  const itemsTabelaEstamparia = {
    label: 'Estamparia',
    icon: 'pi pi-fw pi-table',
    items: [
      {
        label: 'Modelo',
        command: () => { navigate('/consultaestampariamodelo'); },
      },
      {
        label: 'Tamanho',
        command: () => { navigate('/consultaestampariatamanho'); },
      },
      {
        label: 'Malha',
        command: () => { navigate('/consultaestampariamalha'); },
      },
      {
        label: 'Cor',
        command: () => { navigate('/consultaestampariacor'); },
      },
      {
        label: 'Bolso',
        command: () => { navigate('/consultaestampariabolso'); },
      },
      {
        label: 'Gola',
        command: () => { navigate('/consultaestampariagola'); },
      },
      {
        label: 'Acabamento da Gola',
        command: () => { navigate('/consultaestampariagolaacabamento'); },
      },
      {
        label: 'Manga',
        command: () => { navigate('/consultaestampariamanga'); },
      },
      {
        label: 'Acabamento da Manga',
        command: () => { navigate('/consultaestampariamangaacabamento'); },
      },
      {
        label: 'Bordado',
        command: () => { navigate('/consultaestampariabordado'); },
      },
      {
        label: 'Silk',
        command: () => { navigate('/consultaestampariasilk'); },
      },
      {
        label: 'Sublimação',
        command: () => { navigate('/consultaestampariasublimacao'); },
      },
      {
        label: 'Acessório',
        command: () => { navigate('/consultaestampariaacessorio'); },
      },
      {
        separator: true,
      },
      {
        label: 'Ítem Padrão',
        command: () => { navigate('/consultaestampariaitempadrao'); },
      },
      {
        separator: true,
      },
      {
        label: 'Configuração dos Preços',
        command: () => { navigate('/estampariaPrecificacao'); },
      },
    ],
  };

  const itemsRelatorioEstamparia = {
    label: 'Estamparia',
    icon: 'pi pi-fw pi-table',
    items: [
      {
        label: 'Relatório de Orçamentos',
        command: () => { navigate('/relatorioestampariaorcamento'); },
      },
      {
        label: 'Relatório de Pedidos',
        command: () => { navigate('/relatorioestampariapedido'); },
      },
      {
        label: 'Relatório de Pedidos - Financeiro',
        command: () => { navigate('/relatorioestampariapedidofinanceiro'); },
      },
      {
        label: 'Relatório de Serviços',
        command: () => { navigate('/relatorioestampariaservico'); },
      },
      {
        label: 'Relatório de Serviços em Aberto',
        command: () => { navigate('/relatorioestampariaservicopedidoemaberto'); },
      },
      {
        label: 'Relatório de Ordem de Produção',
        command: () => { navigate('/relatorioestampariaordemproducao'); },
      },
      {
        label: 'Relatório de Vendedores',
        items: [
          {
            label: 'Orçamentos',
            command: () => { navigate('/relatorioestampariaorcamentovendedores'); },
          },
          {
            label: 'Ordens de Produção',
            command: () => { navigate('/relatorioestampariaordemproducaovendedores'); },
          },
          {
            label: 'Pedidos',
            command: () => { navigate('/relatorioestampariapedidovendedores'); },
          },
        ],
      },
      {
        separator: true,
      },
      {
        label: 'Relatório de Caixa',
        command: () => { navigate('/relatorioestampariacaixa'); },
      },
      {
        label: 'Relatório Recebimentos em Aberto',
        command: () => { navigate('/relatorioestampariarecebimentopedidoaberto'); },
      },
    ],
  };

  const itemsEmpresa = [
    {
      icon: 'pi pi-fw pi-home',
      command: () => { navigate('/home'); },
    },
    {
      label: 'Tabelas',
      icon: 'pi pi-fw pi-table',
      items: [
        {
          label: 'Produtos',
          command: () => { navigate('/consultaproduto'); },
        },
        {
          label: 'Participantes',
          command: () => { navigate('/consultaparticipante'); },
        },
        {
          label: 'Unidades de Medida',
          command: () => { navigate('/consultaunidademedida'); },
        },
        {
          label: 'Grupos de Produtos',
          command: () => { navigate('/consultagrupo'); },
        },
        {
          label: 'Sub-Grupos',
          command: () => { navigate('/consultasubgrupo'); },
        },
        {
          label: 'Natureza de Operação',
          command: () => { navigate('/consultanaturezaoperacao'); },
        },
        /* {
          label: 'Carteira Digital',
          command: () => { navigate('/consultacarteiradigital'); },
        }, */
        /* {
          label: 'Bandeira de Cartão',
          command: () => { navigate('/consultabandeiracartao'); },
        }, */
        /* {
          label: 'Administradora de Cartão',
          command: () => { navigate('/consultaadministradoracartao'); },
        }, */
        {
          label: 'Conversão CFOP',
          command: () => { navigate('/consultaconversaocfop'); },
        },
        {
          label: 'Conversão CSOSN CST',
          command: () => { navigate('/consultaconversaocsosncst'); },
        },
        {
          label: 'Conta Analítica Contabil',
          command: () => { navigate('/consultacontaanalitica'); },
        },
        {
          label: 'Grupo de Acesso',
          command: () => { navigate('/consultagrupoacesso'); },
        },
        {
          label: 'Operador',
          command: () => { navigate('/consultaoperador'); },
        },
        {
          label: 'Vendedor',
          command: () => { navigate('/consultavendedor'); },
        },
        {
          label: 'Técnico',
          command: () => { navigate('/consultatecnico'); },
        },
        {
          label: 'Oficina',
          command: () => { navigate('/consultaoficina'); },
        },
        {
          label: 'Motorista',
          command: () => { navigate('/consultamotorista'); },
        },
        {
          label: 'Veículo',
          command: () => { navigate('/consultaveiculo'); },
        },
      ],
    },
    {
      label: 'Doc. Fiscais',
      icon: 'pi pi-fw pi-table',
      items: [
        /* {
          label: 'CT-e Emissão Própria',
          icon: 'pi pi-fw pi-table',
          items: [
            {
              label: 'Digitação de CT-e',
              command: () => { navigate('/consultacte'); },
            },
            {
              label: 'Envio de CT-e',
              command: () => { navigate('/enviocte'); },
            },
          ],
        },
        {
          separator: true,
        }, */
        {
          label: 'NF-e Emissão Própria',
          icon: 'pi pi-fw pi-table',
          items: [
            {
              label: 'Digitação de NF-e',
              command: () => { navigate('/consultanfepropria'); },
            },
            {
              label: 'Envio de NF-e',
              command: () => { navigate('/envionfepropria'); },
            },
          ],
        },
        {
          label: 'NF-e de Entrada',
          icon: 'pi pi-fw pi-table',
          items: [
            {
              label: 'Digitação de NF-e',
              command: () => { navigate('/consultanfeterceiro'); },
            },
          ],
        },
        {
          separator: true,
        },
        /* {
          label: 'MDF-e ',
          icon: 'pi pi-fw pi-table',
          items: [
            {
              label: 'Digitação de MDF-e',
              command: () => { navigate('/consultamdfe'); },
            },
            {
              label: 'Envio de MDF-e',
              command: () => { navigate('/enviomdfe'); },
            },
          ],
        },
        {
          separator: true,
        }, */
        {
          label: 'NFC-e',
          icon: 'pi pi-fw pi-table',
          items: [
            {
              label: 'Emissão NFC-e',
              command: () => { navigate('/emissaonfce'); },
            },
            {
              label: 'Consulta NFC-e',
              command: () => { navigate('/consultanfce'); },
            },
            {
              label: 'Monitor NFC-e',
              command: () => { navigate('/monitornfce'); },
            },
          ],
        },
        {
          separator: true,
        },
        {
          label: 'Arquivos Fiscais',
          icon: 'pi pi-fw pi-table',
          items: [
            {
              label: 'Gerar Arquivo Sintegra',
              command: () => { navigate('/gerarsintegra'); },
            },
          ],
        },
        {
          separator: true,
        },
        {
          label: 'Exportação de XML',
          command: () => { navigate('/exportarxml'); },
        },
        {
          label: 'Status do Serviço',
          command: () => { navigate('/statusservico'); },
        },
      ],
    },

    {
      label: 'Financeiro',
      icon: 'pi pi-fw pi-table',
      items: [
        {
          label: 'Painel Financeiro',
          command: () => { navigate('/painelfinanceiro'); },
        },
        {
          separator: true,
        },
        {
          label: 'Movimentação Financeira',
          command: () => { navigate('/consultamovimentacaofinanceira'); },
        },
        {
          label: 'Previsão Financeira',
          command: () => { navigate('/consultaprevisaofinanceira'); },
        },
        {
          label: 'Movimentação Bancária',
          command: () => { navigate('/consultamovimentacaobancaria'); },
        },
        {
          label: 'Livro Caixa',
          command: () => { navigate('/consultalivrocaixa'); },
        },
        {
          label: 'Fluxo Caixa',
          command: () => { navigate('/consultafluxocaixa'); },
        },
        /* {
          separator: true,
        },
        {
          label: 'Pagamento Mensalidade',
          command: () => { navigate('/pagamento'); },
        }, */
      ],
    },
    {
      label: 'Relatórios',
      icon: 'pi pi-fw pi-table',
      items: [
        {
          label: 'Relatório Movimentação Produto',
          command: () => { navigate('/relatoriomovimentacaoproduto'); },
        },
        {
          label: 'Relatório Ordem Serviço Aparelho',
          command: () => { navigate('/relatorioosaparelho'); },
        },
      ],
    },
    {
      label: 'Consultas',
      icon: 'pi pi-fw pi-table',
      items: [
        {
          label: 'Fechamento de Caixa',
          command: () => { navigate('/consultafechamentocaixa'); },
        },
        {
          label: 'Resumo Diário DAV',
          command: () => { navigate('/consultaresumodav'); },
        },
        {
          label: 'Resumo Diário NFC-e',
          command: () => { navigate('/consultaresumonfce'); },
        },
      ],
    },
    {
      label: 'Outros',
      icon: 'pi pi-fw pi-table',
      items: [
        {
          label: 'Configuração do Sistema',
          icon: 'pi pi-fw pi-cog',
          command: () => { navigate('/configuracaosistema'); },
        },
        {
          label: 'Movimentação de Produto',
          icon: 'pi pi-fw pi-table',
          command: () => { navigate('/consultamovimentacaoproduto'); },
        },
        {
          label: 'Ordem de Serviço - Aparelhos',
          icon: 'pi pi-fw pi-table',
          command: () => { navigate('/consultaordemservicoaparelho'); },
        },
        /* {
          label: 'Ponto de Venda',
          icon: 'pi pi-fw pi-table',
          command: () => { navigate('/consultapontovenda'); },
        }, */
        {
          label: 'Atualiza Estoque',
          icon: 'pi pi-fw pi-table',
          command: () => { navigate('/atualizaestoque'); },
        },
        {
          label: 'Tickets',
          icon: 'pi pi-fw pi-table',
          command: () => { navigate('/empresaconsultaticket'); },
        },
        {
          label: 'Alterar Senha',
          icon: 'pi pi-fw pi-lock',
          command: () => { navigate('/alterarsenha'); },
        },
      ],
    },
    {
      label: 'Sair',
      icon: 'pi pi-fw pi-power-off',
      command: () => { logout(); },
    },
  ];

  const itemsSuporte = [
    {
      label: 'Início',
      icon: 'pi pi-fw pi-home',
      command: () => { navigate('/home'); },
    },
    {
      label: 'Tabelas',
      icon: 'pi pi-fw pi-table',
      items: [
        {
          label: 'Modulo',
          command: () => { navigate('/consultamodulo'); },
        },
        {
          label: 'Empresas',
          command: () => { navigate('/consultaempresasuporte', { state: { somenteLeitura: true } }); },
        },
      ],
    },
    {
      label: 'Tickets',
      icon: 'pi pi-fw pi-table',
      command: () => { navigate('/consultaticket'); },
    },
    {
      label: 'Recebimentos',
      icon: 'pi pi-fw pi-table',
      command: () => { navigate('/suporteconsultarecebimentoemaberto'); },
    },
    {
      label: 'Sair',
      icon: 'pi pi-fw pi-power-off',
      command: () => { logout(); },
    },
  ];

  const itemsContador = [
    {
      label: 'Início',
      icon: 'pi pi-fw pi-home',
      command: () => { navigate('/home'); },
    },
    {
      label: 'Tabelas',
      icon: 'pi pi-fw pi-table',
      items: [
      ],
    },
    {
      label: 'Sair',
      icon: 'pi pi-fw pi-power-off',
      command: () => { logout(); },
    },
  ];

  const itemsVendasEstamparia = [
    {
      label: 'Início',
      icon: 'pi pi-fw pi-home',
      command: () => { navigate('/home'); },
    },
    {
      label: 'Caixa',
      icon: 'pi pi-fw pi-table',
      items: [
        {
          label: 'Reforço',
          command: () => { navigate('/consultaestampariacaixareforco'); },
        },
        {
          separator: true,
        },
        {
          label: 'Sangria',
          command: () => { navigate('/consultaestampariacaixasangria'); },
        },
        {
          separator: true,
        },
      ],
    },
    {
      label: 'Orçamentos',
      icon: 'pi pi-fw pi-table',
      command: () => { navigate('/consultaestampariaorcamento'); },
    },
    {
      label: 'Pedidos',
      icon: 'pi pi-fw pi-table',
      items: [
        {
          label: 'Digitação de Pedidos',
          command: () => { navigate('/consultaestampariapedido'); },
        },
        {
          separator: true,
        },
        {
          label: 'Recebimento de Pedidos',
          command: () => { navigate('/consultaestampariarecebimentopedido'); },
        },
      ],
    },
    {
      label: 'Ordem de Produção',
      icon: 'pi pi-fw pi-table',
      items: [
        {
          label: 'Gerar Ordem de Produção',
          command: () => { navigate('/gerarestampariaordemproducao'); },
        },
        {
          separator: true,
        },
        {
          label: 'Consultar Ordem de Produção',
          command: () => { navigate('/consultaestampariaordemproducao'); },
        },
      ],
    },
    {
      label: 'Sair',
      icon: 'pi pi-fw pi-power-off',
      command: () => { logout(); },
    },
  ];

  const { isLoggedIn } = store.getState().auth;

  const menuItens = () => {
    if (tipoUsuario === 'ADMINISTRADOR') {
      return itemsAdmin;
    }
    if (tipoUsuario === 'EMPRESA') {
      if (tipoAcesso === 'VENDAS' && modeloNegocio === 'ESTAMPARIA') {
        return itemsVendasEstamparia;
      }
      if (modeloNegocio === 'ESTAMPARIA') {
        itemsEmpresa[1].items.push(itemsTabelaEstamparia);
        itemsEmpresa[4].items.push(itemsRelatorioEstamparia);
      }
      if (moduloFinanceiro) {
        itemsEmpresa[1].items.push(itemsTabelaFinanceiro);
      }

      return itemsEmpresa;
    }
    if (tipoUsuario === 'SUPORTE') {
      itemsEmpresa[1].items.push(itemsSuporte);
      return itemsSuporte;
    }
    return itemsContador;
  };

  if (isLoggedIn) {
    return (
      <Menubar model={menuItens()} />
    );
  }

  return (<div></div>);
}
